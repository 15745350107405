import Project from '../models/project.model.js'
import buildQueryString from '../utils/build-query-string.js'

export const getProjects = async ({ axios }) => {
  const { data } = await axios.get('projects')
  return data.map(p => new Project(p))
}

export const getProject = async ({ axios, projectId, authTokenRestricted }) => {
  const options = {
    params: {},
  }

  if (authTokenRestricted) {
    options.params.auth_token_restricted = authTokenRestricted
  }

  const { data } = await axios.get(`projects/${projectId}`, options)
  return new Project(data)
}

export const deleteProject = async ({ axios, project }) => {
  await axios.delete(`projects/${project._id}`)
}

export const transferProject = async ({ axios, project, newValue }) => {
  const currentOwnerParticipant = project.participants.find(participant => participant.permission === 'owner')

  const patchObject = {
    op: 'execute',
    path: 'invitationAsProjectOwnerIsPending',
    value: {
      old: currentOwnerParticipant._id,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const acceptProjectOwnershipTransfer = async ({ axios, project }) => {
  const patchObject = {
    op: 'execute',
    path: 'acceptProjectOwnership',
    value: {},
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const cancelProjectOwnershipTransfer = async ({ axios, project }) => {
  const patchObject = {
    op: 'execute',
    path: 'cancelProjectOwnershipTransfer',
    value: {},
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const updateProjectTitle = async ({ axios, project, newValue, oldValue }) => {
  const patchObject = {
    op: 'update',
    path: 'title',
    value: {
      old: oldValue,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const updateProjectLanguage = async ({ axios, project, newValue }) => {
  if (newValue === project.language) {
    return project
  }

  const patchObject = {
    op: 'update',
    path: 'language',
    value: {
      old: project.language,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const updateProjectTimezone = async ({ axios, project, newValue }) => {
  if (newValue === project.timezone) {
    return project
  }

  const patchObject = {
    op: 'update',
    path: 'timezone',
    value: {
      old: project.timezone,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const addPhasetagPlans = async ({ axios, project, newValue }) => {
  const patchObject = {
    op: 'add',
    path: 'phasetags',
    value: newValue,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const reorderPhasetagsPlans = async ({ axios, project, newValue }) => {
  const patchObject = {
    op: 'reorder',
    path: 'phasetags',
    value: newValue,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const addPhasetagDocuments = async ({ axios, project, newValue }) => {
  const patchObject = {
    op: 'add',
    path: 'phasetagsDocuments',
    value: newValue,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const reorderPhasetagsDocuments = async ({ axios, project, newValue }) => {
  const patchObject = {
    op: 'reorder',
    path: 'phasetagsDocuments',
    value: newValue,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const updatePlansTaggingRestrictedForUsers = async ({ axios, project, oldValue, newValue }) => {
  const patchObject = {
    op: 'update',
    path: 'tags.plansTaggingRestrictedForUsers',
    value: {
      old: oldValue,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const updateDocumentsTaggingRestrictedForUsers = async ({ axios, project, oldValue, newValue }) => {
  const patchObject = {
    op: 'update',
    path: 'tags.documentsTaggingRestrictedForUsers',
    value: {
      old: oldValue,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const updateTopCategoriesPlansEnabled = async ({ axios, project, oldValue, newValue }) => {
  const patchObject = {
    op: 'update',
    path: 'topCategoriesPlansEnabled',
    value: {
      old: oldValue,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const addTopCategoriesPlans = async ({ axios, project, newValue }) => {
  const patchObject = {
    op: 'add',
    path: 'topCategoriesPlans',
    value: newValue,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const reorderTopCategoriesPlans = async ({ axios, project, newValue }) => {
  const patchObject = {
    op: 'reorder',
    path: 'topCategoriesPlans',
    value: newValue,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const updateTopCategoriesPlans = async ({ axios, project, oldValue, newValue }) => {
  const patchObject = {
    op: 'update',
    path: 'topCategoriesPlans',
    value: {
      old: oldValue,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const deleteTopCategoryPlans = async ({ axios, project, value }) => {
  const patchObject = {
    op: 'delete',
    path: 'topCategoriesPlans',
    value,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const enableTopCategoriesDocuments = async ({ axios, project, oldValue, newValue }) => {
  const patchObject = {
    op: 'update',
    path: 'topCategoriesDocumentsEnabled',
    value: {
      old: oldValue,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const addTopCategoriesDocuments = async ({ axios, project, newValue }) => {
  const patchObject = {
    op: 'add',
    path: 'topCategoriesDocuments',
    value: newValue,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const reorderTopCategoriesDocuments = async ({ axios, project, newValue }) => {
  const patchObject = {
    op: 'reorder',
    path: 'topCategoriesDocuments',
    value: newValue,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const updateTopCategoriesDocuments = async ({ axios, project, oldValue, newValue }) => {
  const patchObject = {
    op: 'update',
    path: 'topCategoriesDocuments',
    value: {
      old: oldValue,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const deleteTopCategoryDocuments = async ({ axios, project, value }) => {
  const patchObject = {
    op: 'delete',
    path: 'topCategoriesDocuments',
    value,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const addRole = async ({ axios, project, newValue }) => {
  const patchObject = {
    op: 'add',
    path: 'roles',
    value: newValue,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const addTagPlans = async ({ axios, project, newValue }) => {
  const patchObject = {
    op: 'add',
    path: 'tags.plans',
    value: newValue,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const addTagTasks = async ({ axios, project, newValue }) => {
  const patchObject = {
    op: 'add',
    path: 'tags.tasks',
    value: newValue,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const addTagDocuments = async ({ axios, project, newValue }) => {
  const patchObject = {
    op: 'add',
    path: 'tags.documents',
    value: newValue,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const updatePhasetagPlans = async ({ axios, project, oldValue, newValue }) => {
  const patchObject = {
    op: 'update',
    path: 'phasetags',
    value: {
      old: oldValue,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const updatePhasetagDocuments = async ({ axios, project, oldValue, newValue }) => {
  const patchObject = {
    op: 'update',
    path: 'phasetagsDocuments',
    value: {
      old: oldValue,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const updateTagPlans = async ({ axios, project, oldValue, newValue }) => {
  const patchObject = {
    op: 'update',
    path: 'tags.plans',
    value: {
      old: oldValue,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const updateTagTasks = async ({ axios, project, oldValue, newValue }) => {
  const patchObject = {
    op: 'update',
    path: 'tags.tasks',
    value: {
      old: oldValue,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const updateTagDocuments = async ({ axios, project, oldValue, newValue }) => {
  const patchObject = {
    op: 'update',
    path: 'tags.documents',
    value: {
      old: oldValue,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const updateRole = async ({ axios, project, oldValue, newValue }) => {
  const patchObject = {
    op: 'update',
    path: 'roles',
    value: {
      old: oldValue,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const deletePhasetagPlans = async ({ axios, project, value }) => {
  const patchObject = {
    op: 'delete',
    path: 'phasetags',
    value,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const deletePhasetagDocuments = async ({ axios, project, value }) => {
  const patchObject = {
    op: 'delete',
    path: 'phasetagsDocuments',
    value,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const deleteTagPlans = async ({ axios, project, value }) => {
  const patchObject = {
    op: 'delete',
    path: 'tags.plans',
    value,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const deleteTagTasks = async ({ axios, project, value }) => {
  const patchObject = {
    op: 'delete',
    path: 'tags.tasks',
    value,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const deleteTagDocuments = async ({ axios, project, value }) => {
  const patchObject = {
    op: 'delete',
    path: 'tags.documents',
    value,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const deleteRole = async ({ axios, project, value }) => {
  const patchObject = {
    op: 'delete',
    path: 'roles',
    value,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const createProject = async ({ axios, project }) => {
  const { data } = await axios.post('projects', {
    ...project,
  })

  return new Project(data)
}

export const getLastRevisionDate = async ({ axios, projectId, itemType }) => {
  const query = buildQueryString({
    select: `lastrevisionupload${itemType}`,
  })
  const { data } = await axios.get(`projects/${projectId}/${query}`)

  return data
}

export const reorderLocationProperties = async ({ axios, project, oldValue, newValue }) => {
  const patchObject = {
    op: 'reorder',
    path: 'locations',
    value: {
      old: oldValue,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const updateLocationPropertyIsEnabled = async ({ axios, project, locationProperty, oldValue, newValue }) => {
  const patchObject = {
    op: 'update',
    path: `locations.${locationProperty}.isEnabled`,
    value: {
      old: oldValue,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const addLocationValue = async ({ axios, project, locationProperty, newValue }) => {
  const patchObject = {
    op: 'add',
    path: `locations.${locationProperty}.values`,
    value: [newValue],
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const deleteLocationValue = async ({ axios, project, locationProperty, value }) => {
  const patchObject = {
    op: 'delete',
    path: `locations.${locationProperty}.values`,
    value,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const updateLocationValue = async ({ axios, project, locationProperty, oldValue, newValue }) => {
  const patchObject = {
    op: 'update',
    path: `locations.${locationProperty}.values`,
    value: {
      old: oldValue,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const reorderLocationValues = async ({ axios, project, locationProperty, newValue }) => {
  const patchObject = {
    op: 'reorder',
    path: `locations.${locationProperty}.values`,
    value: {
      old: project.locations[locationProperty].values,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const updateLocationLabel = async ({ axios, project, locationProperty, oldValue, newValue }) => {
  const patchObject = {
    op: 'update',
    path: `locations.${locationProperty}.label`,
    value: {
      old: oldValue,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const addTaskType = async ({ axios, project, newValue }) => {
  const patchObject = {
    op: 'add',
    path: 'taskTypes',
    value: [newValue],
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const updateTaskType = async ({ axios, project, oldValue, newValue }) => {
  const patchObject = {
    op: 'update',
    path: 'taskTypes',
    value: {
      old: oldValue,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const deleteTaskType = async ({ axios, project, value }) => {
  const patchObject = {
    op: 'delete',
    path: 'taskTypes',
    value,
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}

export const reorderTaskTypes = async ({ axios, project, newValue }) => {
  const patchObject = {
    op: 'reorder',
    path: 'taskTypes',
    value: {
      old: project.taskTypes,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}`, patchObject)

  return new Project(data)
}
