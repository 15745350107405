<template>
  <div
    v-if="showBanner"
    class="com_notification-box com_notification-box--buttonbar-right"
    :class="{
      'com_notification-box--info': !didTrialPeriodEnd,
      'com_notification-box--alert': didTrialPeriodEnd,
    }"
  >
    <div
      class="com_notification-box__content"
    >
      <h5
        class="com_notification-box__title"
      >
        <span
          v-if="didTrialPeriodEnd"
        >
          {{ $t('feature.account.trial_period_banner.trial_period_ended', { date: daysToTrialEnd }) }}
        </span>

        <span
          v-if="!didTrialPeriodEnd"
        >
          {{ $t('feature.account.trial_period_banner.trial_period_ends', { date: daysToTrialEnd }) }}
        </span>
      </h5>

      <p
        class="com_notification-box__text"
      >
        {{ $t('feature.account.trial_period_banner.continue_working_if_plan_selected') }}
      </p>
    </div>

    <PfButtonBar
      class="com_notification-box__action"
    >
      <router-link
        :to="{ name: 'account' }"
        class="comv2_button-bar__button obj_button"
        @click="onClickChoosePlanLink"
      >
        <span
          class="label"
        >
          {{ $t('feature.account.trial_period_banner.choose_plan') }}
        </span>
      </router-link>

      <a
        class="comv2_button-bar__link"
        :href="$t('data.pf_links.personal_support')"
        target="_blank"
        @click="onClickHelpLink"
      >
        {{ $t('feature.account.trial_period_banner.extend_test_phase') }}
      </a>
    </PfButtonBar>
  </div>
</template>

<script>
import PfButtonBar from '@/components/PfButtonBar.vue'

export default {
  name: 'BannerTrialPeriod',

  components: {
    PfButtonBar,
  },

  computed: {
    showBanner () {
      if (this.$config.isBackupApp) {
        return false
      }

      if (this.$config.customerFeatures.account.disablePayment) {
        return false
      }

      // user is part of company wide account
      if (this.$auth.user.crm.isCompanyWideAccount) {
        return false
      }

      if (this.$config.customerFeatures.hideFeatureAlerts) {
        return false
      }

      // don't show banner to users who have registered before the 1st of January 2016
      if (this.hasRegisteredBefore2016) {
        return false
      }

      // no projects, don't show banner
      if (!this.hasProjects) {
        return false
      }

      // user has filled payment data, no banner
      if (this.hasFilledPaymentData) {
        return false
      }

      // user has filled payment data, no banner
      if (this.isPackageSelected) {
        return false
      }

      // if user has a first project older than 20 days, then show banner that trial period ends
      return this.hasProjectOlderThanTwentyDays
    },

    trialPeriodEndDate () {
      return this.$dayjs(this.$auth.user.payment.firstproject).add(this.$config.trialPeriodDurationInDays, 'days')
    },

    daysToTrialEnd () {
      return this.$dayjs().to(this.trialPeriodEndDate)
    },

    didTrialPeriodEnd () {
      return this.$dayjs() > this.trialPeriodEndDate
    },

    hasRegisteredBefore2016 () {
      return this.$auth.user.date && this.$dayjs(this.$auth.user.date) < this.$dayjs('2016-01-01')
    },

    hasProjects () {
      return this.$auth.user.crm && this.$auth.user.crm.numberOfProjects > 0
    },

    hasFilledPaymentData () {
      return this.$auth.user.payment && this.$auth.user.payment.enabled
    },

    isPackageSelected () {
      return this.$auth.user.payment && this.$auth.user.payment.customerbillingplan && this.$auth.user.payment.customerbillingplan.plan !== 'none'
    },

    hasProjectOlderThanTwentyDays () {
      return this.$auth.user.payment && this.$auth.user.payment.firstproject && this.$dayjs().diff(this.$auth.user.payment.firstproject, 'days') > 20
    },
  },

  methods: {
    onClickHelpLink () {
      this.$ga.event({ eventCategory: 'gotoWebsite', eventAction: 'gotoWebsite via trial-ends-link' })
    },

    onClickChoosePlanLink () {
      this.$ga.event({ eventCategory: 'gotoPayment', eventAction: 'gotoPayment via trial-ends-button' })
    },
  },
}
</script>
