import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M11.4367 13.0753C8.11861 13.1328 5.60502 15.1298 4.56685 17.9369C4.15805 19.0422 5.10269 20.074 6.28121 20.074H10.9842M11.4367 13.0753C11.4857 13.0744 11.5349 13.074 11.5843 13.074C11.9364 13.074 12.2797 13.0957 12.6133 13.1378C12.947 13.1799 13.271 13.2424 13.5847 13.324M11.4367 13.0753C10.7866 13.0866 10.1678 13.1723 9.58445 13.324M15.3343 6.32404C15.3343 8.39511 13.6554 10.074 11.5843 10.074C9.5133 10.074 7.83437 8.39511 7.83437 6.32404C7.83437 4.25297 9.5133 2.57404 11.5843 2.57404C13.6554 2.57404 15.3343 4.25297 15.3343 6.32404Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M20.576 16.3783C19.8502 16.9235 18.2738 18.0518 17.4953 18.6072C17.2233 18.8013 16.8602 18.8013 16.5882 18.6072C15.8097 18.0518 14.2333 16.9235 13.5076 16.3783M14.1952 15.593C13.7615 15.593 13.41 15.9446 13.41 16.3783V20.5009C13.41 20.9345 13.7615 21.2861 14.1952 21.2861H19.8883C20.322 21.2861 20.6735 20.9345 20.6735 20.5009V16.3783C20.6735 15.9446 20.322 15.593 19.8883 15.593H14.1952Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }