import Textblock from '../models/textblock.model'
import buildQueryString from '../utils/build-query-string'

export const getTextblocks = async ({ axios, projectId, category }) => {
  const url = `textblocks/${buildQueryString({
    projectId,
    category,
  })}`
  const { data } = await axios.get(url)

  const textblocks = data.map(textblock => Object.freeze(new Textblock(textblock)))

  return textblocks
}

export const getSingleBlock = async ({ axios, blockId }) => {
  const { data } = await axios.get(`textblocks/${blockId}`)
  return Object.freeze(new Textblock(data))
}

export const postNewBlock = async ({ axios, projectId, title, text, category }) => {
  const { data } = await axios.post('textblocks', {
    projectId, title, text, category,
  })

  return Object.freeze(new Textblock(data))
}

export const patchBlock = async ({ axios, blockId, textblock, newValue }) => {
  const patchObj = {
    op: 'update',
    value: {
      old: {
        title: textblock.title,
        text: textblock.text,
      },
      new: {
        title: newValue.title,
        text: newValue.text,
      },
    },
  }

  const { data } = await axios.patch(`textblocks/${blockId}`, patchObj)

  return Object.freeze(new Textblock(data))
}

export const deleteBlock = async ({ axios, blockId }) => {
  const { data } = await axios.delete(`textblocks/${blockId}`)

  return data
}
