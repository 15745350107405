import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M4.75 6.75H6.42963C7.09834 6.75 7.7228 6.4158 8.09373 5.8594L8.90627 4.6406C9.2772 4.0842 9.90166 3.75 10.5704 3.75H13.4296C14.0983 3.75 14.7228 4.0842 15.0937 4.6406L15.9063 5.8594C16.2772 6.4158 16.9017 6.75 17.5704 6.75H19.25C20.3546 6.75 21.25 7.64543 21.25 8.75V18.25C21.25 19.3546 20.3546 20.25 19.25 20.25H4.75C3.64543 20.25 2.75 19.3546 2.75 18.25V8.75C2.75 7.64543 3.64543 6.75 4.75 6.75Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "square",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M15.25 13C15.25 14.7949 13.7949 16.25 12 16.25C10.2051 16.25 8.75 14.7949 8.75 13C8.75 11.2051 10.2051 9.75 12 9.75C13.7949 9.75 15.25 11.2051 15.25 13Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "square",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }