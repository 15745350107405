import AttachmentFile from './attachment-file.model'
import LocationLayerFile from './location-layer-file.model'
import constants from '../config/constants'

// Used to map different types of files from the outside (plans, documents, tasks, taskComments) to a uniform shape
export default class UnifiedFile {
  constructor ({ _id, isUploading, fileName, created, size, mimeType, s3Key, resolutions, imgSrc, uri } = { }) {
    if (!_id) {
      throw new Error('_id in UnifiedFile must be defined')
    }

    this._id = _id
    this.fileName = fileName
    this.created = created
    this.size = size
    this.mimeType = mimeType
    this.s3Key = s3Key
    this.s3KeyThumb = resolutions && resolutions[constants.FILE_RESOLUTIONS.THUMB] ? resolutions[constants.FILE_RESOLUTIONS.THUMB].s3Key : undefined
    this.imgSrc = imgSrc // Mostly base64, but can be also a URL
    this.isUploading = isUploading // Only used in Ionic app
    this.uri = uri // Only used in Ionic app
  }

  convertToAttachmentFile () {
    return new AttachmentFile({
      _id: this._id,
      key: this.s3Key,
      filesize: this.size,
      filename: this.fileName,
    })
  }

  convertToLocationLayerFile () {
    return new LocationLayerFile({
      _id: this._id,
      s3Key: this.s3Key,
      name: this.fileName,
      size: this.size,
      mimeType: this.mimeType,
    })
  }
}
