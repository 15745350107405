import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M15.25 15.25V20C15.25 20.6904 14.6904 21.25 14 21.25H4C3.30964 21.25 2.75 20.6904 2.75 20V10C2.75 9.30964 3.30964 8.75 4 8.75H8.75M10 15.25H20C20.6904 15.25 21.25 14.6904 21.25 14V4C21.25 3.30964 20.6904 2.75 20 2.75H10C9.30964 2.75 8.75 3.30964 8.75 4V14C8.75 14.6904 9.30964 15.25 10 15.25Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }