import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M3.98484 17.6516L1.75 6.75L7.75 10.25L12 3.75L16.25 10.25L22.25 6.75L20.0152 17.6516C19.8244 18.5821 19.0057 19.25 18.0559 19.25H5.94409C4.99434 19.25 4.17557 18.582 3.98484 17.6516Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "square",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }