import sendRaygun from '@/utils/send-raygun.js'

export default ({ e, routeName, redirectOptions = { name: 'projects' } }) => {
  if (!e) {
    throw new Error('Missing "e"')
  }
  if (!routeName) {
    throw new Error('Missing "routeName"')
  }

  const is404 = e.response.status === 404
  const isInvalidObjectId = e.response.status === 400 && e.response.data.error === 'INVALID_OBJECTID'
  const isNotAllowedToAccessProject = e.response.status === 403

  // log 404 because in Planfred there should not exist missing documents
  if (is404) {
    sendRaygun({
      error: new Error(e.response),
      tags: ['404-async-data-error-handler'],
    })
  }

  if (is404 && routeName === 'projects-projectId') {
    return { name: 'projects' }
  }

  if (is404 || isInvalidObjectId || isNotAllowedToAccessProject) {
    return redirectOptions
  }
}
