<template>
  <component
    :is="tagName"
    :type="tagName === 'button' ? 'submit' : undefined"
    :href="href"
    class="comv2_button-bar__button obj_button"

    :class="{
      'is_loading': isLoading,
      'is_disabled': !isEnabled,
      'mod--inverse': isInversed,
    }"
  >
    <PfIcon
      v-if="iconName"
      :name="iconName"
      class="icon"
    />

    <span
      class="label"
    >
      <slot />
    </span>
  </component>
</template>

<script>
export default {
  name: 'PfButtonBarButton',

  props: {
    href: {
      type: String,
      default: undefined,
    },

    isEnabled: {
      type: Boolean,
      default: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    isInversed: {
      type: Boolean,
      default: false,
    },

    iconName: {
      type: String,
      default: null,
    },
  },

  computed: {
    tagName () {
      if (this.href) {
        return 'a'
      }

      return 'button'
    },
  },
}
</script>
