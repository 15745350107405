import constants from '@/config/constants.js'
const validModi = [
  {
    name: constants.LIST_MODUS.LIST,
    filterStoreName: constants.FILTER_STORE_NAMES.PARTICIPANTS_FILTER,
  }, {
    name: constants.LIST_MODUS.PRINT,
    filterStoreName: constants.FILTER_STORE_NAMES.PARTICIPANTS_FILTER,
  }, {
    name: constants.LIST_MODUS.TRASH,
    filterStoreName: constants.FILTER_STORE_NAMES.PARTICIPANTS_FILTER_MODUS_TRASH,
  }, {
    name: constants.LIST_MODUS.MULTI_ACTION_EDIT,
    filterStoreName: constants.FILTER_STORE_NAMES.PARTICIPANTS_FILTER,
  },
]

export default {
  namespaced: true,

  state: () => {
    return {
      modus: constants.LIST_MODUS.LIST,
      filterStoreName: constants.FILTER_STORE_NAMES.PARTICIPANTS_FILTER,
    }
  },

  mutations: {
    SET_MODUS (state, modus) {
      const matchedModusObject = validModi.find(m => m.name === modus)

      if (!matchedModusObject) {
        console.error(`${modus} is not a valid participantsListModus`)
        return
      }
      state.modus = matchedModusObject.name
      state.filterStoreName = matchedModusObject.filterStoreName
    },

    FULL_RESET (state) {
      state.modus = constants.LIST_MODUS.LIST
      state.filterStoreName = constants.FILTER_STORE_NAMES.PARTICIPANTS_FILTER
    },
  },
}
