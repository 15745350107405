import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M11.9095 1.75211C8.18298 1.75211 5.15955 4.62976 5.15955 8.17398C5.15955 12.2521 9.65955 18.7148 11.3119 20.9469C11.3805 21.0412 11.4704 21.1179 11.5742 21.1707C11.6781 21.2236 11.793 21.2511 11.9095 21.2511C12.0261 21.2511 12.141 21.2236 12.2449 21.1707C12.3487 21.1179 12.4386 21.0412 12.5072 20.9469C14.1595 18.7157 18.6595 12.2554 18.6595 8.17398C18.6595 4.62976 15.6361 1.75211 11.9095 1.75211Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M11.9095 10.7521C13.1522 10.7521 14.1595 9.74475 14.1595 8.50211C14.1595 7.25946 13.1522 6.25211 11.9095 6.25211C10.6669 6.25211 9.65955 7.25946 9.65955 8.50211C9.65955 9.74475 10.6669 10.7521 11.9095 10.7521Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }