import { getLocationLayers, patchLocationLayerTitle, patchLocationLayerIsActive, patchLocationLayerLocationRestrictions, postLocationLayer, deleteLocationLayer, reorderLocationLayers } from '@client-shared/api/location-layers.api'
import { sortLocationLayersByOrder } from '@client-shared/utils/sort-functions'

import constants from '@/config/constants'

export default {
  namespaced: true,

  state: () => {
    return {
      list: [],
    }
  },

  getters: {
    getById: (state) => {
      return locationLayerId => state.list.find(locationLayer => locationLayer._id === locationLayerId)
    },

    listSelectable: (state) => {
      return state.list.filter(locationLayer => locationLayer.tileProcessing.status !== constants.TILE_PROCESSING_STATUS.ERROR && locationLayer.isActive)
    },

    isLocationLayerProcessing () {
      return locationLayer => {
        if (!locationLayer) {
          return false
        }

        return locationLayer.tileProcessing.status === constants.TILE_PROCESSING_STATUS.NOT_STARTED ||
        locationLayer.tileProcessing.status === constants.TILE_PROCESSING_STATUS.PROCESSING ||
        locationLayer.tileProcessing.status === constants.TILE_PROCESSING_STATUS.UPLOADING
      }
    },
  },

  mutations: {
    SET_LIST (state, list) {
      state.list = [...list]
        .sort(sortLocationLayersByOrder)
    },

    REPLACE_LOCATION_LAYER (state, updatedLocationLayer) {
      const existingLocationLayer = state.list.findIndex(locationLayer => locationLayer._id === updatedLocationLayer._id)

      if (existingLocationLayer !== -1) {
        const isUpdateNewer = new Date(updatedLocationLayer.modified) > new Date(state.list[existingLocationLayer].modified)

        if (isUpdateNewer) {
          state.list[existingLocationLayer] = updatedLocationLayer
        }
      }
    },

    ADD_LOCATION_LAYER (state, newLocationLayer) {
      const isInList = state.list.some(locationLayer => locationLayer._id === newLocationLayer._id)

      if (isInList) {
        return
      }

      state.list.push(newLocationLayer)
    },

    REMOVE_LOCATION_LAYER (state, locationLayerId) {
      state.list = state.list.filter(locationLayer => locationLayer._id !== locationLayerId)
    },

    FULL_RESET (state) {
      state.list = []
    },
  },

  actions: {
    async fetch ({ commit }, { projectId }) {
      const locationLayers = await getLocationLayers({
        axios: this.$axios,
        projectId,
      })

      commit('SET_LIST', locationLayers)
    },

    async updateTitle ({ commit }, { locationLayer, title }) {
      const updatedLocationLayer = await patchLocationLayerTitle({
        axios: this.$axios,
        locationLayer,
        title,
      })

      commit('REPLACE_LOCATION_LAYER', updatedLocationLayer)
    },

    async updateIsActive ({ commit }, { locationLayer, isActive }) {
      const updatedLocationLayer = await patchLocationLayerIsActive({
        axios: this.$axios,
        locationLayer,
        isActive,
      })

      commit('REPLACE_LOCATION_LAYER', updatedLocationLayer)
    },

    async updateLocationRestrictions ({ commit }, { locationLayer, locationRestrictions }) {
      const updatedLocationLayer = await patchLocationLayerLocationRestrictions({
        axios: this.$axios,
        locationLayer,
        locationRestrictions,
      })

      commit('REPLACE_LOCATION_LAYER', updatedLocationLayer)
    },

    async create ({ commit }, { locationLayer }) {
      const newLocationLayer = await postLocationLayer({
        axios: this.$axios,
        locationLayer,
      })

      commit('ADD_LOCATION_LAYER', newLocationLayer)

      return newLocationLayer
    },

    async delete ({ commit }, locationLayerId) {
      await deleteLocationLayer({
        axios: this.$axios,
        locationLayerId,
      })

      commit('REMOVE_LOCATION_LAYER', locationLayerId)
    },

    async reorder  ({ commit, rootState }, { projectId, oldValue, newValue }) {
      const updatedLocationLayers = await reorderLocationLayers({
        axios: this.$axios,
        projectId,
        oldValue,
        newValue,
      })

      commit('SET_LIST', updatedLocationLayers)
    },
  },
}
