import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M6.49985 5L3.20696 8.29289C2.81643 8.68342 2.81643 9.31658 3.20696 9.70711L6.49985 13M3.99985 9H17.2498C19.459 9 21.2498 10.7909 21.2498 13V14.25C21.2498 16.4591 19.459 18.25 17.2498 18.25H11.7498",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }