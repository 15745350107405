import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M12.022 11.9133C12.1237 11.2368 12.5022 10.8714 12.8816 10.6136C13.2526 10.3613 13.6237 10.0339 13.6237 9.42768C13.6237 8.59098 12.9524 7.91333 12.1237 7.91333C11.2949 7.91333 10.6237 8.59098 10.6237 9.42768M9.29422 18.4836L11.3593 20.2147C11.7292 20.5248 12.2679 20.5263 12.6397 20.2183L14.738 18.4799C14.9173 18.3313 15.143 18.25 15.3759 18.25H18.25C19.3546 18.25 20.25 17.3546 20.25 16.25V5.75C20.25 4.64543 19.3546 3.75 18.25 3.75H5.75C4.64543 3.75 3.75 4.64543 3.75 5.75V16.25C3.75 17.3546 4.64543 18.25 5.75 18.25H8.65182C8.88675 18.25 9.11418 18.3327 9.29422 18.4836Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M12 14V13.99M12.25 14C12.25 14.138 12.138 14.25 12 14.25C11.8619 14.25 11.75 14.138 11.75 14C11.75 13.8619 11.8619 13.75 12 13.75C12.138 13.75 12.25 13.8619 12.25 14Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }, null, -1)
  ])))
}
export default { render: render }