const template = {
  roles: [
    'AG - Bauherr',
    'AN - Bautechnik',
    'AN - Primärtechnik',
    'AN - Diverses',
    'AN - GIS Anlagen',
    'AN - Haustechnik',
    'AN - Transformationen',
    'PS - Projektsteuerung',
    'UTBA - Umwelttechnische Bauaufsicht',
    'ÖBA - PRIM/BAU/HT',
    'Planer - Bautechnik Tiefbau',
    'Planer - Bautechnik Hochbau',
    'Planer Primärtechnik',
    'Planer - GIS Anlagen',
    'Planer Haustechnik',
    'Gutachter',
    'Netzpartner',
  ],
  phasetags: [
    'G-Grundlagen',
    'E-Entwurf-Vorabzug',
    'E-Entwurf-gültig',
    'G-Genehmigung-Einreichung-Vorabzug',
    'G-Genehmigung-Einreichung–gültig',
    'V-Vergabeplan/Ausschreibungsplan-Vorabzug',
    'V-Vergabeplan/Ausschreibungsplan-gültig',
    'A-Ausführungsplan-Vorabzug',
    'A-Ausführungsplan-gültig',
    'B-Bestandsplan-Vorabzug',
    'B-Bestandsplan-gültig',
    'Vermessungsplan',
  ],

  phasetagsDocuments: [
    'Allgemein',
    'Behörde',
    'Datenblätter',
    'Einreichbeilagen',
    'Fotos',
    'Grundlagen',
    'Gutachten',
    'Listen',
    'Protokolle',
    'Terminplan',
  ],
  tags: {
    plans: [
      '006 kV',
      '010 kV',
      '020 kV',
      '030 kV',
      '110 kV',
      '132 kV',
      '220 kV',
      '3D-Scan',
      'Allgemein',
      'Abwasseranlage (Entwässerung Hebepumpen)',
      'AIS',
      'Anlageplan, Lageplan',
      'Anlagenbeleuchtungsplan',
      'Abrechnungsplan',
      'Antennenanlagen (A1, GPS)',
      'Alarmanlage',
      'BA - Bauabschnitte/Bauphasen',
      'Bahnbau',
      'Bauabschnittsplan',
      'Bauphasen',
      'Beleuchtungseinrichtungen',
      'Blitzschutzmast',
      'Blitzschutzbereichsplan',
      'Brandschutzanlage',
      'Computernetz (LAN)',
      'Detailplan',
      'Einfriedung',
      'Entwässerung',
      'Bewehrungsplan',
      'EBU',
      'Eisenaufstellungsplans',
      'ESB',
      'Elektro',
      'Elektrische Installationen (Licht, Kraft usw.)',
      'Erdungsplan, Erdungstragfähigkeitsplan',
      'Feuerlöscherpumpen',
      'Gerätesteher',
      'GIS',
      'Gesamtgrundriss',
      'Grundriss',
      'Hochbau',
      'HS-Klemmenplan',
      'Krananlagen',
      'HKLS',
      'Kabelgang',
      'Katasterplan',
      'Kabelwege',
      'Klimaanlage',
      'Kräfteplan',
      'Kunstbauten',
      'Kennzeichnungsplan',
      'KEV-Gerüst',
      'Kabellageplan',
      'Landschaftsbau',
      'Leitungsmast',
      'Lautsprecheranlagen',
      'Lüftungsanlagen, Entrauchung',
      'NA - Nebelanlagen',
      'Ölauffanganlage, Regenwasseranlage',
      'Portalfundamente',
      'Provisiorien',
      'Primärgeräte',
      'PST',
      'Portal',
      'Regelplan',
      'RHU',
      'Schalplan',
      'Schnitt',
      'Störmeldungen',
      'Stahlbau',
      'Spezialtiefbau-Erdbau',
      'Stützmauer',
      'Spannungsabstandsnachweisplan',
      'Telefonanlagen',
      'TLA - Trafolöschanlage',
      'Verkehrswege',
      'Videoüberwachungsanlagen',
      'Wasserversorgung (Brunnen)',
      'Zisterne',
    ],
    documents: [
      'Baubesprechung',
      'Planungsbesprechung',
      'Türliste',
      'Planliste',
    ],
  },
  topCategoriesPlansEnabled: true,
  topCategoriesPlans: [
    'Standard',
    'Leitungsbau',
    'Primärtechnik',
    'Bautechnik',
    'Haustechnik',
  ],
  topCategoriesDocumentsEnabled: true,
  topCategoriesDocuments: [
    'Standard',
    'Leitungsbau',
    'Primärtechnik',
    'Bautechnik',
    'Haustechnik',
  ],

  taskTypes: [
    'ToDo',
    'Mangel',
    'Klärungsbedarf',
  ],

  locations: {
    location1: {
      isEnabled: true,
      label: 'Gebäude',
      order: 0,
      values: [
        'Bauteil 1',
        'Bauteil 2',
        'Bauteil 3',
        'Nebengebäude',
      ],
    },

    location2: {
      isEnabled: true,
      label: 'Stockwerk',
      order: 1,
      values: [
        'UG',
        'EG',
        'OG 1',
        'OG 2',
      ],
    },

    location3: {
      isEnabled: true,
      label: 'Einheit',
      order: 2,
      values: [
        'Top 1',
        'Top 2',
        'Top 3',
        'Top 4',
      ],
    },

    location4: {
      isEnabled: false,
      label: 'Raum',
      order: 3,
      values: [
        'Büro',
        'WC',
        'Zimmer 1',
        'Zimmer 2',
      ],
    },
  },
}

export default template
