// Check nuxt for reference:
// https://github.com/nuxt/nuxt/blob/2b10dd7b7550b14004bad259c7d3501949b84bc1/packages/nusrc/app/plugins/chunk-reload.client.ts#L4
// or here: https://github.com/nuxt/nuxt/issues/14594#issuecomment-1397361071

export default {
  install: (app) => {
    const $router = app.config.globalProperties.$router
    let navigateToUrl

    $router.beforeEach((to) => {
      navigateToUrl = to.href
    })

    $router.onError((error) => {
      const moduleNotFoundMessage = [
        'Importing a module script failed', // safari
        'Failed to fetch dynamically imported module', // edge & chrome
        'Unable to preload CSS for', // Firefox, Safari
        'not a valid JavaScript MIME type', // Firefox, Safari (full message in raygun: "TypeError: 'text/html' is not a valid JavaScript MIME type." )
      ]

      const isModuleNotFoundError = moduleNotFoundMessage.find(message => error?.message.includes(message))

      if (isModuleNotFoundError) {
        window.location.href = navigateToUrl
      }
    })
  },
}
