import constants from '../config/constants.js'

export default class ClipboardFile {
  constructor ({ id, name, extension, mimeType, size, uploadError, assignedAs, assignedAsTmp, assignedAsTmpStoreId, isUploadCompleted = false, uploadPercentageDone = 0, s3Key = '' }) {
    if (
      !id ||
      !name ||
      !size ||
      typeof s3Key !== 'string'
    ) {
      throw new Error('Passed object is probably not an uppy file from uppy.store.state.files')
    }

    if (assignedAs && !constants.FILE_TYPES.ALL.includes(assignedAs)) {
      throw new Error(`Clipboardfiles assignedAs property must be of value ${constants.FILE_TYPES.ALL} or undefined`)
    }

    this.id = id
    this.name = name
    this.extension = extension
    this.mimeType = mimeType
    this.size = size
    this.isUploadCompleted = isUploadCompleted
    this.uploadPercentageDone = uploadPercentageDone
    this.s3Key = s3Key
    this.uploadError = uploadError
    this.assignedAs = assignedAs // Is used to mark assigned files as 'print' file, or whatever it is
    this.assignedAsTmp = assignedAsTmp // Same as 'assignedAs' but for temporary assignments -> means the assignment is not saved yet
    this.assignedAsTmpStoreId = assignedAsTmpStoreId // Is used to reset the temporary assignment as soon as tab gets closed
  }

  static createFromUppyFile (uppyFile) {
    return new ClipboardFile({
      id: uppyFile.id,
      name: uppyFile.name,
      size: uppyFile.size,
      extension: uppyFile.extension,
      mimeType: uppyFile.type,
      isUploadCompleted: uppyFile.progress.uploadComplete,
      uploadPercentageDone: uppyFile.progress.percentage,
      s3Key: uppyFile.s3Multipart ? uppyFile.s3Multipart.key : '',
      uploadError: uppyFile.error,
    })
  }

  get nameDecoded () {
    return decodeURIComponent(this.name)
  }

  get isUnassigned () {
    return !this.isUploadRunning && !this.uploadError && !this.assignedAs && !this.assignedAsTmp
  }

  get isUploadRunning () {
    return !this.isUploadCompleted && !this.uploadError
  }

  get isPrintFile () {
    return constants.VALID_PRINT_FILE_EXTENSIONS.includes(this.extension.toLowerCase())
  }

  get isCadFile () {
    return constants.VALID_CAD_FILE_EXTENSIONS.includes(this.extension.toLowerCase())
  }
}
