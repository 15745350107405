import Participant from '../models/participant.model.js'

export const getParticipantsEligableForProjectTransfer = async ({ axios, project }) => {
  const { data } = await axios.get(`projects/${project._id}/participants`, {
    params: {
      filter: 'can-accept-project-transfer',
    },
  })

  const participants = data.map(p => Object.freeze(new Participant(p)))

  return participants
}

export const deleteGroupSelection = async ({ axios, project, participant, selectionId }) => {
  const { data } = await axios.delete(`projects/${project._id}/participants/${participant._id}/participantselection/${selectionId}`)

  return new Participant(data)
}

export const updateGroupSelection = async ({ axios, project, participant, selectionGroup }) => {
  const { data } = await axios.put(`projects/${project._id}/participants/${participant._id}/participantselection/${selectionGroup._id}`, selectionGroup)
  return new Participant(data)
}

export const addParticipant = async ({ axios, project, newParticipant, inviteMessage }) => {
  const postObject = {
    participant: newParticipant,
  }

  if (newParticipant.permission !== 'silent') {
    postObject.message = inviteMessage
  }

  const { data } = await axios.post(`projects/${project._id}/participants`, postObject)

  return new Participant(data)
}

export const updateAlertStatus = async ({ axios, project, participant, newValue, authTokenRestricted }) => {
  if (participant.uploadAlert.status === newValue) {
    return new Participant(participant)
  }

  const patchObject = {
    op: 'update',
    path: 'alertStatus',
    value: {
      old: {
        status: participant.uploadAlert.status,
      },
      new: {
        status: newValue,
      },
    },
  }

  const options = {
    params: {},
  }

  if (authTokenRestricted) {
    options.params.auth_token_restricted = authTokenRestricted
  }

  const { data } = await axios.patch(`projects/${project._id}/participants/${participant._id}`, patchObject, options)
  return new Participant(data)
}

export const updatePlanDocReport = async ({ axios, project, participant, isEnabled, authTokenRestricted }) => {
  const patchObject = {
    op: 'update',
    path: 'planDocReport',
    value: {
      old: {
        isEnabled: participant.planDocReport.isEnabled,
      },
      new: {
        isEnabled,
      },
    },
  }

  const options = {
    params: {},
  }

  if (authTokenRestricted) {
    options.params.auth_token_restricted = authTokenRestricted
  }

  const { data } = await axios.patch(`projects/${project._id}/participants/${participant._id}`, patchObject, options)
  return new Participant(data)
}

export const updateTaskReport = async ({ axios, project, participant, isEnabled, authTokenRestricted }) => {
  const patchObject = {
    op: 'update',
    path: 'taskReport',
    value: {
      old: {
        isEnabled: participant.taskReport.isEnabled,
      },
      new: {
        isEnabled,
      },
    },
  }

  const options = {
    params: {},
  }

  if (authTokenRestricted) {
    options.params.auth_token_restricted = authTokenRestricted
  }

  const { data } = await axios.patch(`projects/${project._id}/participants/${participant._id}`, patchObject, options)
  return new Participant(data)
}

export const updateDueDateReport = async ({ axios, project, participant, isEnabled, authTokenRestricted }) => {
  const patchObject = {
    op: 'update',
    path: 'dueDateReport',
    value: {
      old: {
        isEnabled: participant.dueDateReport.isEnabled,
      },
      new: {
        isEnabled,
      },
    },
  }

  const options = {
    params: {},
  }

  if (authTokenRestricted) {
    options.params.auth_token_restricted = authTokenRestricted
  }

  const { data } = await axios.patch(`projects/${project._id}/participants/${participant._id}`, patchObject, options)
  return new Participant(data)
}

export const updateWeekStartReport = async ({ axios, project, participant, containsTasks, containsPlans, containsDocuments, authTokenRestricted }) => {
  const patchObject = {
    op: 'update',
    path: 'weekStartReport',
    value: {
      old: {
        containsTasks: participant.weekStartReport.containsTasks,
        containsPlans: participant.weekStartReport.containsPlans,
        containsDocuments: participant.weekStartReport.containsDocuments,
      },
      new: {
        containsTasks,
        containsPlans,
        containsDocuments,
      },
    },
  }

  const options = {
    params: {},
  }

  if (authTokenRestricted) {
    options.params.auth_token_restricted = authTokenRestricted
  }

  const { data } = await axios.patch(`projects/${project._id}/participants/${participant._id}`, patchObject, options)
  return new Participant(data)
}

export const updateCanapproverevisions = async ({ axios, project, participant, newValue }) => {
  if (participant.canapproverevisions === newValue) {
    return new Participant(participant)
  }

  const patchObject = {
    op: 'update',
    path: 'canApproveRevisions',
    value: {
      old: participant.canapproverevisions,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}/participants/${participant._id}`, patchObject)
  return new Participant(data)
}

export const updateSendCopyToMyself = async ({ axios, project, participant, newValue }) => {
  if (participant.sendCopyToMyself === newValue) {
    return new Participant(participant)
  }

  const patchObject = {
    op: 'update',
    path: 'sendCopyToMyself',
    value: {
      old: participant.sendCopyToMyself,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}/participants/${participant._id}`, patchObject)
  return new Participant(data)
}

export const updateShowActivitiesInTaskDetail = async ({ axios, project, participant, newValue }) => {
  if (participant.showActivitiesInTaskDetail === newValue) {
    return new Participant(participant)
  }

  const patchObject = {
    op: 'update',
    path: 'showActivitiesInTaskDetail',
    value: {
      old: participant.showActivitiesInTaskDetail,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}/participants/${participant._id}`, patchObject)
  return new Participant(data)
}

export const updatePermission = async ({ axios, project, participant, newValue, message }) => {
  if (participant.permission === newValue) {
    return new Participant(participant)
  }

  const patchObject = {
    op: 'update',
    path: 'permission',
    value: {
      old: participant.permission,
      new: newValue,
    },
    message,
  }
  const { data } = await axios.patch(`projects/${project._id}/participants/${participant._id}`, patchObject)

  return new Participant(data)
}

export const updateEnabled = async ({ axios, project, participant, newValue }) => {
  const patchObject = {
    op: 'update',
    path: 'enabledStatus',
    value: {
      old: participant.enabled,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}/participants/${participant._id}`, patchObject)
  return new Participant(data)
}

export const updateRoles = async ({ axios, project, participant, newValue }) => {
  if (JSON.stringify(participant.roles) === JSON.stringify(newValue)) {
    return new Participant(participant)
  }

  const patchObject = {
    op: 'update',
    path: 'roles',
    value: {
      old: participant.roles,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}/participants/${participant._id}`, patchObject)
  return new Participant(data)
}

export const updateCompany = async ({ axios, project, participant, newValue }) => {
  if (participant.company === newValue) {
    return new Participant(participant)
  }

  const patchObject = {
    op: 'update',
    path: 'company',
    value: {
      old: participant.company,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}/participants/${participant._id}`, patchObject)
  return new Participant(data)
}

export const updatePhone = async ({ axios, project, participant, newValue }) => {
  if (participant.phone === newValue) {
    return new Participant(participant)
  }

  const patchObject = {
    op: 'update',
    path: 'phone',
    value: {
      old: participant.phone,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}/participants/${participant._id}`, patchObject)
  return new Participant(data)
}

export const updateAddress = async ({ axios, project, participant, newValue }) => {
  if (JSON.stringify(participant.address) === JSON.stringify(newValue)) {
    return participant
  }

  const patchObject = {
    op: 'update',
    path: 'address',
    value: {
      old: participant.address,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}/participants/${participant._id}`, patchObject)
  return new Participant(data)
}

export const updateNote = async ({ axios, project, participant, newValue }) => {
  if (participant.note === newValue) {
    return participant
  }

  const patchObject = {
    op: 'update',
    path: 'note',
    value: {
      old: participant.note,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`projects/${project._id}/participants/${participant._id}`, patchObject)
  return new Participant(data)
}

export const updateName = async ({ axios, project, participant, newFirstName, newLastName }) => {
  if (participant.firstname === newFirstName && participant.lastname === newLastName) {
    return new Participant(participant)
  }

  const patchObject = {
    op: 'update',
    path: 'name',
    value: {
      old: {
        firstname: participant.firstname,
        lastname: participant.lastname,
      },
      new: {
        firstname: newFirstName,
        lastname: newLastName,
      },
    },
  }

  const { data } = await axios.patch(`projects/${project._id}/participants/${participant._id}`, patchObject)
  return new Participant(data)
}

export const addGroupSelection = async ({ axios, project, participant, selectionLabel, selectionEmails, category }) => {
  const selection = {
    category,
    label: selectionLabel,
    emails: selectionEmails,
  }

  const { data } = await axios.post(`projects/${project._id}/participants/${participant._id}/participantselection/`, selection)

  return new Participant(data)
}

export const resendInvitation = async ({ axios, project, participant, message }) => {
  const patchObject = {
    op: 'execute',
    path: 'resendInvitationEmail',
    value: {
      message,
    },
  }

  await axios.patch(`projects/${project._id}/participants/${participant._id}`, patchObject)
}
