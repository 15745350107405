export default {
  namespaced: true,

  state: () => {
    return {
      supportMessage: '',
    }
  },

  mutations: {
    SET_SUPPORT_MESSAGE (state, option) {
      state.supportMessage = option
    },
  },

  getters: {
    supportMessage (state) {
      return state.supportMessage
    },
  },
}
