import { baseConfig } from '@/config/_config-base.js'

export default {
  ...baseConfig,
  api: {
    ...baseConfig.api,
    host: `https://api.planfred-lsc-group.com/${baseConfig.api.apiversion}`,
    companionUrl: `https://api.planfred-lsc-group.com/${baseConfig.api.apiversion}`, // we use CORS for uppy companion calls
    websocketServerUrl: 'https://api.planfred-lsc-group.com',
  },
  awsS3BucketHost: 'https://lscgrouplu.s3-accelerate.amazonaws.com',
  isApgPlanserver: true,
  headTitleTag: 'LSC Group Planserver',
  logoPath: new URLsrc/assets/img/customer-theme/lscgrouplu/lscgrouplu_logo.png', import.meta.url).href,
  tasksBetaUsers: [ // If an array entry matches with the logged in users email (regex), a user can see the tasks tab
  ],
  customerFeatures: {
    ...baseConfig.customerFeatures,
    htmlTagClass: 'theme theme--lscgrouplu',
    hideFeatureAlerts: true,
    disableUserRegistrationForm: true,
  },
}
