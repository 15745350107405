import constants from '@client-shared/config/constants'

export default {
  namespaced: true,

  state: () => {
    return {
      searchString: '',
      ownership: constants.FILTER_PROPERTIES.PROJECTS_OWNERSHIP_ALL,
    }
  },

  mutations: {
    SET_SEARCH_STRING (state, searchString) {
      state.searchString = searchString
    },

    RESET_SEARCH_STRING (state) {
      state.searchString = ''
    },

    SET_OWNERSHIP (state, ownership) {
      state.ownership = ownership
    },

    FULL_RESET (state) {
      state.ownership = constants.FILTER_PROPERTIES.PROJECTS_OWNERSHIP_ALL
      state.searchString = ''
    },
  },
}
