import generateRandomId from '../utils/crypto-random-uuid.js'

export default class ParticipantVirtual {
  constructor ({ user, createdby, roles, company, firstname, lastname, phone, address, enabled, permission, canapproverevisions, uploadAlert }) {
    this._id = `new_participant_${generateRandomId()}`
    this.user = user
    this.createdby = createdby
    this.roles = roles
    this.company = company
    this.firstname = firstname
    this.lastname = lastname
    this.phone = phone
    this.address = address
    this.enabled = enabled
    this.permission = permission
    this.canapproverevisions = canapproverevisions
    this.uploadAlert = {
      status: uploadAlert ? uploadAlert.status : 'off',
    }

    this.computed = {
      isParticipant: false,
      isVirtual: true,
    }
  }
}
