import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M15.6094 4.25L13.5 6.25L15.5 8.25M14.5781 6.25H18.25C19.3546 6.25 20.25 7.14543 20.25 8.25V15.75C20.25 16.8546 19.3546 17.75 18.25 17.75H13.75M9.25 17.5C9.25 19.0188 8.01878 20.25 6.5 20.25C4.98122 20.25 3.75 19.0188 3.75 17.5C3.75 15.9812 4.98122 14.75 6.5 14.75C8.01878 14.75 9.25 15.9812 9.25 17.5ZM9.25 6.5C9.25 8.01878 8.01878 9.25 6.5 9.25C4.98122 9.25 3.75 8.01878 3.75 6.5C3.75 4.98122 4.98122 3.75 6.5 3.75C8.01878 3.75 9.25 4.98122 9.25 6.5Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }