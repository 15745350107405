import ClipboardFile from '@client-shared/models/clipboard-file.model.js'

import constants from '@/config/constants.js'

export default {
  install: (app) => {
    const $store = app.config.globalProperties.$store
    const $auth = app.config.globalProperties.$auth

    // This listener is to do a live syncing between all open browser tabs. We ONLY do it for the clipboard store
    window.addEventListener('storage', (event) => {
      if (!event.newValue) {
        return
      }

      const project = $store.state.project.project

      const projectId = project?._id
      const userId = $auth.user?._id

      if (!projectId || !userId) {
        return
      }

      const localStorageKey = [
        constants.LOCAL_STORAGE_PREFIX,
        userId,
        projectId,
      ].join('-')

      if (event.key !== localStorageKey) {
        return
      }

      const localStorageContent = JSON.parse(event.newValue)

      if (!localStorageContent.clipboard || !localStorageContent.clipboard.files) {
        return
      }

      const clipboardFiles = localStorageContent.clipboard.files.map(f => new ClipboardFile(f))

      $store.replaceState({
        ...$store.state,
        clipboard: {
          ...$store.state.clipboard,
          files: clipboardFiles,
        },
      })
    })
  },
}
