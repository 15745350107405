import TaskFileLocal from './task-file-local.model'
import TaskFile from './task-file.model'
export default class TaskComment {
  constructor ({ _id, message, files, created, modified, user, stateOld, stateNew }) {
    this._id = _id
    this.message = message
    this.files = files.map(file => {
      if (file instanceof TaskFileLocal) {
        return new TaskFileLocal(file)
      }
      return new TaskFile(file)
    })
    this.created = created
    this.modified = modified
    this.user = user
    this.stateOld = stateOld
    this.stateNew = stateNew
  }
}
