import isOnLocalhost from '@client-shared/utils/is-on-localhost.js'

import { baseConfig } from '@/config/_config-base.js'

export default {
  ...baseConfig,
  showDevLogins: isOnLocalhost && !window.Cypress,
  showGoToWebsiteLink: true,
  showBuildDateInFooter: true,
  stripeApiKeyPublic: 'pk_test_51Ipq5yAXnj3HeKyrZADDyvM1LFCRczJzbg7yZ8SHDyuJLJLVXrxlR9eHuVRxxBpvmAPAWJSlT9fIYS83ziIO4OPX00wycwc6Tt',
  api: {
    ...baseConfig.api, // if you need changes to hosts, apply those at the end of cliesrc/plugins/config-inject.js
  },
  tasksBetaUsers: [ // If an array entry matches with the logged in users email (regex), a user can see the tasks tab
    /.*/,
  ],
  customerFeatures: {
    ...baseConfig.customerFeatures,
    enableAgbPrivacyConfirmation: true,
    disableUserRegistrationForm: false,
    hideFeatureAlerts: false,
    account: {
      ...baseConfig.customerFeatures.account,
      disablePayment: false,
      disableDelete: false,
      disableUsedStorage: false,
    },
  },
}
