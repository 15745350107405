import { getParticipantNameSearchSegments } from './search-helpers.js'
import constants from '../config/constants.js'
import Document from '../models/document.model.js'
import ParticipantVirtual from '../models/participant-virtual.model.js'
import Plan from '../models/plan.model.js'

export default ({ item, project }) => {
  if (!project) {
    console.error('project must be passed to matchesFilterText function')
    return
  }

  if (!(item instanceof Plan) && !(item instanceof Document)) {
    console.error('Plan or Document instance have to passed to matchFilterText')
    return
  }

  const latestRevision = item.revisions[item.revisions.length - 1]

  let authorParticipant

  if (item.author.userId) {
    authorParticipant = project.findParticipantByUserId(item.author.userId)
  } else if (item.author.role) {
    authorParticipant = new ParticipantVirtual({
      roles: [item.author.role],
    })
  }

  const latestUploadParticipant = project.findParticipantByUserId(latestRevision.createdby)
  let approverStrings = []

  // generate approver strings
  if (latestRevision.approval && latestRevision.approval.requested && latestRevision.approval.by) {
    approverStrings = latestRevision.approval.by.flatMap(approver => {
      const participant = project.findParticipantById(approver.participantid)

      return [
        ...getParticipantNameSearchSegments(participant),
        participant.roles[0],
      ]
    })
  }

  const searchIndexes = [
    item.name,
    item.content,
    item.phasetag,
    ...item.tags,
    latestRevision.files && latestRevision.files[0] ? latestRevision.files[0].filename : '',
    latestRevision.dwg_file && latestRevision.dwg_file.filename ? latestRevision.dwg_file.filename : '',
    latestRevision.pdf_file && latestRevision.pdf_file.filename ? latestRevision.pdf_file.filename : '',
    latestRevision.index,
    ...authorParticipant.roles,
    authorParticipant.company,
    ...getParticipantNameSearchSegments(latestUploadParticipant),
    ...approverStrings,
  ]

  if ((project.topCategoriesDocumentsEnabled && item instanceof Document) ||
  (project.topCategoriesPlansEnabled && item instanceof Plan)) {
    searchIndexes.push(item.topCategory)
  }

  if (item.permission && item.permission.restricted && item.permission.users && item.permission.users.length > 0) {
    for (const userId of item.permission.users) {
      const participant = project.findParticipantByUserId(userId)

      searchIndexes.push(...getParticipantNameSearchSegments(participant))
    }
  }

  return searchIndexes
    .filter(Boolean)
    .join(constants.SEARCH_INDEX_SEPARATOR)
}
