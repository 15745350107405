export default class LocationPin {
  constructor ({ _id, pinNumber, lat, lng, status, created, modified }) {
    this._id = _id
    this.pinNumber = pinNumber
    this.lat = lat
    this.lng = lng
    this.status = status
    this.created = created
    this.modified = modified
  }
}
