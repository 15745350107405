import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 25",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M3.25 7.24985L6.54289 3.95696C6.93342 3.56643 7.56658 3.56643 7.95711 3.95696L11.25 7.24985M12.75 16.7498L16.0429 20.0427C16.4334 20.4333 17.0666 20.4333 17.4571 20.0427L20.75 16.7498M7.25 4.99985V20.2498M16.75 3.74985V19.2498",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }