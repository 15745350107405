import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M12.849 8.74977V12.7498M12.849 15.4998V15.4898M11.1299 3.64094L3.64593 16.2276C2.85324 17.5608 3.81397 19.2498 5.365 19.2498H20.333C21.884 19.2498 22.8447 17.5608 22.052 16.2276L14.568 3.64093C13.7928 2.33714 11.9051 2.33714 11.1299 3.64094ZM13.099 15.4998C13.099 15.6378 12.987 15.7498 12.849 15.7498C12.7109 15.7498 12.599 15.6378 12.599 15.4998C12.599 15.3617 12.7109 15.2498 12.849 15.2498C12.987 15.2498 13.099 15.3617 13.099 15.4998Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }, null, -1)
  ])))
}
export default { render: render }