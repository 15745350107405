import dayjs from 'dayjs'
import Cookies from 'universal-cookie'

import constants from '@/config/constants'

const cookies = new Cookies()

export default function (locale) {
  let overrideCookieDomain = window.location.hostname

  if (window.location.hostname === 'app.planfred.com' || window.location.hostname === 'demo.planfred.com') {
    overrideCookieDomain = '.planfred.com'
  }

  if (window.location.hostname === 'app.planfred.com.planfredapp.com') {
    overrideCookieDomain = '.planfred.com.planfredapp.com'
  }

  cookies.set(
    constants.LANG_COOKIE_NAME,
    locale,
    {
      path: '/',
      expires: dayjs().add(1, 'year').toDate(),
      domain: overrideCookieDomain,
      sameSite: 'lax',
    },
  )
}
