import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M7.24985 3.25L3.95696 6.54289C3.56643 6.93342 3.56643 7.56658 3.95696 7.95711L7.24985 11.25M16.7498 12.75L20.0427 16.0429C20.4333 16.4334 20.4333 17.0666 20.0427 17.4571L16.7498 20.75M4.99985 7.25H20.2498M3.74985 16.75H19.2498",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }