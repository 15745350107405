import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M2.94025 11.2008V4.78923C2.94025 3.68466 3.83568 2.78923 4.94025 2.78923H11.3518C11.8822 2.78923 12.3909 2.99994 12.766 3.37502L20.776 11.385C21.557 12.166 21.557 13.4324 20.776 14.2134L14.3644 20.625C13.5834 21.406 12.317 21.406 11.536 20.625L3.52604 12.615C3.15096 12.2399 2.94025 11.7312 2.94025 11.2008Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M8.401 7.5C8.401 7.91421 8.06521 8.25 7.651 8.25C7.23679 8.25 6.901 7.91421 6.901 7.5C6.901 7.08579 7.23679 6.75 7.651 6.75C8.06521 6.75 8.401 7.08579 8.401 7.5Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }