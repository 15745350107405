import Plausible from 'plausible-tracker'

export default {
  install: (app) => {
    const isBackupApp = app.config.globalProperties.$config.isBackupApp

    // do not run on backup app, localhost or file: protocol
    if (isBackupApp || window.location.hostname.includes('localhost') || window.location.hostname.startsWith('1') || window.location.protocol === 'file:') {
      // mock event function but do nothing with it
      app.config.globalProperties.$ga = {
        event: () => {},
      }
      return
    }

    const plausibleOptions = {
      domain: window.location.hostname,
      hashMode: false,
      trackLocalhost: false,
    }

    const { enableAutoPageviews } = Plausible(plausibleOptions)

    enableAutoPageviews()

    app.provide('plausible')

    app.config.globalProperties.$ga = {
      event: ({ eventCategory, eventAction, eventLabel = '' }) => {
        if (!eventCategory || !eventAction) {
          console.error('Pass eventCategory and eventAction to $ga.event call')
        }
      },
    }
  },
}
