import participantsFilter from '@/store/participantsFilter'

export default {
  namespaced: true,

  state: () => {
    return {
      ...participantsFilter.state(),
    }
  },

  getters: {
    ...participantsFilter.getters,
  },

  mutations: {
    ...participantsFilter.mutations,
  },
}
