export default {
  namespaced: true,

  state: () => {
    return {
      searchString: '',
      roles: [],
      permissions: [],

      lastLocalUpdate: null,
      lastServerUpdate: null,
    }
  },

  getters: {
    isFilterActive: (state, getters, rootState) => {
      return state.searchString ||
        state.roles.length > 0 ||
        state.permissions.length > 0
    },
  },

  mutations: {
    FULL_RESET (state) {
      state.searchString = ''
      state.roles = []
      state.permissions = []

      state.lastLocalUpdate = null
      state.lastServerUpdate = null
    },

    SET_FILTERS (state, { filters = {}, modified }) {
      state.searchString = filters.searchString || ''
      state.roles = filters.roles || []
      state.permissions = filters.permissions || []

      state.lastLocalUpdate = null
      state.lastServerUpdate = new Date(modified)
    },

    RESET_FILTERS (state) {
      state.searchString = ''
      state.roles = []
      state.permissions = []

      state.lastLocalUpdate = new Date()
      state.lastServerUpdate = null
    },
  },
}
