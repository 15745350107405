import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M11.4367 13.0753C8.11861 13.1328 5.60502 15.1298 4.56685 17.9369C4.15805 19.0422 5.10269 20.074 6.28121 20.074H12.0843M11.4367 13.0753C11.4857 13.0744 11.5349 13.074 11.5843 13.074C11.9364 13.074 12.2797 13.0957 12.6133 13.1378M11.4367 13.0753C10.7866 13.0866 10.1678 13.1723 9.58445 13.324M12.6133 13.1378C12.947 13.1799 13.271 13.2424 13.5847 13.324M12.6133 13.1378C13.3153 13.2264 13.9747 13.4052 14.5843 13.662M15.3343 6.32404C15.3343 8.39511 13.6554 10.074 11.5843 10.074C9.5133 10.074 7.83437 8.39511 7.83437 6.32404C7.83437 4.25297 9.5133 2.57404 11.5843 2.57404C13.6554 2.57404 15.3343 4.25297 15.3343 6.32404Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M17.8219 17.5282C17.8219 17.9424 17.4861 18.2782 17.0719 18.2782C16.6577 18.2782 16.3219 17.9424 16.3219 17.5282C16.3219 17.114 16.6577 16.7782 17.0719 16.7782C17.4861 16.7782 17.8219 17.114 17.8219 17.5282Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M20.5776 17.194C18.792 14.1945 15.3519 14.1946 13.5663 17.1941C13.4438 17.3999 13.4438 17.6566 13.5663 17.8624C15.3519 20.8619 18.792 20.8619 20.5776 17.8624C20.7001 17.6566 20.7001 17.3998 20.5776 17.194Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }