import constants from '@/config/constants.js'

export default {
  namespaced: true,

  state: () => {
    return {
      sortProperty: constants.SORT_PROPERTIES.TASK_MODIFIED,
      sortOrderReversed: false,
    }
  },

  mutations: {
    RESET_SORTING (state) {
      state.sortProperty = constants.SORT_PROPERTIES.TASK_MODIFIED
      state.sortOrderReversed = false
    },

    SET_SORT_PROPERTY (state, sortProperty) {
      state.sortProperty = sortProperty
    },

    SET_SORT_ORDER_REVERSED (state, sortOrderReversed) {
      state.sortOrderReversed = sortOrderReversed
    },

    FULL_RESET (state) {
      state.sortProperty = constants.SORT_PROPERTIES.TASK_MODIFIED
      state.sortProperty = false
    },
  },
}
