const getLatestApprovedRevision = item => {
  if (item.revisions.length < 2) {
    return
  }

  const latestRevision = item.revisions[item.revisions.length - 1]

  const hasLatestRevisionApprovalRequested = latestRevision?.approval?.requested
  if (hasLatestRevisionApprovalRequested) {
    return
  }

  const hasApprovedRevision = item.revisions.find(r => r.approval?.requested && r.approvalStatus === 'approved')
  if (!hasApprovedRevision) {
    return
  }

  // loop through revisions in reverse order until you find first approved revision
  let latestAprovedRevision
  for (let i = item.revisions.length - 1; i >= 0; i--) {
    if (item.revisions[i]?.approval?.requested && item.revisions[i].approvalStatus === 'approved') {
      latestAprovedRevision = item.revisions[i]
      break
    }
  }

  return latestAprovedRevision
}

export default getLatestApprovedRevision
