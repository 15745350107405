import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M9.25 10.75H6.75M6.75 13.75V19.25C6.75 20.3546 7.64543 21.25 8.75 21.25H15.25C16.3546 21.25 17.25 20.3546 17.25 19.25V13.75M6.75 13.75H17.25M6.75 13.75V17.25H4.75C3.64543 17.25 2.75 16.3546 2.75 15.25V8.75C2.75 7.64543 3.64543 6.75 4.75 6.75H19.25C20.3546 6.75 21.25 7.64543 21.25 8.75V15.25C21.25 16.3546 20.3546 17.25 19.25 17.25H17.25V13.75M8.75 2.75H15.25C16.3546 2.75 17.25 3.64543 17.25 4.75V6.75H6.75V4.75C6.75 3.64543 7.64543 2.75 8.75 2.75Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }