import ObjectID from 'bson-objectid'

import TaskFile from './task-file.model'
import UnifiedFile from './unified-file.model'

export default class TaskFileLocal extends TaskFile {
  constructor ({ _id, projectId, taskId, imgSrc, uri, ...args }) {
    super(args)

    this._id = _id || ObjectID().toString()
    this.projectId = projectId
    this.taskId = taskId
    this.imgSrc = imgSrc
    this.uri = uri
  }

  convertToUnifiedFile () {
    return new UnifiedFile({
      _id: this._id,
      fileName: this.name,
      created: this.created,
      size: this.size,
      mimeType: this.mimeType,
      s3Key: this.s3Key,
      imgSrc: this.imgSrc,
      uri: this.uri,
    })
  }
}
