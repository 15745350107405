<template>
  <PfModal
    v-if="showModal"
    class="com_modal--sm"
    :footer-is-sticky="true"
    data-testid="demoserver-modal"
    :context-ids="[]"
    @after:close-animation="$emit('after:close-animation')"
  >
    <template #title>
      {{ $t('feature.components.modal_demoserver.start_own_project') }}
    </template>

    <div
      class="com_modal__window-body"
    >
      <ul
        class="demo-onboarding__feature-list"
      >
        <li
          class="demo-onboarding__feature-list-item"
        >
          <PfIcon
            name="checkmark"
            class="demo-onboarding__feature-list-icon"
          />{{ $t('feature.components.modal_demoserver.free_for_30_days') }}
        </li>

        <li
          class="demo-onboarding__feature-list-item"
        >
          <PfIcon
            name="checkmark"
            class="demo-onboarding__feature-list-icon"
          />{{ $t('feature.components.modal_demoserver.no_binding_guaranteed') }}
        </li>

        <li
          class="demo-onboarding__feature-list-item"
        >
          <PfIcon
            name="checkmark"
            class="demo-onboarding__feature-list-icon"
          />{{ $t('feature.components.modal_demoserver.many_satisfied_customers', { counter: '100.000' }) }}
        </li>

        <li
          class="demo-onboarding__feature-list-item"
        >
          <PfIcon
            name="checkmark"
            class="demo-onboarding__feature-list-icon"
          />{{ $t('feature.components.modal_demoserver.no_installation_required') }}
        </li>
      </ul>

      <p
        class="demo-onboarding__text"
      >
        {{ $t('feature.components.modal_demoserver.continue_projects_after_trial') }}
        <br>

        <b>{{ $t('feature.components.modal_demoserver.data_doesnt_get_deleted') }}</b>
      </p>
    </div>

    <PfButtonBar
      class="com_modal__window-footer"
    >
      <a
        :href="$t('data.pf_links.planfred_app_registration')"
        class="comv2_button-bar__button obj_button"
        target="_blank"
        @click="onClickFirstProjectLink"
      >
        <span
          class="label"
        >
          {{ $t('feature.components.modal_demoserver.create_first_project') }}
        </span>
      </a>

      <PfButtonBarLink
        :href="$t('data.pf_links.planfred_website')"
        target="_blank"
        @click="onClickLearnMoreLink"
      >
        {{ $t('feature.components.modal_demoserver.learn_more_about_planfred') }}
      </PfButtonBarLink>
    </PfButtonBar>
  </PfModal>
</template>

<script>
import PfButtonBar from '@/components/PfButtonBar.vue'
import PfButtonBarLink from '@/components/PfButtonBarLink.vue'
import PfModal from '@/components/PfModal.vue'

export default {
  name: 'ModalDemoserver',

  components: {
    PfButtonBarLink,
    PfButtonBar,
    PfModal,
  },

  emits: [
    'after:close-animation',
  ],

  data () {
    return {
      msUntilModalIsShown: this.$config.msToShowDemoserverModal,
      showModal: false,
    }
  },

  mounted () {
    window.setTimeout(() => {
      this.$ga.event({ eventCategory: 'demoprojekt', eventAction: 'CRM Demo-Projekt Modal Window angezeigt' })

      window.localStorage.setItem(this.$config.constants.LOCAL_STORAGE_DEMOSERVER_MODAL, 'true')
      this.showModal = true
    }, this.msUntilModalIsShown)
  },

  methods: {
    onClickFirstProjectLink () {
      this.$ga.event({ eventCategory: 'gotoAppRegistration', eventAction: 'gotoAppRegistration via demo-modal-button' })
    },

    onClickLearnMoreLink () {
      this.$ga.event({ eventCategory: 'gotoWebsite', eventAction: 'gotoWebsite via demo-modal-link' })
    },
  },
}
</script>
