import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "25",
  height: "24",
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M13.1962 19.25H6.1462C5.02609 19.25 4.46604 19.25 4.03822 19.032C3.66189 18.8403 3.35593 18.5343 3.16419 18.158C2.9462 17.7302 2.9462 17.1701 2.9462 16.05V7.95C2.9462 6.82989 2.9462 6.26984 3.16419 5.84202C3.35593 5.46569 3.66189 5.15973 4.03822 4.96799C4.46604 4.75 5.0261 4.75 6.1462 4.75H18.2462C19.3663 4.75 19.9264 4.75 20.3542 4.96799C20.7305 5.15973 21.0365 5.46569 21.2282 5.84202C21.4462 6.26984 21.4462 6.8299 21.4462 7.95V11",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M3.1962 5.63635L11.1723 10.3898C11.8031 10.7657 12.5893 10.7657 13.2201 10.3898L21.1962 5.63635",
      stroke: "#FF0000",
      "stroke-width": "1.5"
    }, null, -1),
    _createElementVNode("path", {
      d: "M19.1962 13.75V20.25",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M22.4462 17H15.9462",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }, null, -1)
  ])))
}
export default { render: render }