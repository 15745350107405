import UnifiedFile from './unified-file.model'

export default class TaskFile {
  constructor ({ _id, created, s3Key, name, mimeType, width, height, size, resolutions }) {
    this._id = _id
    this.created = created
    this.s3Key = s3Key
    this.name = name
    this.mimeType = mimeType
    this.width = width
    this.height = height
    this.size = size

    this.resolutions = {
      thumb: resolutions && resolutions.thumb ? { ...resolutions.thumb } : undefined,
      large: resolutions && resolutions.large ? { ...resolutions.large } : undefined,
    }
  }

  convertToUnifiedFile ({ resolution } = {}) {
    if (this.resolutions && this.resolutions[resolution]) {
      const resolutionFile = this.resolutions[resolution]

      return new UnifiedFile({
        _id: resolutionFile._id,
        fileName: resolutionFile.name,
        size: resolutionFile.size,
        mimeType: resolutionFile.mimeType,
        s3Key: resolutionFile.s3Key,
        resolutions: this.resolutions,
      })
    }

    return new UnifiedFile({
      _id: this._id,
      fileName: this.name,
      created: this.created,
      size: this.size,
      mimeType: this.mimeType,
      s3Key: this.s3Key,
      resolutions: this.resolutions,
    })
  }
}
