import getIsApprovalForRevisionRequested from './get-is-approval-for-revision-requested'

const _getApproverWithLatestAction = (approvers) => {
  return approvers.reduce((acc, current) => {
    // if no date in current, just return acc
    if (!current.date && current.latestCommentDate) {
      return acc
    }

    // if no date in acc, but in current, make current new acc
    if ((!acc.date && !acc.latestCommentDate) && (current.date || current.latestCommentDate)) {
      acc = current
      return acc
    }

    // else compare current with acc
    let accDate
    let currentDate

    if (acc.date) {
      accDate = acc.latestCommentDate
    }
    if (acc.latestCommentDate) {
      accDate = acc.latestCommentDate
    }

    if (current.date) {
      currentDate = current.date
    }
    if (current.latestCommentDate) {
      currentDate = current.latestCommentDate
    }

    if (currentDate > accDate) {
      acc = current
    }

    return acc
  }, 0)
}

const getLatestApproverActionDate = revision => {
  // no approval requested, there is no date
  if (!getIsApprovalForRevisionRequested(revision)) {
    return
  }

  const approverWithLatestAction = _getApproverWithLatestAction(revision.approval.by)

  if (approverWithLatestAction.latestCommentDate) {
    return approverWithLatestAction.latestCommentDate
  }
  return approverWithLatestAction.date
}

export default getLatestApproverActionDate
