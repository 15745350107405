/**
 * EMAIL_REGEX has to match regex in file servsrc/models/utils/validators/email-validation-regex-server.js
 *
 * This is checked in the cypress spec
 */

// basic email regex taken from emailregex.com
// - modified to allow german umlauts öüä in hostname
// - modified local part (mailbox) to comply with postmarkapp

// old regex till 2024-09-11
// problem with this regex: it allows also 'ß' and other non ASCII chars in local (user) part of email. This does not work with postmarkapp
// const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:äöü\s@"]+(\.[^<>()\[\]\\.,;:äöü\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9äüö-]+\.)+[a-zA-Z]{2,}))$/

// 2024-09-11
// New - local part regex by postmark ( ^[a-z0-9\.!#\$%&'`\*\+\-\/=\^_\{\}\|~]+$  -> from https://postmarkapp.com/support/article/1180-what-does-errorcode-300-mean
// Umlauts and other non ASCII trigger postmark error 300 (https://postmarkapp.com/support/article/1180-what-does-errorcode-300-mean)

const EMAIL_REGEX = /^([a-z0-9.!#$%&'`*+\-/=^_{}|~]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9äüö-]+\.)+[a-zA-Z]{2,}))$/i

export default EMAIL_REGEX
