import { baseConfig } from '@/config/_config-base.js'

export default {
  ...baseConfig,
  api: {
    ...baseConfig.api,
    host: `https://api.app.egv-planserver.de/${baseConfig.api.apiversion}`,
    companionUrl: `https://api.app.egv-planserver.de/${baseConfig.api.apiversion}`, // we use CORS for uppy companion calls
    websocketServerUrl: 'https://api.app.egv-planserver.de',
  },
  awsS3BucketHost: 'https://egvplanserverde.s3-accelerate.amazonaws.com',
  isEgvPlanserver: true,
  headTitleTag: 'EGV Planserver',
  logoPath: new URLsrc/assets/img/customer-theme/egvplanserverde/egvplanserverde_logo.svg', import.meta.url).href,
  tasksBetaUsers: [ // If an array entry matches with the logged in users email (regex), a user can see the tasks tab
  ],
  customerFeatures: {
    ...baseConfig.customerFeatures,
    htmlTagClass: 'theme theme--egvplanserverde',
    isUserAllowedToCreateNewProject: email => {
      const allowedToCreateNewProject = [
        'wolfgang.moennikes@erzbistum-paderborn.de',
        'egvplanserver_cypress@test.planfredapp.com', // user for cypress tests - do not remove!
        'approver@test.planfredapp.com', // user for cypress tests - do not remove!
      ]

      return allowedToCreateNewProject.map(email => email.toLowerCase()).includes(email.toLowerCase())
    }, // all users are allowed to create new projects

  },
}
