<template>
  <div
    v-click-away="onClickOutside"
    class="com_language-menu"
    :data-testid-language="currentLocaleObj.code"
  >
    <div
      class="com_language-menu__toggle"
      data-testid="language-dropdown-toggle"
      :class="`com_language-menu__toggle--${currentLocaleObj.code}`"
      @click="onClickLanguageToggle"
    >
      <span
        class="com_language-menu__toggle__label"
      >
        {{ currentLocaleObj.labelShort }}
      </span>
    </div>

    <ul
      v-if="isLanguageDropdownOpen"
      class="com_context-menu"
    >
      <li
        v-for="localeObj in availableLocaleObjs"
        :key="localeObj.code"
        class="
          com_context-menu__item
          com_language-menu__item
        "
        :class="
          [
            `com_language-menu__item--${localeObj.code}`,
            {
              'is_active': localeObj.code === currentLocaleObj.code,
            },
          ]
        "
        @click="onClickLanguageSwitchOption(localeObj.code)"
      >
        <span
          :data-testid="`select-language-${localeObj.code}`"
          class="
            com_context-menu__link
            com_language-menu__item__label
          "
        >
          {{ localeObj.labelLong }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { updateLanguageSelected } from '@client-shared/api/users.api.js'
import { vOnClickOutside } from '@vueuse/components'

import setLangCookieDomain from '@/utils/set-lang-cookie-domain'

export default {
  name: 'LanguageSwitch',

  directives: {
    clickOutside: vOnClickOutside,
  },

  data () {
    return {
      isLanguageDropdownOpen: false,
    }
  },

  computed: {
    availableLocaleObjs () {
      return this.$config.i18n.availableLocales
    },

    currentLocaleObj () {
      return this.availableLocaleObjs.find(l => l.code === this.$i18n.locale)
    },
  },

  methods: {
    onClickLanguageToggle () {
      this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen
    },

    onClickOutside () {
      this.isLanguageDropdownOpen = false
    },

    async onClickLanguageSwitchOption (newLocale) {
      this.isLanguageDropdownOpen = false
      this.$i18n.locale = newLocale

      setLangCookieDomain(newLocale)

      // on demo.planfred.com do not save language selection of user arch@planmanager.at on server
      // every visitor should get its own language based on browser or local setting, but not on global user setting from DB
      if (!this.$auth.loggedIn || this.$config.isDemoserver) {
        return
      }

      await updateLanguageSelected({
        axios: this.$axios,
        user: this.$auth.user,
        newValue: newLocale,
      })

      return this.$auth.fetchUser()
    },
  },
}
</script>
