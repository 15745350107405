export const fetchRefreshToken = ({
  $axios,
  token,
}) => {
  return $axios.post('refresh-token', {
    token,
  },
  {
    headers: {
      Authorization: null,
    },
  })
}
