import * as DocumentsApi from '@client-shared/api/documents.api.js'

export default {
  namespaced: true,

  state: () => {
    return {
      list: [],
      importedIds: [],
    }
  },

  getters: {
    getById: (state, getters, rootState) => {
      return documentId => { return state.list.find(document => document._id === documentId) }
    },

    getByName: (state, getters, rootState) => {
      return documentName => { return state.list.filter(document => document.name === documentName) }
    },
  },

  mutations: {
    SET_DOCUMENTS (state, documents) {
      state.list = documents.filter(plan => !plan.trashed)
    },

    REPLACE_DOCUMENT (state, document) {
      const documentIndex = state.list.findIndex(p => p._id === document._id)

      state.list[documentIndex] = document
    },

    SET_IMPORTED_IDS (state, importedIds) {
      state.importedIds = [...importedIds]
    },

    RESET_IMPORTED_IDS (state) {
      state.importedIds = []
    },

    RESET_LIST (state) {
      state.list = []
    },
  },

  actions: {
    async fetch ({ commit }, project) {
      const documents = await DocumentsApi.getDocuments({
        axios: this.$axios,
        projectId: project._id,
      })

      commit('SET_DOCUMENTS', documents)
    },
  },
}
