const updateApproverStatusOfRevisions = revisions => {
  return [...revisions].map(revision => {
    if (!revision.approval || !revision.approval.requested) {
      return revision
    }

    return {
      ...revision,
      approval: {
        ...revision.approval,
        by: [...revision.approval.by].map(approver => {
          if (revision.approval.mode.approvalmode === 'or') {
            // approval is done, all pending are obsolete
            if (revision.approvalStatus !== 'pending' && approver.approved === 'pending') {
              return {
                ...approver,
                approved: 'obsolete',
              }
            }
          }

          return approver
        }),
      },
    }
  })
}

export default updateApproverStatusOfRevisions
