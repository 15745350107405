<template>
  <div
    class="com_message-box"
    :class="{
      'obj--sm': size === 'sm',
      'obj--md': size === 'md',
      'obj--lg': size === 'lg',
      'com_message-box--block': isBlock,
      'com_form__alert': isForm,
      'com_message-box--align-center': isCentered,
      'com_message-box--align-right': isRight,
    }"
  >
    <PfIcon
      v-if="iconName"
      :name="iconName"
      class="icon"
    />

    <div
      class="label"
    >
      {{ message }}

      <slot
        v-if="!message"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageBoxBase',

  props: {
    message: {
      type: String,
      default: '',
    },

    iconName: {
      type: String,
      default: undefined,
    },

    size: {
      type: String,
      default: '',
    },

    isBlock: {
      type: Boolean,
      default: false,
    },

    isForm: {
      type: Boolean,
      default: false,
    },

    isCentered: {
      type: Boolean,
      default: false,
    },

    isRight: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
