import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M15.337 6.32404C15.337 8.39511 13.6581 10.074 11.587 10.074C9.51591 10.074 7.83698 8.39511 7.83698 6.32404C7.83698 4.25297 9.51591 2.57404 11.587 2.57404C13.6581 2.57404 15.337 4.25297 15.337 6.32404Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M11.587 13.074C8.19584 13.074 5.62298 15.0883 4.56947 17.9369C4.16067 19.0422 5.10531 20.074 6.28383 20.074H16.8902C18.0687 20.074 19.0133 19.0422 18.6045 17.9369C17.551 15.0883 14.9782 13.074 11.587 13.074Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }