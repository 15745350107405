const isString = function (value) {
  return typeof value === 'string' || value instanceof String
}

const stringValidation = function ({ value, validValues, propKey }) {
  if (!Array.isArray(validValues)) {
    throw new TypeError('Passed "validValues" is not an Array')
  }

  const invalidStrings = validValues.filter(value => !isString(value))
  if (invalidStrings.length) {
    throw new TypeError(`Passed "validValues" contains entries which are not strings: ${validValues.join(', ')}`)
  }

  if (!isString(value)) {
    throw new TypeError('Passed "value" is not a String')
  }

  if (!isString(propKey)) {
    throw new TypeError('Passed "propKey" is not a String')
  }

  if (!validValues.includes(value)) {
    throw new Error(`'${value}' invalid. Only ${validValues.join(', ')} allowed for prop '${propKey}' of type String`)
  }

  return true
}

export {
  stringValidation,
}
