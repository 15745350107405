import isOnLocalhost from '@client-shared/utils/is-on-localhost'

import packageJson from '../../package.json'

import sendRaygun from '@/utils/send-raygun.js'

export default {
  install: (app) => {
    // uncomment if statement in client/index.html to enable raygun client on localhost
    if (!window.rg4js) {
      return
    }

    // log to console on localhost
    if (isOnLocalhost) {
      window.console = (function (origConsole) {
        if (!window.console || !origConsole) {
          origConsole = {}
        }
        return {
          ...origConsole,
          error () {
            try {
              origConsole.error.apply(origConsole, arguments)
            } catch (err) {} // Ignore error during console.error

            sendRaygun({
              error: new Error(arguments[0]),
            })
          },
        }
      }(window.console))

      if (window.rg4js) {
        window.rg4js('apiKey', 'h1T78R9XAtw6VBIwi7yXA')
      }

      return
    }

    const $router = app.config.globalProperties.$router

    $router.afterEach((to, from) => {
      window.rg4js('trackEvent', {
        type: 'pageView',
        path: to.path,
      })
    })

    if (window.location.hostname === 'app.planfred.com' || window.location.hostname === 'demo.planfred.com') {
      window.rg4js('apiKey', 'WhZkDCKmsHbmYjruBaB54g')
    }

    if (window.location.hostname === 'app.planfred.com.planfredapp.com') {
      window.rg4js('apiKey', 'yOGoaGTgobuPCIRLUVqVJA')
    }

    if (window.location.hostname === 'app.apg-planserver.at') {
      window.rg4js('apiKey', 'UllbCCeO3xGNotQElHQw')
    }

    if (window.location.hostname === 'app.koupserver.at') {
      window.rg4js('apiKey', 'jcA2cU7FCB9HdvDRZrGiZg')
    }

    if (window.location.hostname === 'app.egv-planserver.de') {
      window.rg4js('apiKey', 'QPWDkG0nE0TbtOu9txRCA')
    }

    if (window.location.hostname === 'app.klg-planserver.de') {
      window.rg4js('apiKey', 'texrZgyUTBBb7j0JLGeobQ')
    }

    if (window.location.hostname === 'app.staabserver.com') {
      window.rg4js('apiKey', 'Gyma1L7WdzVAogUbQvQw')
    }

    if (window.location.hostname === 'planfred.lsc-group.lu') {
      window.rg4js('apiKey', 'Wdy0cKupdBBBh060hhdGAg')
    }

    window.rg4js('enableCrashReporting', true)
    window.rg4js('enablePulse', false) // real user monitoring
    window.rg4js('filterSensitiveData', [
      'oldPassword',
      'password',
      'credit_card',
      'confirmationtoken',
      'token',
      'access_token',
      'refresh_token',
    ])
    window.rg4js('setFilterScope', 'all') // Filter any key in the payload
    window.rg4js('setVersion', packageJson.version)

    // window.rg4js('logContentsOfXhrCalls', true) // would leak sensitive data

    app.config.warnHandler = function (err, vm, info) {
      window.rg4js('send', {
        error: err,
        customData: [{ info }],
      })
    }

    app.config.errorHandler = function (err, vm, info) {
      window.rg4js('send', {
        error: err,
        customData: [{ info }],
      })

      throw err
    }
  },
}
