import { jwtDecode } from 'jwt-decode'
const TIMEOUT_OFFSET = 10 * 1000 // 10 seconds

export const isTokenValid = (token, offset = TIMEOUT_OFFSET) => {
  if (!token) return false

  const decodedToken = jwtDecode(token)
  const exp = new Date(decodedToken.exp * 1000).getTime()
  const cur = new Date().getTime()
  return exp > (cur + offset)
}
