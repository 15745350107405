<template>
  <div
    v-if="state === validStates.INITIAL && showDemoBanner"
    data-testid="demoserver-banner-component"
    class="com_notification-box com_notification-box--buttonbar-right"
  >
    <ButtonClose
      data-testid="btn-close-demo-app-banner"
      size="md"
      class="com_notification-box__x-close-button"
      @click="onClickClose"
    />

    <div
      class="message-content com_notification-box__content"
    >
      <h4
        class="com_notification-box__title"
      >
        {{ $t('feature.components.banner_demoserver.you_are_on_demoserver') }}
      </h4>

      <p
        class="com_notification-box__text"
      >
        {{ $t('feature.components.banner_demoserver.daily_reset') }}
      </p>
    </div>

    <PfButtonBar
      class="com_notification-box__action"
    >
      <a
        :href="$t('data.pf_links.planfred_app_registration')"
        data-testid="create-own-project-link"
        class="comv2_button-bar__button obj_button"
        target="_blank"
        @click="onClickFirstProjectLink"
      >
        <span
          class="label"
        >
          {{ $t('feature.components.banner_demoserver.create_first_project') }}
        </span>
      </a>

      <a
        :href="$t('data.pf_links.planfred_website')"
        data-testid="link-to-website-in-current-language"
        class="comv2_button-bar__link"
        target="_blank"
        @click="onClickLearnMoreLink"
      >
        {{ $t('feature.components.banner_demoserver.learn_more_about_planfred') }}
      </a>
    </PfButtonBar>
  </div>
</template>

<script>
import ButtonClose from '@/components/ButtonClose.vue'
import PfButtonBar from '@/components/PfButtonBar.vue'

export default {
  name: 'BannerDemoserver',

  components: {
    PfButtonBar,
    ButtonClose,
  },

  data () {
    const validStates = Object.freeze({
      INITIAL: 0,
      BANNER_CLOSED: 1,
    })
    return {
      state: validStates.INITIAL,
      validStates,
      currentDateStamp: new Date().toISOString().split('T')[0],
    }
  },

  computed: {
    showDemoBanner () {
      const dateInLocalstorage = window.localStorage.getItem('planfred-demo-banner-closed-date-stamp')
      return !dateInLocalstorage || this.currentDateStamp !== dateInLocalstorage
    },
  },

  methods: {
    onClickFirstProjectLink () {
      this.$ga.event({
        eventCategory: 'gotoAppRegistration',
        eventAction: 'gotoAppRegistration via demo-header-button',
      })
    },

    onClickLearnMoreLink () {
      this.$ga.event({ eventCategory: 'gotoWebsite', eventAction: 'gotoWebsite via demo-header-link' })
    },

    onClickClose () {
      this.state = this.validStates.BANNER_CLOSED
      window.localStorage.setItem('planfred-demo-banner-closed-date-stamp', this.currentDateStamp)
    },
  },
}
</script>
