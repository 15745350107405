const mockRandomUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

export default () => {
  if (crypto?.randomUUID) {
    return crypto.randomUUID()
  }

  // crypto.randomUUID() is only available via https
  // mock it for development on localhost
  return mockRandomUUID()
}
