import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 25",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M12 8.41203V6.74536M12 8.41203H10.3333C8.49238 8.41203 7 6.91965 7 5.0787C7 4.15822 7.74619 3.41203 8.66667 3.41203C10.5076 3.41203 12 4.90441 12 6.74536M12 8.41203H13.6667C15.5076 8.41203 17 6.91965 17 5.0787C17 4.15822 16.2538 3.41203 15.3333 3.41203C13.4924 3.41203 12 4.90441 12 6.74536M12 8.41203H5.75C4.64543 8.41203 3.75 9.30746 3.75 10.412V10.662C3.75 11.4023 4.1522 12.0486 4.75 12.3944M12 8.41203H18.25C19.3546 8.41203 20.25 9.30746 20.25 10.412V10.662C20.25 11.4023 19.8478 12.0486 19.25 12.3944M12 8.41203V12.662M4.75 12.3944V18.912C4.75 20.0166 5.64543 20.912 6.75 20.912H12M4.75 12.3944C5.04417 12.5646 5.38571 12.662 5.75 12.662H12M4.75 12.3944V12.162M19.25 12.3944V18.912C19.25 20.0166 18.3546 20.912 17.25 20.912H12M19.25 12.3944C18.9558 12.5646 18.6143 12.662 18.25 12.662H12M19.25 12.3944V12.162M12 12.662V20.912",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }