import Plan from '@client-shared/models/plan.model'

export default {
  namespaced: true,

  getters: {
    isAdmin: (state, getters, rootState) => {
      const project = rootState.project.project
      const currentUser = rootState.currentUser.user

      if (!project || !currentUser) {
        return false
      }

      const participant = project.findParticipantByUserId(currentUser._id)
      if (!participant) {
        return false
      }

      return participant.permission === 'admin'
    },

    isOwner: (state, getters, rootState) => {
      const project = rootState.project.project
      const currentUser = rootState.currentUser.user

      if (!project || !currentUser) {
        return false
      }

      const participant = project.findParticipantByUserId(currentUser._id)
      if (!participant) {
        return false
      }

      return participant.permission === 'owner'
    },

    isAdminOrOwner: (state, getters, rootState) => {
      return getters.isOwner || getters.isAdmin
    },

    isCurrentUserPlanDocAuthor: (state, getters, rootState) => {
      return planDoc => {
        const currentUser = rootState.currentUser.user

        if (!currentUser) {
          return false
        }

        return Boolean(planDoc.author.userId && planDoc.author.userId === currentUser._id)
      }
    },

    isCurrentUserTaskAssignee: (state, getters, rootState) => {
      return task => {
        const project = rootState.project.project
        const currentUser = rootState.currentUser.user

        if (!project || !currentUser) {
          return false
        }

        const currentUserParticipant = project.findParticipantByUserId(currentUser._id)
        const assigneeParticipant = project.findParticipantByUserId(task.assigneeUserId)

        if (!currentUserParticipant || !assigneeParticipant) {
          return false
        }

        return currentUserParticipant._id === assigneeParticipant._id
      }
    },

    isUserEditingHimself: (state, getters, rootState) => {
      return participant => {
        const currentUser = rootState.currentUser.user

        return participant.user._id === currentUser._id
      }
    },

    hasCurrentUserRoleOfPlanDocCreator: (state, getters, rootState) => {
      return planDoc => {
        const project = rootState.project.project
        const currentUser = rootState.currentUser.user

        if (!project || !currentUser) {
          return false
        }

        const currentUserParticipant = project.findParticipantByUserId(currentUser._id)
        const authorParticipant = project.findParticipantByPlanDocAuthor(planDoc.author)

        if (!currentUserParticipant || !authorParticipant) {
          return false
        }

        return currentUserParticipant.roles[0] === authorParticipant.roles[0]
      }
    },

    hasCurrentUserRoleOfTaskCreator: (state, getters, rootState) => {
      return task => {
        const project = rootState.project.project
        const currentUser = rootState.currentUser.user

        if (!project || !currentUser) {
          return false
        }

        const currentUserParticipant = project.findParticipantByUserId(currentUser._id)
        const creatorParticipant = project.findParticipantByUserId(task.creatorUserId)

        if (!currentUserParticipant || !creatorParticipant) {
          return false
        }

        return currentUserParticipant.roles[0] === creatorParticipant.roles[0]
      }
    },

    isAllowedToUpdatePlanDoc: (state, getters, rootState) => {
      return planDoc => getters.isAdminOrOwner || getters.hasCurrentUserRoleOfPlanDocCreator(planDoc) || getters.isCurrentUserPlanDocAuthor(planDoc)
    },

    isAuthorizedToUpdateTask: (state, getters, rootState) => {
      return task => {
        const isNewTask = !task.created // Created date is set on server

        return isNewTask || getters.isAdminOrOwner || getters.hasCurrentUserRoleOfTaskCreator(task)
      }
    },

    isAuthorizedToUpdateTaskStatus: (state, getters, rootState) => {
      return task => getters.isAdminOrOwner || getters.hasCurrentUserRoleOfTaskCreator(task) || getters.isCurrentUserTaskAssignee(task)
    },

    isAllowedToUpdateParticipant: (state, getters, rootState) => {
      return participant => {
        if (getters.isOwner) {
          return true
        } else {
          return participant.permission !== 'owner' && getters.isAdminOrOwner
        }
      }
    },

    isAuthorizedToUpdateTaskComment: (state, getters, rootState) => {
      return ({ task, taskComment }) => {
        if (!task || !taskComment) {
          throw new Error('task and taskComment must be provided to "acl/isAuthorizedToUpdateTaskComment" function')
        }
        const isOwnComment = taskComment.user === rootState.currentUser.user._id
        const isLastComment = task.comments[task.comments.length - 1]._id === taskComment._id
        const isTaskDeleted = task.trashed

        return isOwnComment && isLastComment && !isTaskDeleted
      }
    },

    isParticipantAuthorizedToReadPlanDoc: (state, getters) => {
      return ({ participant, planDoc }) => {
        if (!participant.enabled || participant.permission === 'silent') {
          return false
        }

        if (planDoc instanceof Plan) {
          return true
        }

        if (!planDoc.permission || !planDoc.permission.restricted) {
          return true
        }

        const isInPermissionList = Boolean(planDoc.permission.users.includes(participant.user._id))
        const isAuthor = Boolean(planDoc.author.userId && planDoc.author.userId === participant.user._id)
        const isAuthorRoleAndParticipantHasSameRole = Boolean(planDoc.author.role && planDoc.author.role === participant.roles[0])
        const isParticipantAdminOrOwner = participant.permission === 'admin' || participant.permission === 'owner'

        return isParticipantAdminOrOwner || isInPermissionList || isAuthor || isAuthorRoleAndParticipantHasSameRole
      }
    },

    canUseStripe: (state, getters, rootState) => {
      const currentUser = rootState.currentUser.user
      const language = currentUser.languageSelected || currentUser.languageBrowser
      const hasStripePaymentMethodId = currentUser.payment && currentUser.payment.stripePaymentMethodId

      return language === 'en-us' || hasStripePaymentMethodId
    },
  },
}
