import tasksFilterMain from '@/store/tasksFilterMain'

export default {
  namespaced: true,

  state: () => {
    return {
      ...tasksFilterMain.state(),
    }
  },

  getters: {
    ...tasksFilterMain.getters,
  },

  mutations: {
    ...tasksFilterMain.mutations,
  },
}
