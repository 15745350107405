import Document from '@client-shared/models/document.model'
import LocationLayer from '@client-shared/models/location-layer.model'
import Participant from '@client-shared/models/participant.model'
import Plan from '@client-shared/models/plan.model'
import Project from '@client-shared/models/project.model'
import Task from '@client-shared/models/task.model'
import { io } from 'socket.io-client'

import i18nInstance from '@/plugins/i18n'

const $t = i18nInstance.global.t

function logPopupContextUpdate ({ app, socketAction, socketPayload, updatedContextId }) {
  const store = app.config.globalProperties.$store
  const logger = app.config.globalProperties.$logger

  const firstPopupWithContext = store.state.layout.openPopups.find(popup => popup.contextIds.includes(updatedContextId))

  if (!firstPopupWithContext) {
    return
  }

  logger.log({
    tag: 'POPUP_CONTEXT_UPDATED_VIA_SOCKET',
    context: {
      socketAction,
      socketPayload,
      updatedContextId,
      openPopups: store.state.layout.openPopups.map(popup => {
        return {
          popupId: popup.popupId,
          popupType: popup.popupType,
          contextIds: popup.contextIds,
          componentName: popup.componentName,
        }
      }),
    },
  })
}

function logListReload ({ app, socketAction, projectId }) {
  const store = app.config.globalProperties.$store
  const logger = app.config.globalProperties.$logger

  const hasOpenPopup = store.state.layout.openPopups.length > 0

  if (!hasOpenPopup) {
    return
  }

  logger.log({
    tag: 'TRIGGER_RELOAD_VIA_SOCKET',
    context: {
      socketAction,
      socketPayload: {
        projectId,
      },
      openPopups: store.state.layout.openPopups.map(popup => {
        return {
          popupId: popup.popupId,
          popupType: popup.popupType,
          contextIds: popup.contextIds,
          componentName: popup.componentName,
        }
      }),
    },
  })
}

export default {
  install: (app) => {
    let ioConnection
    let isConnected = false

    const socket = {
      connect (projectId) {
        if (isConnected || app.config.globalProperties.$config.isBackupApp) {
          return
        }

        if (!projectId) {
          console.error('No projectId passed to connect to websocket')
          return
        }

        ioConnection = io.connect(app.config.globalProperties.$config.api.websocketServerUrl, {
          transports: ['websocket'],
          auth: {
            token: app.config.globalProperties.$auth.accessToken,
            projectId,
          },
        })

        ioConnection.on('connect', () => {
          isConnected = true
        })

        ioConnection.on('disconnect', () => {
          isConnected = false
        })

        ioConnection.on('PARTICIPANT_NEW', (payload) => {
          const { projectId, participant } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of PARTICIPANT_NEW event does not match with currently loaded project')
          }

          logPopupContextUpdate({
            app,
            socketAction: 'PARTICIPANT_NEW',
            socketPayload: {
              projectId,
              participantId: participant._id,
            },
            updatedContextId: participant._id,
          })
          // store.commit('project/ADD_PARTICIPANT', new Participant(participant))
        })

        ioConnection.on('PARTICIPANT_UPDATE', (payload) => {
          const { projectId, participant } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of PARTICIPANT_UPDATE event does not match with currently loaded project')
          }

          logPopupContextUpdate({
            app,
            socketAction: 'PARTICIPANT_UPDATE',
            socketPayload: {
              projectId,
              participantId: participant._id,
            },
            updatedContextId: participant._id,
          })
          // const firstPopupWithContext = store.state.layout.openPopups.find(popup => popup.contextIds.includes(participant._id))

          // if (firstPopupWithContext && !confirm('Ausgewählter Beteiligte wurde verändert. Beteiligte wird aktualisiert.')) {
          //   return
          // }

          // store.commit('project/UPDATE_PARTICIPANT', new Participant(participant))
        })

        ioConnection.on('PROJECT_UPDATE', (payload) => {
          const { project } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (project._id !== store.state.project.project._id) {
            throw new Error('ProjectId of PROJECT_UPDATE event does not match with currently loaded project')
          }

          logPopupContextUpdate({
            app,
            socketAction: 'PROJECT_UPDATE',
            socketPayload: {
              projectId: project._id,
            },
            updatedContextId: project._id,
          })
          // store.commit('project/SET_PROJECT', new Project(project))
        })

        ioConnection.on('TASK_NEW', (payload) => {
          const { projectId, task } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of TASK_NEW event does not match with currently loaded project')
          }

          logPopupContextUpdate({
            app,
            socketAction: 'TASK_NEW',
            socketPayload: {
              projectId,
              taskId: task._id,
            },
            updatedContextId: task._id,
          })
          // store.commit('tasks/ADD_TASK', new Task(task))
        })

        ioConnection.on('TASK_UPDATE', (payload) => {
          const { projectId, task } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of TASK_UPDATE event does not match with currently loaded project')
          }

          logPopupContextUpdate({
            app,
            socketAction: 'TASK_UPDATE',
            socketPayload: {
              projectId,
              taskId: task._id,
            },
            updatedContextId: task._id,
          })
          // const firstPopupWithContext = store.state.layout.openPopups.find(popup => popup.contextIds.includes(task._id))

          // if (firstPopupWithContext && !confirm('Ausgewählter Task wurde verändert. Task wird aktualisiert.')) {
          //   return
          // }

          // store.commit('tasks/ADD_TASK', new Task(task))
        })

        ioConnection.on('TASK_FILE_THUMBNAIL_UPDATE', (payload) => {
          const { projectId, taskId, task } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of TASK_FILE_THUMBNAIL_UPDATE event does not match with currently loaded project')
          }

          const originalTask = store.getters['tasks/getById'](taskId)

          if (!originalTask) {
            console.error(`Original task with _id "${taskId}" couldn't be found`)
            return
          }

          store.commit('tasks/REPLACE_TASK', new Task(task))
        })

        ioConnection.on('TASK_COMMENT_FILE_THUMBNAIL_UPDATE', (payload) => {
          const { projectId, taskId, task } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of TASK_COMMENT_FILE_THUMBNAIL_UPDATE event does not match with currently loaded project')
          }

          const originalTask = store.getters['tasks/getById'](taskId)

          if (!originalTask) {
            console.error(`Original task with _id "${taskId}" couldn't be found`)
            return
          }

          store.commit('tasks/REPLACE_TASK', new Task(task))
        })

        ioConnection.on('TASK_COMMENT_NEW', (payload) => {
          const { projectId, task } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of TASK_COMMENT_NEW event does not match with currently loaded project')
          }

          store.commit('tasks/REPLACE_TASK', new Task(task))
        })

        ioConnection.on('TASK_COMMENT_UPDATE', (payload) => {
          const { projectId, task } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of TASK_COMMENT_UPDATE event does not match with currently loaded project')
          }

          logPopupContextUpdate({
            app,
            socketAction: 'TASK_COMMENT_UPDATE',
            socketPayload: {
              projectId,
              taskId: task._id,
            },
            updatedContextId: task._id,
          })
          // store.commit('tasks/REPLACE_TASK', new Task(task))
        })

        ioConnection.on('TASK_REMOVE_VIEW_PERMISSION', (payload) => {
          const { projectId, taskId } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of TASK_REMOVE_VIEW_PERMISSION event does not match with currently loaded project')
          }

          logPopupContextUpdate({
            app,
            socketAction: 'TASK_REMOVE_VIEW_PERMISSION',
            socketPayload: {
              projectId,
              taskId,
            },
            updatedContextId: taskId,
          })

          store.commit('tasks/REMOVE_TASK', taskId)

          if (app.config.globalProperties.$route.name === 'projects-projectId-tasks-taskId' && app.config.globalProperties.$route.params.taskId === taskId) {
            alert(`${$t('feature.task.detail.task_not_available')} - ${$t('feature.task.detail.maybe_no_permission_to_view_task')}`)

            app.config.globalProperties.$router.push({
              name: 'projects-projectId-tasks',
              params: {
                projectId: store.state.project.project._id,
              },
            })
          }
        })

        ioConnection.on('PLAN_NEW', (payload) => {
          const { projectId, plan } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of PLAN_NEW event does not match with currently loaded project')
          }

          logPopupContextUpdate({
            app,
            socketAction: 'PLAN_NEW',
            socketPayload: {
              projectId,
              plan: plan._id,
            },
            updatedContextId: plan._id,
          })
          // store.commit('plans/ADD_PLAN', new Plan(plan))
        })

        ioConnection.on('PLAN_UPDATE', (payload) => {
          const { projectId, plan } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of PLAN_UPDATE event does not match with currently loaded project')
          }

          logPopupContextUpdate({
            app,
            socketAction: 'PLAN_UPDATE',
            socketPayload: {
              projectId,
              plan: plan._id,
            },
            updatedContextId: plan._id,
          })
          // const firstPopupWithContext = store.state.layout.openPopups.find(popup => popup.contextIds.includes(plan._id))

          // if (firstPopupWithContext && !confirm('Ausgewählter Plan wurde verändert. Plan wird aktualisiert.')) {
          //   return
          // }

          // store.commit('plans/ADD_PLAN', new Plan(plan))
        })

        ioConnection.on('PLAN_REVISION_NEW', (payload) => {
          const { projectId, plan } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of PLAN_REVISION_NEW event does not match with currently loaded project')
          }

          logPopupContextUpdate({
            app,
            socketAction: 'PLAN_REVISION_NEW',
            socketPayload: {
              projectId,
              plan: plan._id,
            },
            updatedContextId: plan._id,
          })
          // store.commit('plans/REPLACE_PLAN', new Plan(plan))
        })

        ioConnection.on('DOCUMENT_NEW', (payload) => {
          const { projectId, document } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of DOCUMENT_NEW event does not match with currently loaded project')
          }

          logPopupContextUpdate({
            app,
            socketAction: 'DOCUMENT_NEW',
            socketPayload: {
              projectId,
              document: document._id,
            },
            updatedContextId: document._id,
          })
          // store.commit('documents/ADD_DOCUMENT', new Document(document))
        })

        ioConnection.on('DOCUMENT_UPDATE', (payload) => {
          const { projectId, document } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of DOCUMENT_UPDATE event does not match with currently loaded project')
          }

          logPopupContextUpdate({
            app,
            socketAction: 'DOCUMENT_UPDATE',
            socketPayload: {
              projectId,
              document: document._id,
            },
            updatedContextId: document._id,
          })
          // const firstPopupWithContext = store.state.layout.openPopups.find(popup => popup.contextIds.includes(document._id))

          // if (firstPopupWithContext && !confirm('Ausgewähltes Dokument wurde verändert. Dokument wird aktualisiert.')) {
          //   return
          // }

          // store.commit('documents/ADD_DOCUMENT', new Document(document))
        })

        ioConnection.on('DOCUMENT_REVISION_NEW', (payload) => {
          const { projectId, document } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of DOCUMENT_REVISION_NEW event does not match with currently loaded project')
          }

          logPopupContextUpdate({
            app,
            socketAction: 'DOCUMENT_REVISION_NEW',
            socketPayload: {
              projectId,
              document: document._id,
            },
            updatedContextId: document._id,
          })
          // store.commit('documents/REPLACE_DOCUMENT', new Document(document))
        })

        ioConnection.on('DOCUMENT_REMOVE_VIEW_PERMISSION', (payload) => {
          const { projectId, documentId } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of DOCUMENT_REMOVE_VIEW_PERMISSION event does not match with currently loaded project')
          }

          logPopupContextUpdate({
            app,
            socketAction: 'DOCUMENT_REMOVE_VIEW_PERMISSION',
            socketPayload: {
              projectId,
              documentId,
            },
            updatedContextId: documentId,
          })

          store.commit('documents/REMOVE_DOCUMENT', documentId)

          if (app.config.globalProperties.$route.name === 'projects-projectId-documents-documentId' && app.config.globalProperties.$route.params.documentId === documentId) {
            alert(`${$t('feature.item.document_not_available')} - ${$t('feature.item.maybe_no_permission_to_view_document')}`)

            app.config.globalProperties.$router.push({
              name: 'projects-projectId-documents',
              params: {
                projectId: store.state.project.project._id,
              },
            })
          }
        })

        ioConnection.on('TRIGGER_RELOAD_PLANS', (payload) => {
          const { projectId } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of TRIGGER_RELOAD_PLANS event does not match with currently loaded project')
          }

          logListReload({
            app,
            socketAction: 'TRIGGER_RELOAD_PLANS',
            projectId,
          })
          // store.dispatch('plans/fetch', projectId)
        })

        ioConnection.on('TRIGGER_RELOAD_DOCUMENTS', (payload) => {
          const { projectId } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of TRIGGER_RELOAD_DOCUMENTS event does not match with currently loaded project')
          }

          logListReload({
            app,
            socketAction: 'TRIGGER_RELOAD_DOCUMENTS',
            projectId,
          })
          // store.dispatch('documents/fetch', projectId)
        })

        ioConnection.on('TRIGGER_RELOAD_TASKS', (payload) => {
          const { projectId } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of TRIGGER_RELOAD_TASKS event does not match with currently loaded project')
          }

          logListReload({
            app,
            socketAction: 'TRIGGER_RELOAD_TASKS',
            projectId,
          })
          // store.dispatch('tasks/fetch', {
          //   projectId,
          // })
        })

        ioConnection.on('TRIGGER_RELOAD_PROJECT', (payload) => {
          const { projectId } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of TRIGGER_RELOAD_PROJECT event does not match with currently loaded project')
          }

          logListReload({
            app,
            socketAction: 'TRIGGER_RELOAD_PROJECT',
            projectId,
          })
          // store.dispatch('project/fetch', projectId)
        })

        ioConnection.on('TRIGGER_RELOAD_LOCATION_LAYERS', (payload) => {
          const { projectId } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of TRIGGER_RELOAD_LOCATION_LAYERS event does not match with currently loaded project')
          }

          logListReload({
            app,
            socketAction: 'TRIGGER_RELOAD_LOCATION_LAYERS',
            projectId,
          })
          store.dispatch('locationLayers/fetch', { projectId })
        })

        ioConnection.on('TRIGGER_RELOAD_FILTERS', (payload) => {
          const { projectId } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of TRIGGER_RELOAD_FILTERS event does not match with currently loaded project')
          }

          logListReload({
            app,
            socketAction: 'TRIGGER_RELOAD_FILTERS',
            projectId,
          })
          // store.dispatch('project/fetchFilters', { projectId })
        })

        ioConnection.on('LOCATION_LAYER_UPDATE', (payload) => {
          const {
            projectId,
            locationLayer,
          } = JSON.parse(payload)
          const store = app.config.globalProperties.$store

          if (projectId !== store.state.project.project._id) {
            throw new Error('ProjectId of LOCATION_LAYER_UPDATE event does not match with currently loaded project')
          }

          const originalLocationLayer = store.getters['locationLayers/getById'](locationLayer._id)

          if (!originalLocationLayer) {
            console.error(`Original locationLayer with _id "${locationLayer._id}" couldn't be found`)
            return
          }

          store.commit('locationLayers/REPLACE_LOCATION_LAYER', new LocationLayer(locationLayer))
        })
      },

      disconnect () {
        if (!isConnected) {
          return
        }

        ioConnection.disconnect()
      },

      getConnection () {
        return ioConnection
      },
    }

    app.config.globalProperties.$socket = socket
  },
}
