import { baseConfig } from '@/config/_config-base'
import demoserverConfig from '@/config/hosts/demo.planfred.com.js'

export default {
  ...demoserverConfig,
  api: {
    ...demoserverConfig.api,
    host: `https://api.edit.demo.planfred.com/${demoserverConfig.api.apiversion}`,
    companionUrl: `https://api.edit.demo.planfred.com/${demoserverConfig.api.apiversion}`, // we use CORS for uppy companion calls
    websocketServerUrl: 'https://api.edit.demo.planfred.com',
  },
  customerFeatures: {
    ...baseConfig.customerFeatures,
    hideFeatureAlerts: true,
    account: {
      ...baseConfig.customerFeatures.account,
      disablePayment: false,
      disableDelete: false,
      disableUsedStorage: false,
    },
  },
  isDemoserverEditEnvironment: true,
}
