import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M3.75 9.76643C3.75 9.20926 3.75 8.93068 3.82009 8.67295C3.88218 8.44461 3.98431 8.2291 4.12172 8.03644C4.27681 7.81899 4.49242 7.64258 4.92364 7.28976L9.97364 3.15794C10.6956 2.56728 11.0565 2.27195 11.4583 2.1589C11.8125 2.05922 12.1875 2.05922 12.5417 2.1589C12.9435 2.27195 13.3044 2.56728 14.0264 3.15795L19.0764 7.28977C19.5076 7.64258 19.7232 7.81899 19.8783 8.03644C20.0157 8.2291 20.1178 8.44461 20.1799 8.67295C20.25 8.93068 20.25 9.20926 20.25 9.76643V17.05C20.25 18.1701 20.25 18.7302 20.032 19.158C19.8403 19.5343 19.5343 19.8403 19.158 20.032C18.7302 20.25 18.1701 20.25 17.05 20.25H6.95C5.82989 20.25 5.26984 20.25 4.84202 20.032C4.46569 19.8403 4.15973 19.5343 3.96799 19.158C3.75 18.7302 3.75 18.1701 3.75 17.05V9.76643Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M7.74854 16.2814H16.2515",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }, null, -1)
  ])))
}
export default { render: render }