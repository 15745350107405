export default class Textblock {
  constructor ({
    _id, title, text, projectId, category, userId,
  }) {
    this._id = _id
    this.title = title
    this.text = text
    this.category = category
    this.projectId = projectId
    this.userId = userId
  }
}
