export default {
  LOCAL_STORAGE_PREFIX: 'vuex',
  SEARCH_INDEX_SEPARATOR: ';',
  FILE_TYPES: {
    ALL: ['print', 'cad', 'file'],
    PRINT: 'print',
    CAD: 'cad',
    FILE: 'file',
  },
  PLATFORMS: {
    WEB_APP: 'WEB_APP',
    MOBILE_APP_IOS: 'MOBILE_APP_IOS',
    MOBILE_APP_ANDROID: 'MOBILE_APP_ANDROID',
    MOBILE_APP_WEB: 'MOBILE_APP_WEB',
    SERVER: 'SERVER',
  },
  PIN_STATUS: {
    INITIAL: 'initial',
  },
  VALID_PRINT_FILE_EXTENSIONS: [
    'pdf',
  ],
  VALID_CAD_FILE_EXTENSIONS: [
    'dwg',
    'dxf',
    'dgn',
    'ifc',
    'rvt',
    'pln',
    'vwx',
    'n4d',
    'shx',
  ],
  DOCUMENT_PERMISSIONS: {
    PUBLIC: 'public',
    RESTRICTED: 'restricted',
  },
  VALID_TASK_STATES: {
    DRAFT: 'draft',
    OPEN: 'open',
    EXECUTION: 'execution',
    QUESTION: 'question',
    DONE: 'done',
  },
  VALID_TASK_DUE_DATE_PRESETS: {
    OVERDUE: 'overdue',
    NOT_SET: 'not_set',
  },
  VALID_DATE_SHORTCUTS: {
    TODAY: 'today',
    TOMORROW: 'tomorrow',
    THIS_WEEK: 'this_week',
    NEXT_WEEK: 'next_week',
  },
  VALID_ITEM_APPROVAL_DATE_PRESETS: {
    OVERDUE: 'overdue',
    NOT_SET: 'not_set',
  },
  VALID_ACTIVITY_CATEGORIES: {
    UPLOADS: 'uploads',
    DOWNLOADS: 'downloads',
    SENT: 'sent',
    ACCESS: 'access',
    LOGIN: 'login',
    RECEIVE: 'receive',
    APPROVAL: 'approval',
    TASKS: 'tasks',
    OTHER: 'other',
  },
  VALID_ACTIVITY_FILTER_DATE_RANGE_LABELS: {
    TODAY: 'today',
    DAYS_7: 'days-7',
    DAYS_30: 'days-30',
    DAYS_365: 'days-365',
    PROJECT_START: 'project-start',
    CUSTOM_DATE: 'custom-date',
  },
  UNSYNCED_RESOURCE_METHODS: {
    CREATE: 'create',
    UPDATE: 'update',
  },
  UNSYNCED_RESOURCE_UPDATE_OPERATIONS: {
    TASK_ADD_FILES: 'taskAddFiles',
    TASK_REPLACE_FILE: 'taskReplaceFile',
    TASK_COMMENT_ADD_FILES: 'taskCommentAddFiles',
    TASK_COMMENT_REPLACE_FILE: 'taskCommentReplaceFile',
  },
  ACTIVITY_KEYS: {
    PATCH_TASK_COMMENT_MESSAGE: 'patchtaskcommentmessage',
    PATCH_TASK_COMMENT_FILES: 'patchtaskcommentfiles',
    POST_PARTICIPANT: 'postparticipant',
    POST_TASK_COMMENT: 'posttaskcomment',
    PUT_PROJECT_PARTICIPANT_PERMISSION: 'putprojectparticipantpermission',
    PUT_PROJECT_PARTICIPANT_ENABLED: 'putprojectparticipantenabled',
    GET_PLAN_REVISION_PDF: 'getplanrevisionpdf',
    GET_PLAN_REVISION_DWG: 'getplanrevisiondwg',
    GET_DOCUMENT_REVISION: 'getdocumentrevision',
    SEND_REVISION_PLAN: 'sendrevisionplan',
    SEND_REVISION_DOC: 'sendrevisiondoc',
    GET_APPROVAL_COMMENT_ATTACHMENT: 'getapprovalcommentattachment',
    GET_DOCUMENT_APPROVAL_COMMENT_ATTACHMENT: 'getdocumentapprovalcommentattachment',
    PATCH_PLAN_TRASH: 'patchplantrash',
    PATCH_DOCUMENT_TRASH: 'patchdocumenttrash',
    PATCH_PLAN_REVISION_APPROVAL_DATE: 'patchplanrevisionapprovaldate',
    PATCH_DOCUMENT_REVISION_APPROVAL_DATE: 'patchdocumentrevisionapprovaldate',
  },
  RESOURCE_NAMES: {
    TASK: 'task',
    TASK_COMMENT: 'task-comment',
  },
  FILE_RESOLUTIONS: {
    THUMB: 'thumb',
    LARGE: 'large',
  },
  FILTER_KEYS: {
    TASKS_LIST_WEB_DRAFTS: 'tasksListWebDrafts',
    TASKS_LIST_WEB_MAIN: 'tasksListWebMain',
    TASKS_LIST_WEB_DONE: 'tasksListWebDone',
    TASKS_LIST_MOBILE_DRAFTS: 'tasksListMobileDrafts',
    TASKS_LIST_MOBILE_MAIN: 'tasksListMobileMain',
    TASKS_LIST_MOBILE_DONE: 'tasksListMobileDone',
    PLANS_LIST_WEB_MAIN: 'plansListWebMain',
    PLANS_LIST_MOBILE_MAIN: 'plansListMobileMain',
    DOCUMENTS_LIST_WEB_MAIN: 'documentsListWebMain',
    DOCUMENTS_LIST_MOBILE_MAIN: 'documentsListMobileMain',
    PARTICIPANTS_LIST_WEB_MAIN: 'participantsListWebMain',
    PARTICIPANTS_LIST_MOBILE_MAIN: 'participantsListMobileMain',
    ACTIVITIES_LIST_WEB: 'activitiesListWeb',
    ACTIVITIES_LIST_MOBILE: 'activitiesListMobile',
  },
  FILTER_PROPERTIES: {
    PROJECTS_OWNERSHIP_ALL: 'projects-all',
    PROJECTS_OWNERSHIP_PARTICIPANT: 'projects-participant',
    PROJECTS_OWNERSHIP_OWNER: 'projects-owner',
  },
  REVISION_LOG_CATEGORIES: {
    TRASH: 'trash',
    EXPORT: 'exported',
    IMPORT: 'imported',
  },
  PARTICIPANT_PERMISSIONS: {
    SILENT: 'silent',
    PARTICIPANT: 'participant',
    ADMIN: 'admin',
    OWNER: 'owner',
  },
  SORT_PROPERTIES: {
    ITEM_NAME: 'item-name',
    ITEM_INDEX: 'item-index',
    ITEM_CONTENT: 'item-content',
    ITEM_PHASETAG: 'item-phasetag',
    ITEM_TAGS: 'item-tags',
    ITEM_FILES_PLAN_PRINT: 'item-print-file',
    ITEM_FILES_PLAN_CAD: 'item-cad-file',
    ITEM_FILE_DOCUMENT: 'item-document-file',
    ITEM_AUTHOR: 'item-author',
    ITEM_APPROVERS: 'item-approvers',
    ITEM_MODIFIED_DATE: 'item-last-upload',
    ITEM_LAST_APPROVAL_DATE: 'item-last-approval-date',
    ITEM_SELECTED: 'item-selected',
    ITEM_TOP_CATEGORY: 'item-top-category',
    ITEM_APPROVAL_DATE: 'item-approval-date',
    TASK_TITLE: 'task-title',
    TASK_TYPE: 'task-type',
    TASK_CREATED: 'task-created',
    TASK_PUBLISHED: 'task-published',
    TASK_DUE_DATE: 'task-due-date',
    TASK_NEWEST: 'task-newest',
    TASK_MODIFIED: 'task-modified',
    TASK_STATE: 'task-state',
    TASK_ASSIGNEE: 'task-assignee',
    TASK_CREATOR: 'task-creator',
    TASK_LOCATION: 'task-location',
    TASK_CONSECUTIVE_NUMBER: 'task-consecutive-number',
    TASK_FILES: 'task-files',
    TASK_SELECTED: 'task-selected',
    PARTICIPANT_EMAIL: 'participant-email',
    PARTICIPANT_NAME: 'participant-name',
    PARTICIPANT_ROLE: 'participant-role',
    PARTICIPANT_PHONE: 'participant-phone',
    PARTICIPANT_COMPANY: 'participant-company',
    PARTICIPANT_PERMISSION: 'permission-status',
    PARTICIPANT_CAN_APPROVE_REVISIONS: 'permission-approval',
    PARTICIPANT_UPLOAD_ALERT: 'permission-upload-alert',
    PARTICIPANT_SELECTED: 'participant-selected',
    CLIPBOARD_FILE_NAME: 'clipboard-file-name',
    CLIPBOARD_FILE_TYPE: 'clipboard-file-type',
    CLIPBOARD_FILE_STATUS: 'clipboard-file-status',
    PROJECT_TITLE_ASC: 'project-title-asc',
    PROJECT_TITLE_DESC: 'project-title-desc',
    PROJECT_LASTREVISIONUPLOAD: 'project-lastrevisionupload',
    PROJECT_CREATED_AT: 'project-created-at',
  },

  FUNCTIONAL_CSS_CLASS: {
    DRAG_HANDLE: 'js-drag-handle',
    DRAG_HANDLE_DISABLED: 'js-drag-handle-disabled',
    MAP_PINS_JIGGLE_ANIMATION: 'js-pin-jiggle-animation',
  },

  LOCALES: {
    DE: 'de',
    EN: 'en',
    EN_GB: 'en-gb',
    EN_US: 'en-us',
  },

  ITEM_TYPES: {
    PLANS: 'plans',
    DOCUMENTS: 'documents',
    TASKS: 'tasks',
    ALL: ['plans', 'documents', 'tasks'],
  },

  APPROVAL_STATUS: {
    APPROVED: 'approved',
    APPROVED_VIA_IMPORT: 'approvedViaImport',
    REJECTED: 'rejected',
    PENDING: 'pending',
    NOTREQUESTED: 'notrequested',
  },

  APPROVER_STATUS: {
    APPROVED: 'approved',
    REJECTED: 'rejected',
    PENDING: 'pending',
    OBSOLETE: 'obsolete',
    CANCELLED: 'cancelled',
  },

  MAP_STATES: {
    VIEW: 'VIEW',
    ADD_PINS: 'ADD_PINS',
    EDIT_PINS: 'EDIT_PINS',
    NEW_PIN_SELECTED: 'NEW_PIN_SELECTED',
    EXISTING_PIN_SELECTED: 'EXISTING_PIN_SELECTED',
  },

  TASK_VIEW: {
    MAP: 'map',
  },

  BREAKPOINTS: {
    MOBILE: 768,
  },
}
