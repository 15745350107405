import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M4.75 9.75V9.5C4.75 5.77208 7.99594 2.75 12 2.75C16.0041 2.75 19.25 5.77208 19.25 9.5V9.75M12 19.6429V20.25C12 20.8023 12.4477 21.25 13 21.25H15C17.4853 21.25 19.5 19.2353 19.5 16.75M4.25 9.75H5.75V16.25H4.25C3.42157 16.25 2.75 15.5784 2.75 14.75V11.25C2.75 10.4216 3.42157 9.75 4.25 9.75ZM18.25 9.75H19.75C20.5784 9.75 21.25 10.4216 21.25 11.25V14.75C21.25 15.5784 20.5784 16.25 19.75 16.25H18.25V9.75Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }