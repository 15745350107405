import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M16.25 4.75H18.25C19.3546 4.75 20.25 5.64543 20.25 6.75V19.25C20.25 20.3546 19.3546 21.25 18.25 21.25H5.75C4.64543 21.25 3.75 20.3546 3.75 19.25V6.75C3.75 5.64543 4.64543 4.75 5.75 4.75H7.75M16.25 4.75V6.25C16.25 6.80228 15.8023 7.25 15.25 7.25H8.75C8.19772 7.25 7.75 6.80228 7.75 6.25V4.75M16.25 4.75C16.25 3.64543 15.3546 2.75 14.25 2.75H9.75C8.64543 2.75 7.75 3.64543 7.75 4.75",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "square",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }