import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M9.90689 20.032C8.90689 20.132 8.1069 19.432 8.0069 18.632C7.9069 17.632 8.9069 16.432 10.2069 16.332C11.3069 16.232 12.0069 16.832 12.1069 17.732C12.2069 18.832 11.0069 19.932 9.90689 20.032ZM16.8069 4.532C15.3069 6.832 12.8069 11.532 12.3069 13.832C12.3069 14.632 12.1069 15.032 10.8069 15.132C10.1069 15.232 9.30689 15.132 9.30689 14.432C9.20689 13.232 11.5069 7.732 12.7069 5.332C12.5069 3.732 13.3069 3.132 14.5069 3.032C15.2069 2.932 16.9069 3.032 16.9069 3.832C17.0069 4.032 17.0069 4.332 16.8069 4.532Z",
      stroke: "#FF0000",
      "stroke-width": "1.5"
    }, null, -1)
  ])))
}
export default { render: render }