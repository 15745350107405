export const putFilter = async ({ axios, projectId, filterKey, filterBody }) => {
  const { data } = await axios.put('filters', filterBody, {
    params: {
      projectid: projectId,
      filterkey: filterKey,
    },
  })

  return data
}

export const getFilters = async ({ axios, projectId, filterKeys }) => {
  const { data } = await axios.get('filters', {
    params: {
      projectid: projectId,
      filterkeys: filterKeys.join(','),
    },
  })

  return data
}
