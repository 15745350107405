<template>
  <PromotionElement
    v-if="!hidePromotionElement"
    :headline="currentPromotionElement.headline"
    :description="currentPromotionElement.description"
    :href="currentPromotionElement.href"
    :crm-property="currentPromotionElement.crmProperty"
  />
</template>

<script>
import PromotionElement from '@/components/PromotionElement.vue'

export default {
  name: 'PromotionElementSection',

  components: {
    PromotionElement,
  },

  computed: {
    hidePromotionElement () {
      return this.$auth.user.crm && this.$auth.user.crm[this.currentPromotionElement.crmProperty] === true
    },

    currentPromotionElement () {
      const promotionElements = [
        {
          headline: this.$t('layout.header.promotion_element.task_location.title'),
          description: this.$t('layout.header.promotion_element.task_location.description'),
          href: this.$t('data.blog.task_location'),
          crmProperty: this.$config.constants.CRM_FEATURE_FLAGS.HIDE_TASK_LOCALIZATION_ELEMENT,
        },
      ]

      return promotionElements[0]
    },
  },
}
</script>
