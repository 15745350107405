import LocationPin from './location-pin.model'
import TaskComment from './task-comment.model'
import TaskFileLocal from './task-file-local.model'
import TaskFile from './task-file.model'

export default class Task {
  constructor ({ _id, projectId, title, description, files, created, modified, published, lastStateChange, trashed, dueDate, consecutiveNumber, type, tags, creatorUserId, assigneeUserId, location1, location2, location3, location4, locationLayerId, latestPinNumber, locationPins, state, comments }) {
    this._id = _id
    this.creatorUserId = creatorUserId
    this.assigneeUserId = assigneeUserId
    this.projectId = projectId
    this.title = title
    this.description = description
    this.files = files.map(file => {
      if (file.imgSrc || file.uri) {
        return new TaskFileLocal(file)
      }
      return new TaskFile(file)
    })
    this.created = created
    this.modified = modified
    this.published = published
    this.lastStateChange = lastStateChange
    this.trashed = trashed
    this.dueDate = dueDate
    this.consecutiveNumber = consecutiveNumber
    this.type = type
    this.tags = tags || []
    this.location1 = location1
    this.location2 = location2
    this.location3 = location3
    this.location4 = location4
    this.locationLayerId = locationLayerId
    this.latestPinNumber = latestPinNumber
    this.locationPins = locationPins ? locationPins.map(pin => new LocationPin(pin)) : []
    this.state = state
    this.comments = comments ? comments.map(comment => new TaskComment(comment)) : []

    this.computed = {
      isTask: true,
    }
  }
}
