import constants from '@/config/constants.js'
const validModi = [
  constants.LIST_MODUS.LIST,
  constants.LIST_MODUS.PRINT,
  constants.LIST_MODUS.MULTI_ACTION_EDIT,
]

const validSubTabs = [
  {
    name: constants.TASK_LIST_SUB_TABS.DRAFTS,
    filterStoreName: constants.FILTER_STORE_NAMES.TASKS_FILTER_DRAFTS,
  },
  {
    name: constants.TASK_LIST_SUB_TABS.MAIN,
    filterStoreName: constants.FILTER_STORE_NAMES.TASKS_FILTER_MAIN,
    isDefault: true,
  },
  {
    name: constants.TASK_LIST_SUB_TABS.DONE,
    filterStoreName: constants.FILTER_STORE_NAMES.TASKS_FILTER_DONE,
  },
  {
    name: constants.TASK_LIST_SUB_TABS.TRASH,
    filterStoreName: constants.FILTER_STORE_NAMES.TASKS_FILTER_TRASH,
  },
]
const defaultSubTab = validSubTabs.find(subTabConfig => subTabConfig.isDefault)

export default {
  namespaced: true,

  state: () => {
    return {
      subTab: defaultSubTab.name,
      modus: constants.LIST_MODUS.LIST,
    }
  },

  getters: {
    filterStoreName: (state) => {
      const matchedModusObject = validSubTabs.find(subTabConfig => subTabConfig.name === state.subTab)

      return matchedModusObject.filterStoreName
    },
  },

  mutations: {
    SET_MODUS (state, modus) {
      const matchedModus = validModi.find(m => m === modus)

      if (!matchedModus) {
        console.error(`${modus} is not a valid tasksListModus`)
        return
      }

      state.modus = matchedModus
    },

    SET_SUB_TAB (state, newSubTab) {
      const listSubTabConfig = validSubTabs.find(subTab => subTab.name === newSubTab)

      if (!listSubTabConfig) {
        console.error(`${newSubTab} is not a valid constants.TASK_LIST_SUB_TABS value`)
        return
      }

      state.subTab = listSubTabConfig.name
    },

    FULL_RESET (state) {
      state.modus = constants.LIST_MODUS.LIST
      state.subTab = defaultSubTab.name
    },
  },
}
