import filterFunctions from '@client-shared/utils/filter-functions'

import constants from '@/config/constants'

const getPropertyValue = ({ currentValue, hasResult }) => {
  return {
    count: currentValue ? currentValue.count + 1 : 1,
    hasResult,
  }
}

const setHasResult = (object, value) => {
  for (const key of Object.keys(object)) {
    object[key].hasResult = value
  }
}

const handleTypesCount = ({ result, task }) => {
  result.types[task.type] = getPropertyValue({
    currentValue: result.types[task.type],
    hasResult: false,
  })
}

const handleStatesCount = ({ result, task }) => {
  result.states[task.state] = getPropertyValue({
    currentValue: result.states[task.state],
    hasResult: false,
  })
}

const handleLocationsCount = ({ result, task, locationProperty }) => {
  const locationValue = task[locationProperty] || null

  result[locationProperty][locationValue] = getPropertyValue({
    currentValue: result[locationProperty][locationValue],
    hasResult: false,
  })
}

const handleLocationLayerIdsCount = ({ result, task }) => {
  const locationLayerId = task.locationLayerId || null

  result.locationLayerIds[locationLayerId] = getPropertyValue({
    currentValue: result.locationLayerIds[locationLayerId],
    hasResult: false,
  })
}

const handleAssigneeCount = ({ result, task, project }) => {
  if (!task.assigneeUserId) {
    return
  }

  const userId = task.assigneeUserId

  result.assigneeUserIds[userId] = getPropertyValue({
    currentValue: result.assigneeUserIds[userId],
    hasResult: false,
  })

  const assignee = project.findParticipantByUserId(userId)

  result.assigneeRoles[assignee.roles[0]] = getPropertyValue({
    currentValue: result.assigneeRoles[assignee.roles[0]],
    hasResult: false,
  })
}

const handleCreatorCount = ({ result, task, project }) => {
  const userId = task.creatorUserId

  result.creatorUserIds[userId] = getPropertyValue({
    currentValue: result.creatorUserIds[userId],
    hasResult: false,
  })

  const creator = project.findParticipantByUserId(userId)

  result.creatorRoles[creator.roles[0]] = getPropertyValue({
    currentValue: result.creatorRoles[creator.roles[0]],
    hasResult: false,
  })
}

const handleDueDatePresetCount = ({ result, task }) => {
  for (const preset of Object.values(constants.VALID_TASK_DUE_DATE_PRESETS)) {
    const matchesPreset = filterFunctions.tasks.matchesFilterDueDatePreset({
      dueDatePreset: preset,
      task,
    })

    if (matchesPreset) {
      result.dueDatePresets[preset] = getPropertyValue({
        currentValue: result.dueDatePresets[preset],
        hasResult: false,
      })
    }
  }
}

const handleTagsCount = ({ result, task }) => {
  for (const tag of task.tags) {
    result.tags[tag] = getPropertyValue({
      currentValue: result.tags[tag],
      hasResult: false,
    })
  }
}

const handleTypesHasResults = ({ result, project, filterStore, tasksAll, isFilterActive }) => {
  if (isFilterActive) {
    const tasksFilteredExcludingTypes = tasksAll.filter(task => { return filterFunctions.tasks.matchesFilters({ task, project, ...filterStore, types: [] }) })

    for (const task of tasksFilteredExcludingTypes) {
      result.types[task.type].hasResult = true
    }
  } else {
    setHasResult(result.types, true)
  }
}

const handleStatesHasResults = ({ result, project, filterStore, tasksAll, isFilterActive }) => {
  if (isFilterActive) {
    const tasksFilteredExcludingStates = tasksAll.filter(task => { return filterFunctions.tasks.matchesFilters({ task, project, ...filterStore, states: [] }) })

    for (const task of tasksFilteredExcludingStates) {
      result.states[task.state].hasResult = true
    }
  } else {
    setHasResult(result.states, true)
  }
}

const handleLocationLayerIdsHasResults = ({ result, project, filterStore, tasksAll, isFilterActive }) => {
  if (isFilterActive) {
    const tasksFilteredExcludingLocationLayerIds = tasksAll.filter(task => { return filterFunctions.tasks.matchesFilters({ task, project, ...filterStore, locationLayerIds: [] }) })

    for (const task of tasksFilteredExcludingLocationLayerIds) {
      const locationLayerId = task.locationLayerId || null // Use "null", not "undefined", because "undefined" is not a valid JSON value and gets parsed to null

      result.locationLayerIds[locationLayerId].hasResult = true
    }
  } else {
    setHasResult(result.locationLayerIds, true)
  }
}

const handleAssigneeHasResults = ({ result, project, filterStore, tasksAll, isFilterActive }) => {
  if (isFilterActive) {
    const tasksFilteredExcludingAssignee = tasksAll.filter(task => { return filterFunctions.tasks.matchesFilters({ task, project, ...filterStore, assigneeRoles: [], assigneeUserIds: [] }) })

    for (const task of tasksFilteredExcludingAssignee) {
      if (!task.assigneeUserId) {
        continue
      }

      result.assigneeUserIds[task.assigneeUserId].hasResult = true

      const assignee = project.findParticipantByUserId(task.assigneeUserId)
      result.assigneeRoles[assignee.roles[0]].hasResult = true
    }
  } else {
    setHasResult(result.assigneeRoles, true)
    setHasResult(result.assigneeUserIds, true)
  }
}

const handleCreatorHasResults = ({ result, project, filterStore, tasksAll, isFilterActive }) => {
  if (isFilterActive) {
    const tasksFilteredExcludingCreator = tasksAll.filter(task => { return filterFunctions.tasks.matchesFilters({ task, project, ...filterStore, creatorRoles: [], creatorUserIds: [] }) })

    for (const task of tasksFilteredExcludingCreator) {
      if (!task.creatorUserId) {
        continue
      }

      result.creatorUserIds[task.creatorUserId].hasResult = true

      const creator = project.findParticipantByUserId(task.creatorUserId)
      result.creatorRoles[creator.roles[0]].hasResult = true
    }
  } else {
    setHasResult(result.creatorRoles, true)
    setHasResult(result.creatorUserIds, true)
  }
}

const handleDueDateHasResults = ({ result, project, filterStore, tasksAll, isFilterActive }) => {
  if (isFilterActive) {
    const tasksFilteredExcludingDueDate = tasksAll.filter(task => { return filterFunctions.tasks.matchesFilters({ task, project, ...filterStore, dueDateFrom: undefined, dueDateTo: undefined, dueDatePreset: undefined }) })

    for (const task of tasksFilteredExcludingDueDate) {
      for (const preset of Object.values(constants.VALID_TASK_DUE_DATE_PRESETS)) {
        const matchesPreset = filterFunctions.tasks.matchesFilterDueDatePreset({
          dueDatePreset: preset,
          task,
        })

        if (matchesPreset) {
          result.dueDatePresets[preset].hasResult = true
        }
      }
    }
  } else {
    setHasResult(result.dueDatePresets, true)
  }
}

const handleTagsHasResults = ({ result, itemType, project, filterStore, tasksAll, tasksFiltered, isFilterActive }) => {
  if (isFilterActive) {
    let tasksFilteredExcludingTags

    if (filterStore.tagsAndSwitchActive) {
      tasksFilteredExcludingTags = tasksFiltered
    } else {
      tasksFilteredExcludingTags = tasksAll.filter(task => { return filterFunctions[itemType].matchesFilters({ task, project, ...filterStore, tags: [] }) })
    }

    for (const task of tasksFilteredExcludingTags) {
      for (const tag of task.tags) {
        result.tags[tag] = result.tags[tag] || { hasResult: false, count: 0 }
        result.tags[tag].hasResult = true
      }
    }
  } else {
    setHasResult(result.tags, true)
  }
}

export const getResult = ({ tasksAll, itemType, project, tasksFiltered, filterStore }) => {
  const result = {
    types: {},
    states: {},
    dueDatePresets: {},
    assigneeRoles: {},
    assigneeUserIds: {},
    creatorRoles: {},
    creatorUserIds: {},
    tags: {},
    location1: {},
    location2: {},
    location3: {},
    location4: {},
    locationLayerIds: {},
  }

  const isFilterActive = tasksAll.length !== tasksFiltered.length

  for (const task of tasksAll) {
    handleTypesCount({ result, task })
    handleStatesCount({ result, task })
    handleAssigneeCount({ result, task, project })
    handleCreatorCount({ result, task, project })
    handleDueDatePresetCount({ result, task, project })
    handleLocationLayerIdsCount({ result, task })
    handleTagsCount({ result, task })

    if (project.locations.location1.isEnabled) {
      handleLocationsCount({ result, task, locationProperty: 'location1' })
    }
    if (project.locations.location2.isEnabled) {
      handleLocationsCount({ result, task, locationProperty: 'location2' })
    }
    if (project.locations.location3.isEnabled) {
      handleLocationsCount({ result, task, locationProperty: 'location3' })
    }
    if (project.locations.location4.isEnabled) {
      handleLocationsCount({ result, task, locationProperty: 'location4' })
    }
  }

  handleTypesHasResults({ result, project, isFilterActive, tasksAll, filterStore })
  handleStatesHasResults({ result, project, isFilterActive, tasksAll, filterStore })
  handleAssigneeHasResults({ result, project, isFilterActive, tasksAll, filterStore })
  handleCreatorHasResults({ result, project, isFilterActive, tasksAll, filterStore })
  handleDueDateHasResults({ result, project, isFilterActive, tasksAll, filterStore })
  handleTagsHasResults({ result, project, itemType, isFilterActive, tasksAll, tasksFiltered, filterStore })
  handleLocationLayerIdsHasResults({ result, project, isFilterActive, tasksAll, filterStore })

  return result
}
