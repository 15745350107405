export default {
  namespaced: true,

  state: () => {
    return {
      isEnabled: false,
      isOpen: false,

      features: {
        useAuthor: true,
        usePermission: true,
        usePhasetag: true,
        useCategory: true,
        useTags: true,
        useIndex: true,
        useIndexComment: true,
        useApproval: true,
        useApprovalRequestMessage: true,
        useApprovalDate: true,
      },
    }
  },

  mutations: {
    SET_IS_ENABLED (state, isEnabled) {
      state.isEnabled = isEnabled
    },

    TOGGLE_IS_ENABLED (state) {
      state.isEnabled = !state.isEnabled

      if (!state.isEnabled) {
        state.isOpen = false
      } else {
        state.isOpen = true
      }
    },

    TOGGLE_IS_OPEN (state) {
      state.isOpen = !state.isOpen
    },

    SET_FEATURE_AUTHOR (state, value) {
      state.features.useAuthor = value
    },

    SET_FEATURE_PERMISSION (state, value) {
      state.features.usePermission = value
    },

    SET_FEATURE_CATEGORY (state, value) {
      state.features.useCategory = value
    },

    SET_FEATURE_PHASETAG (state, value) {
      state.features.usePhasetag = value
    },

    SET_FEATURE_TAGS (state, value) {
      state.features.useTags = value
    },

    SET_FEATURE_INDEX (state, value) {
      state.features.useIndex = value
    },

    SET_FEATURE_INDEX_COMMENT (state, value) {
      state.features.useIndexComment = value
    },

    SET_FEATURE_APPROVAL (state, value) {
      state.features.useApproval = value
    },

    SET_FEATURE_APPROVAL_MESSAGE (state, value) {
      state.features.useApprovalRequestMessage = value
    },

    SET_FEATURE_APPROVAL_DATE (state, value) {
      state.features.useApprovalDate = value
    },
  },
}
