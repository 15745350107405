import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M11.4367 13.0753C8.11861 13.1328 5.60502 15.1298 4.56685 17.9369C4.15805 19.0422 5.10269 20.074 6.28121 20.074H12.0843M11.4367 13.0753C11.4857 13.0744 11.5349 13.074 11.5843 13.074C11.9364 13.074 12.2797 13.0957 12.6133 13.1378M11.4367 13.0753C10.7866 13.0866 10.1678 13.1723 9.58445 13.324M12.6133 13.1378C12.947 13.1799 13.271 13.2424 13.5847 13.324M12.6133 13.1378C13.3153 13.2264 13.9747 13.4052 14.5843 13.662M15.3343 6.32404C15.3343 8.39511 13.6554 10.074 11.5843 10.074C9.5133 10.074 7.83437 8.39511 7.83437 6.32404C7.83437 4.25297 9.5133 2.57404 11.5843 2.57404C13.6554 2.57404 15.3343 4.25297 15.3343 6.32404Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M20.6655 16.4261L20.0176 16.8002M15.0363 19.6761L15.6842 19.302M20.6654 19.6761L20.0176 19.3021M15.0362 16.4261L15.6842 16.8002M17.8509 21.3011V20.4886M17.8509 14.8011V15.6136M20.0176 18.0511C20.0176 17.6564 19.912 17.2863 19.7276 16.9676C19.3529 16.3201 18.6528 15.8844 17.8509 15.8844C17.049 15.8844 16.3488 16.3201 15.9742 16.9676C15.7898 17.2863 15.6842 17.6564 15.6842 18.0511C15.6842 18.4458 15.7898 18.8159 15.9742 19.1346C16.3489 19.7821 17.049 20.2178 17.8509 20.2178C18.6528 20.2178 19.3529 19.7821 19.7276 19.1346C19.912 18.8159 20.0176 18.4458 20.0176 18.0511Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }