import UnifiedFile from './unified-file.model'

export default class AttachmentFile {
  constructor ({ _id, key, filesize, filename } = { }) {
    if (!_id) {
      throw new Error('_id in UnifiedFile must be defined')
    }

    this._id = _id
    this.key = key
    this.filesize = filesize
    this.filename = filename
  }

  convertToUnifiedFile () {
    return new UnifiedFile({
      _id: this._id,
      s3Key: this.key,
      size: this.filesize,
      fileName: this.filename,
    })
  }
}
