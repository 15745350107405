import { nextTick } from 'vue'

export default {
  install: (app) => {
    const scrollToError = () => {
      nextTick(() => {
        const validationError = document.querySelector('.js-validation-error')

        if (validationError) {
          validationError.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          })
        }
      })
    }

    app.config.globalProperties.$scrollToError = scrollToError
  },
}
