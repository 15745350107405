import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M7.75 4.75V2.75M16.25 4.75V2.75M17 14.75V16.9996L18.75 18.75M8.75 20.25H5.75C4.64543 20.25 3.75 19.3546 3.75 18.25V6.75C3.75 5.64543 4.64543 4.75 5.75 4.75H18.25C19.3546 4.75 20.25 5.64543 20.25 6.75V8.75M14.9917 12.1509C17.6701 11.0415 20.7396 12.3137 21.8491 14.9919C22.9586 17.6702 21.6863 20.7396 19.0079 21.8491C16.3296 22.9585 13.2601 21.6863 12.1506 19.0081C11.0419 16.3306 12.3133 13.2604 14.9917 12.1509Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }