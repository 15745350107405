import getIsApprovalForRevisionRequested from './plan-doc-shared/get-is-approval-for-revision-requested'
import getLatestApprovedRevision from './plan-doc-shared/get-latest-approved-revision'
import getLatestApproverActionDate from './plan-doc-shared/get-latest-approver-action-date'
import updateApproverStatusOfRevisions from './plan-doc-shared/update-approver-status-of-revisions'

export default class Document {
  constructor (data) {
    if (data instanceof this.constructor) {
      // it's already a Document instance, so copy the data of the instance and create a new one
      data = JSON.parse(JSON.stringify(data))
    }

    const { _id, name, content, projectid, revisions, created, author, modified, phasetag, tags, trashed, permission, topCategory, permissionDownloadLinks } = data

    this._id = _id
    this.name = name
    this.content = content
    this.projectid = projectid
    this.revisions = updateApproverStatusOfRevisions(revisions) // rewrite approval status of approvers, as the approver status "obsolete" is not set by the server and has to be overridden manually
    this.created = created
    this.author = author
    this.modified = modified
    this.phasetag = phasetag
    this.tags = tags
    this.trashed = trashed
    this.permission = permission
    this.topCategory = topCategory || ''
    this.permissionDownloadLinks = permissionDownloadLinks

    const latestRevision = this.revisions[this.revisions.length - 1]
    const latestApprovedRevision = getLatestApprovedRevision(this)
    const isApprovalForRevisionRequested = getIsApprovalForRevisionRequested(latestRevision)
    const latestApproverActionDate = getLatestApproverActionDate(latestRevision)

    this.computed = {
      latestRevision,
      latestApprovedRevision,
      latestApproverActionDate,
      isApprovalForRevisionRequested,
      isPlan: false,
      isDocument: true,
    }
  }
}
