import constants from '@client-shared/config/constants'
import dayjs from 'dayjs'
const { VALID_ACTIVITY_FILTER_DATE_RANGE_LABELS: RANGES } = constants

const dateRanges = [
  {
    label: RANGES.TODAY,
    get: () => {
      return {
        start: dayjs().startOf('day').toDate(),
        end: dayjs().endOf('day').toDate(),
      }
    },
  },
  {
    label: RANGES.DAYS_7,
    get: () => {
      return {
        start: dayjs().subtract(7, 'days').startOf('day').toDate(),
        end: dayjs().endOf('day').toDate(),
      }
    },
  },
  {
    label: RANGES.DAYS_30,
    get: () => {
      return {
        start: dayjs().subtract(30, 'days').startOf('day').toDate(),
        end: dayjs().endOf('day').toDate(),
      }
    },
  },
  {
    label: RANGES.DAYS_365,
    get: () => {
      return {
        start: dayjs().subtract(365, 'days').startOf('day').toDate(),
        end: dayjs().endOf('day').toDate(),
      }
    },
  },
  {
    label: RANGES.PROJECT_START,
    get: (project) => {
      return {
        start: dayjs(project.created).startOf('day').toDate(),
        end: dayjs().endOf('day').toDate(),
      }
    },
  },
]

export default dateRanges
