import ParticipantVirtual from './participant-virtual.model.js'
import Participant from './participant.model.js'

// getUserId is a safety wrapper for old, inconsistent client APP usage
const getUserId = userId => {
  if (userId && userId._id) {
    return userId._id
  }
  return userId
}

// getUserIdOfParticipant is a safety wrapper for old, inconsistent API which sometimes returned projects with not populated participants.user objects
const getUserIdOfParticipant = participant => {
  if (participant.user && participant.user._id) {
    return participant.user._id
  }
  return participant.user
}

export default class Project {
  constructor ({ _id, title, created, lastrevisionupload, lastRevisionUploadPlans, lastRevisionUploadDocuments, features, participantProjectAccessLogging, trashed, trashedDate, modified, timezone, usedStorage, backupDataSize, language, roles, phasetags, phasetagsDocuments, uploadAlert, tags, taskTypes, locations, participants, computed, topCategoriesPlansEnabled, topCategoriesPlans, topCategoriesDocumentsEnabled, topCategoriesDocuments }) {
    this._id = _id
    this.title = title
    this.created = created
    this.modified = modified
    this.usedStorage = usedStorage
    this.backupDataSize = backupDataSize
    this.language = language
    this.roles = roles
    this.phasetags = phasetags
    this.phasetagsDocuments = phasetagsDocuments
    this.topCategoriesPlansEnabled = topCategoriesPlansEnabled || false
    this.topCategoriesPlans = topCategoriesPlans || []
    this.topCategoriesDocumentsEnabled = topCategoriesDocumentsEnabled || false
    this.topCategoriesDocuments = topCategoriesDocuments || []
    this.uploadAlert = uploadAlert
    this.tags = tags
    this.taskTypes = taskTypes || []
    this.locations = locations
    this.participants = (participants || []).map(p => {
      // When there is no _id it's not a real participant. This can happen in the projects/new form for example
      if (!p._id) {
        return p
      }

      return Object.freeze(new Participant(p))
    })
    this.computed = computed || {}
    this.timezone = timezone
    this.lastrevisionupload = lastrevisionupload
    this.lastRevisionUploadPlans = lastRevisionUploadPlans
    this.lastRevisionUploadDocuments = lastRevisionUploadDocuments
    this.trashed = trashed
    this.trashedDate = trashedDate
    this.features = features
    this.participantProjectAccessLogging = participantProjectAccessLogging

    this.computed = {
      ...this.computed,

      participantsVirtual: this.roles.map(r => Object.freeze(new ParticipantVirtual({ roles: [r] }))),
      ownerParticipant: this.participants ? this.participants.find(p => p.permission === 'owner') : undefined,
      isLocationPropertiesEnabled: this.locations.location1.isEnabled || this.locations.location2.isEnabled || this.locations.location3.isEnabled || this.locations.location4.isEnabled,
    }
  }

  findParticipantByUserId (userId) {
    return this.participants.find(participant => getUserIdOfParticipant(participant) === getUserId(userId))
  }

  findParticipantById (participantId) {
    return this.participants.find(participant => participant._id === participantId)
  }

  findParticipantByEmail (email) {
    return this.participants.find(participant => participant.user.email.toLowerCase() === email.toLowerCase())
  }

  findParticipantVirtualByRole (role) {
    const participantVirtual = this.computed.participantsVirtual.find(participant => participant.roles[0] === role)

    if (!participantVirtual) {
      return new ParticipantVirtual({ roles: [role] })
    }

    return participantVirtual
  }

  findParticipantByPlanDocAuthor (itemAuthor) {
    if (itemAuthor.userId) {
      return this.findParticipantByUserId(itemAuthor.userId)
    }

    return this.findParticipantVirtualByRole(itemAuthor.role)
  }
}
