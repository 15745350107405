export default class LocationLayer {
  constructor ({ _id, projectId, order, title, createdByUserId, tilesS3Key, tilesDownloadUrl, isActive, isUsed, sourceFile, zoomLevels, locationRestrictions, created, modified, tileProcessing }) {
    this._id = _id
    this.projectId = projectId
    this.order = order
    this.title = title
    this.createdByUserId = createdByUserId
    this.tilesS3Key = tilesS3Key
    this.tilesDownloadUrl = tilesDownloadUrl
    this.isActive = isActive
    this.isUsed = isUsed
    this.created = created
    this.modified = modified
    this.tileProcessing = {
      status: tileProcessing.status,
      startedAt: tileProcessing.startedAt,
      finishedAt: tileProcessing.finishedAt,
      originalDpi: tileProcessing.originalDpi,
      originalWidth: tileProcessing.originalWidth,
      originalHeight: tileProcessing.originalHeight,
      scaledWidth: tileProcessing.scaledWidth,
      scaledHeight: tileProcessing.scaledHeight,
      tilePackageFileSize: tileProcessing.tilePackageFileSize,
    }
    this.zoomLevels = zoomLevels
    this.sourceFile = {
      s3Key: sourceFile.s3Key,
      name: sourceFile.name,
      size: sourceFile.size,
      mimeType: sourceFile.mimeType,
    }
    this.locationRestrictions = {
      location1: locationRestrictions.location1,
      location2: locationRestrictions.location2,
      location3: locationRestrictions.location3,
      location4: locationRestrictions.location4,
    }
  }
}
