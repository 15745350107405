<template>
  <span
    data-testid="pf-checkbox"
    role="checkbox"
    :aria-checked="checked"
    tabindex="0"
    class="obj_checkbox"
    @keypress.space.prevent="$emit('click')"
  />
</template>

<script>
export default {
  name: 'PfCheckbox',

  props: {
    checked: {
      type: Boolean,
      required: true,
    },
  },

  emits: [
    'click',
  ],
}
</script>
