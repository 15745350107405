import LocationLayer from '../models/location-layer.model.js'

export const getLocationLayers = async ({ axios, projectId }) => {
  const { data } = await axios.get('location-layers', {
    params: {
      projectid: projectId,
    },
  })

  return data.map(locationLayer => Object.freeze(new LocationLayer(locationLayer)))
}

export const getLocationLayerTileList = async ({ axios, maxZoomLevel }) => {
  const params = {}

  if (maxZoomLevel) {
    params.maxZoomLevel = maxZoomLevel
  }

  const { data } = await axios.get('location-layers/tile-list', {
    params,
  })

  return data
}

export const postLocationLayer = async ({ axios, locationLayer }) => {
  const { data } = await axios.post('location-layers', locationLayer, {
    params: {
      projectid: locationLayer.projectId,
    },
  })

  return new LocationLayer(data)
}

export const patchLocationLayerTitle = async ({ axios, locationLayer, title }) => {
  const patchObject = {
    op: 'update',
    path: 'title',
    value: {
      old: locationLayer.title,
      new: title,
    },
  }

  const { data } = await axios.patch(`location-layers/${locationLayer._id}`, patchObject)

  return new LocationLayer(data)
}

export const patchLocationLayerIsActive = async ({ axios, locationLayer, isActive }) => {
  const patchObject = {
    op: 'update',
    path: 'isActive',
    value: {
      old: locationLayer.isActive,
      new: isActive,
    },
  }

  const { data } = await axios.patch(`location-layers/${locationLayer._id}`, patchObject)

  return new LocationLayer(data)
}

export const patchLocationLayerLocationRestrictions = async ({ axios, locationLayer, locationRestrictions }) => {
  const patchObject = {
    op: 'update',
    path: 'locationRestrictions',
    value: {
      old: locationLayer.locationRestrictions,
      new: locationRestrictions,
    },
  }

  const { data } = await axios.patch(`location-layers/${locationLayer._id}`, patchObject)

  return new LocationLayer(data)
}

export const deleteLocationLayer = async ({ axios, locationLayerId }) => {
  await axios.delete(`location-layers/${locationLayerId}`)
}

export const reorderLocationLayers = async ({ axios, projectId, oldValue, newValue }) => {
  const { data } = await axios.post('location-layers/reorder', {
    projectId,
    value: {
      old: oldValue,
      new: newValue,
    },
  })

  return data.map(locationLayer => Object.freeze(new LocationLayer(locationLayer)))
}
