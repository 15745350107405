import translationsDe from '@client-shared/lang/de-DE/index.json'
import translationsEn from '@client-shared/lang/en/index.json'
import translationsEnUs from '@client-shared/lang/en-US/index.json'
import Cookies from 'universal-cookie'
import { createI18n } from 'vue-i18n'

import constants from '@/config/constants'
import sendRaygun from '@/utils/send-raygun.js'

const cookies = new Cookies()

const getNavigatorLanguage = () => {
  const lang = window.navigator.language

  if (lang === 'en-US') {
    return 'en-us'
  }

  if (lang === 'en-GB') {
    return 'en-gb'
  }

  if (lang.startsWith('en')) {
    return 'en'
  }

  if (lang.startsWith('de')) {
    return 'de'
  }
}

const getLangCookie = () => {
  const value = cookies.get(constants.LANG_COOKIE_NAME)
  const VALID_VALUES = ['en', 'de', 'en-gb', 'en-us', undefined]

  if (VALID_VALUES.includes(value)) {
    return value
  }

  sendRaygun({
    error: new Error(`INVALID_LANG_COOKIE_VALUE: ${value}`),
    tags: ['lang-cookie'],
  })
}

const i18n = createI18n({
  locale: getLangCookie() || getNavigatorLanguage() || 'en',
  fallbackLocale: 'en',
  warnHtmlInMessage: 'off',
  messages: {
    de: translationsDe,
    en: translationsEn,
    'en-gb': translationsEn,
    'en-us': translationsEnUs,
  },
})

export default i18n
