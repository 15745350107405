<template>
  <header
    class="header-container-fixed"
    :class="{
      'header-container-fixed--overlap': isPageScrolled,
    }"
  >
    <div class="header-bar">
      <div class="header-bar__logo">
        <div
          data-testid="header-logo"
          class="app-logo app-logo--mono"
        >
          <PlanfredLogoMono />
        </div>
      </div>

      <MessageBoxInfo
        data-testid="project-backup-date"
        :message="$t('feature.backup_app.createdDate', { DATE: $dayjs(backupTimestamp).locale($i18n.locale).format('ll'), TIME: $dayjs(backupTimestamp).locale($i18n.locale).format('LT') })"
      />
    </div>
  </header>
</template>

<script>
import PlanfredLogoMono from '@/assets/img/logo-planfred-mono.svg?component'
import MessageBoxInfo from '@/components/MessageBoxInfo.vue'

export default {
  name: 'HeaderBarBackupApp',

  components: {
    MessageBoxInfo,
    PlanfredLogoMono,
  },

  data () {
    return {
      scrollPosition: 0,
    }
  },

  computed: {
    isPageScrolled () {
      return this.scrollPosition > 25
    },

    backupTimestamp () {
      return window.planfredOffline.backupTimestamp
    },
  },

  mounted () {
    window.addEventListener('scroll', this.updateScroll)
  },

  methods: {
    updateScroll () {
      this.scrollPosition = window.scrollY
    },
  },

  destroy () {
    window.removeEventListener('scroll', this.updateScroll)
  },
}
</script>
