import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 414 65",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M27.01 44.21H16.04V63.1H0.369995V0H27C40.32 0 50.85 8.88 50.85 22.11C50.85 35.34 40.32 44.22 27 44.22L27.01 44.21ZM25.18 13.23H16.04V30.99H25.18C30.49 30.99 34.49 26.38 34.49 22.11C34.49 17.84 30.49 13.23 25.18 13.23Z\" fill=\"currentColor\"></path><path d=\"M81.21 63.1H53.43V0H69.1V49.87H86.21L81.21 63.1Z\" fill=\"currentColor\"></path><path d=\"M146.74 63.1H130.2L127.5 55.53H104.7L102 63.1H85.46L108.1 0H124.11L146.74 63.1ZM109.14 42.82H122.98L116.28 23.24H115.93L109.14 42.82Z\" fill=\"currentColor\"></path><path d=\"M199.88 63.1H184.21L164.1 29.42H163.93V63.1H148.26V0H163.93L184.04 33.68H184.21V0H199.88V63.1Z\" fill=\"currentColor\"></path><path d=\"M204.57 63.1V0H248.26V13.23H220.23V24.98H242.51V38.04H220.23V63.11H204.56L204.57 63.1Z\" fill=\"currentColor\"></path><path d=\"M276.11 44.21H268.45V63.1H252.78V0H279.41C292.73 0 303.26 8.88 303.26 22.11C303.26 30.9 298.56 37.78 291.6 41.35L302.65 63.11H284.72L276.1 44.22L276.11 44.21ZM277.59 13.23H268.45V30.99H277.59C282.9 30.99 286.9 26.38 286.9 22.11C286.9 17.84 282.9 13.23 277.59 13.23Z\" fill=\"currentColor\"></path><path d=\"M353.79 49.87V63.1H308.36V0H353.79V13.23H324.02V24.98H348.04V38.04H324.02V49.88H353.79V49.87Z\" fill=\"currentColor\"></path><path d=\"M381.52 63.1H358.46V0H381.52C400.32 0 413.46 13.23 413.46 31.51C413.46 49.79 400.32 63.1 381.52 63.1ZM381.52 49.87C391.09 49.87 397.1 41.86 397.1 31.51C397.1 21.16 391.09 13.23 381.52 13.23H374.12V49.87H381.52Z\" fill=\"currentColor\"></path>", 8)
  ])))
}
export default { render: render }