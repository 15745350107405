import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M16.25 9H9.75M16.25 13H9.75M10.2942 18.4836L12.3593 20.2147C12.7292 20.5248 13.2679 20.5263 13.6397 20.2183L15.738 18.4799C15.9173 18.3313 16.143 18.25 16.3759 18.25H19.25C20.3546 18.25 21.25 17.3546 21.25 16.25V5.75C21.25 4.64543 20.3546 3.75 19.25 3.75H6.75C5.64543 3.75 4.75 4.64543 4.75 5.75V16.25C4.75 17.3546 5.64543 18.25 6.75 18.25H9.65182C9.88675 18.25 10.1142 18.3327 10.2942 18.4836Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }