import { baseConfig } from '@/config/_config-base.js'

export default {
  ...baseConfig,
  api: {
    ...baseConfig.api,
    host: `https://api.app.klg-planserver.de/${baseConfig.api.apiversion}`,
    companionUrl: `https://api.app.klg-planserver.de/${baseConfig.api.apiversion}`, // we use CORS for uppy companion calls
    websocketServerUrl: 'https://api.app.klg-planserver.de',
  },
  awsS3BucketHost: 'https://klgplanserverde.s3-accelerate.amazonaws.com',
  isKlgPlanserver: true,
  headTitleTag: 'KLG Planserver',
  logoPath: new URLsrc/assets/img/customer-theme/klgplanserverde/klgplanserverde_logo.svg', import.meta.url).href,
  tasksBetaUsers: [ // If an array entry matches with the logged in users email (regex), a user can see the tasks tab
  ],
  customerFeatures: {
    ...baseConfig.customerFeatures,
    accountActivationTermsKey: 'klg_planserver', // key to specific conditions in translation files
    htmlTagClass: 'theme theme--klgplanserverde',
    isUserAllowedToCreateNewProject: email => {
      const allowedToCreateNewProject = [
        'antje.begemann@klinikum-lippe.de',
        'andreas.kampel@klinikum-lippe.de',
        'ramonajordes.heidemann@klinikum-lippe.de',
        'andreas.pistner@klinikum-lippe.de',
        'michael.nelges@klinikum-lippe.de',
        'anette.schrang@klinikum-lippe.de',
        'bianca.polikeit@klinikum-lippe.de',
        'maximilian.schmid@planfred.com',
        'klinikum-lippe@test.planfredapp.com', // user for cypress tests - do not remove!
        'approver@test.planfredapp.com', // user for localhost cypress tests - do not remove!
        'timo.stini@hofmeister.co.at', // https://3.basecamp.com/4480613/buckets/16502492/todos/6965160010
      ]

      return allowedToCreateNewProject.map(email => email.toLowerCase()).includes(email.toLowerCase())
    }, // all users are allowed to create new projects
  },
}
