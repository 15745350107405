// Use this directive if you want to prevent the click event, when the element is dragged.
// So basically use it when you want to select a text on an element without calling the click action handler

export default {
  install: (app) => {
    app.directive('enable-text-selection', {
      mounted (el, binding) {
        if (!binding.value) {
          // We passed "false" to the directive, so we don't want to enable text selection
          return
        }

        let mouseDownPosition = null
        const PREVENT_CLICK_OFFSET = 8 // indicates how much pixels the mouse must be dragged, until the click action is prevented

        const onMouseDown = (event) => {
          mouseDownPosition = {
            x: event.clientX,
            y: event.clientY,
          }
        }

        const onClick = (event) => {
          if (!mouseDownPosition) {
            event.preventDefault()
            event.stopPropagation()
            return
          }

          const aSquare = Math.pow(event.clientX - mouseDownPosition.x, 2)
          const bSquare = Math.pow(event.clientY - mouseDownPosition.y, 2)

          const offset = Math.round(
            Math.sqrt(aSquare, bSquare),
          )

          if (offset > PREVENT_CLICK_OFFSET) {
            event.preventDefault()
            event.stopPropagation()
            return
          }

          mouseDownPosition = null
        }

        el.addEventListener('mousedown', onMouseDown)
        el.addEventListener('click', onClick, true)
      },
    })
  },
}
