export default function (mimeType) {
  if (!mimeType) {
    return false
  }

  const isImage = mimeType.toLowerCase().startsWith('image')
  const isHeic = mimeType.toLowerCase() === 'image/heic'

  return isImage && !isHeic
}
