import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 25",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M14.3477 20.412L15.5601 17.912M15.5601 17.912L17.5 13.912L19.4333 17.912M15.5601 17.912H19.4333M19.4333 17.912L20.6416 20.412M14.75 3.91203H20.25L14.75 10.412H20.25M7 3.91203V20.412M7 20.412L4 17.412M7 20.412L10 17.412",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }