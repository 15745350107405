import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M9.15704 4.85557C8.81825 4.178 8.12573 3.75 7.36818 3.75H5.448C4.34343 3.75 3.448 4.64543 3.448 5.75V5.8125C3.448 13.7861 9.9119 20.25 17.8855 20.25H17.948C19.0526 20.25 19.948 19.3546 19.948 18.25V16.3298C19.948 15.5723 19.52 14.8797 18.8424 14.541L16.8448 13.5422C16.2346 13.2371 15.4976 13.3566 15.0152 13.8391C14.5668 14.2874 13.8888 14.4356 13.3231 14.1493C11.6061 13.2803 10.4177 12.0919 9.5487 10.3749C9.26235 9.8092 9.41057 9.13118 9.8589 8.68283C10.3414 8.2004 10.4609 7.4634 10.1558 6.85317L9.15704 4.85557Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }