const reload = ({ latestClientVersion, currentClientVersion }) => {
  // ONLY if the client major version is behind the latest client major version, we do a force reload
  if (window.rg4js) {
    window.rg4js('send', {
      error: new Error(`FORCE RELOAD: Current client version ${currentClientVersion} is behind latest client version ${latestClientVersion}  -> force browser reload`),
      tags: ['ApiVersionMismatchReload'],
    })
  }

  window.location.reload(true)
}

export default ({
  $axios,
  packageVersion,
}) => {
  // Interceptor to handle major version mismatches between client and server
  $axios.interceptors.response.use((response) => {
    try {
      const xAppVersionServer = response.headers['x-app-version-server']

      if (xAppVersionServer) {
        const [serverVersion, serverBuildDate] = xAppVersionServer.split(' ')

        packageVersion.server.version = serverVersion
        packageVersion.server.buildDate = serverBuildDate
      }

      const xAppVersionLatestClient = response.headers['x-app-version-client']

      if (!xAppVersionLatestClient) {
        return response
      }

      const [latestClientVersion, latestClientBuildDate] = xAppVersionLatestClient.split(' ')
      const currentClientVersion = packageVersion.client.version
      const currentClientBuildDate = packageVersion.client.buildDate
      packageVersion.latestClient.version = latestClientVersion
      packageVersion.latestClient.buildDate = latestClientBuildDate

      if (!latestClientBuildDate || !currentClientBuildDate || latestClientBuildDate === currentClientBuildDate) {
        // No action needed if build dates of client and the latest client are equal
        return response
      }

      const [latestClientMajorVersion, latestClientFeatureVersion] = latestClientVersion.split('.').map(number => parseInt(number, 10))
      const [currentClientMajorVersion, currentClientFeatureVersion] = currentClientVersion.split('.').map(number => parseInt(number, 10))

      // major version behind - update
      if (currentClientMajorVersion < latestClientMajorVersion) {
        return reload({ latestClientVersion, currentClientVersion })
      } else if (currentClientMajorVersion === latestClientMajorVersion) {
        // feature version behind - update
        if (currentClientFeatureVersion < latestClientFeatureVersion) {
          return reload({ latestClientVersion, currentClientVersion })

        // disabled patch version force reload due to improvements in cliesrc/plugins/chunk-reload.js
        // } else if (currentClientFeatureVersion === latestClientFeatureVersion) {
        //   // patch version behind - update
        //   if (currentClientPatchVersion < latestClientPatchVersion) {
        //     return reload({ latestClientVersion, currentClientVersion })
        //   }
        }
      }
    } catch (err) {
      console.error(err)
      return response
    }

    return response
  })
}
