import filterFunctions from '@client-shared/utils/filter-functions'
import {
  sortParticipantsByRole,
  sortParticipantsByEmail,
  sortParticipantsByLastName,
  sortParticipantsByCompany,
  sortParticipantsByPhone,
  sortParticipantsByPermission,
  sortParticipantsByCanApproveRevisions,
  sortParticipantsByUploadAlert,
  sortParticipantsBySelected,
} from '@client-shared/utils/sort-functions.js'

import constants from '@/config/constants.js'

export default {
  namespaced: true,

  state: () => {
    return {
      // we don't put the participants list in the store, as we don't have a dedicated endpoint for it. Instead we grab it from the project where we need it
      markedParticipant: null,
      selectedParticipantIds: [],
      selectedMultiActionProperty: constants.MULTI_ACTION.PERMISSION,

      sortSettings: {
        sortProperty: constants.SORT_PROPERTIES.PARTICIPANT_ROLE,
        sortOrderReversed: false,
      },

      printSettings: {
        groupBy: constants.PARTICIPANT_PRINT_SETTINGS.GROUP_BY_PERMISSIONS,
      },
    }
  },

  getters: {
    groupFunction (state, getters, rootState) {
      if (getters.groupBy === constants.PARTICIPANT_PRINT_SETTINGS.GROUP_BY_NONE) {
        return () => 0 // Do nothing
      }

      if (getters.groupBy === constants.PARTICIPANT_PRINT_SETTINGS.GROUP_BY_ROLES) {
        return (a, b) => {
          if (a.roles[0] === b.roles[0]) { return 0 }

          return a.roles[0] > b.roles[0] ? 1 : -1
        }
      }

      const permissionOrder = ['owner', 'admin', 'participant', 'silent']

      return (a, b) => {
        if (a.permission === b.permission) { return 0 }

        return permissionOrder.indexOf(a.permission) < permissionOrder.indexOf(b.permission) ? -1 : 1
      }
    },

    groupBy (state, getters, rootState) {
      const participantsListModus = rootState.participantsListModus.modus
      const selectedPrintGrouping = state.printSettings.groupBy

      if (participantsListModus === constants.LIST_MODUS.PRINT && selectedPrintGrouping === constants.PARTICIPANT_PRINT_SETTINGS.GROUP_BY_NONE) {
        return constants.PARTICIPANT_PRINT_SETTINGS.GROUP_BY_NONE
      }

      if (participantsListModus === constants.LIST_MODUS.PRINT && selectedPrintGrouping === constants.PARTICIPANT_PRINT_SETTINGS.GROUP_BY_ROLES) {
        return constants.PARTICIPANT_PRINT_SETTINGS.GROUP_BY_ROLES
      }

      return constants.PARTICIPANT_PRINT_SETTINGS.GROUP_BY_PERMISSIONS
    },

    sortFunction (state, getters, rootState) {
      const sortProperty = state.sortSettings.sortProperty
      let fn = sortParticipantsByRole

      if (sortProperty === constants.SORT_PROPERTIES.PARTICIPANT_EMAIL) { fn = sortParticipantsByEmail }
      if (sortProperty === constants.SORT_PROPERTIES.PARTICIPANT_NAME) { fn = sortParticipantsByLastName }
      if (sortProperty === constants.SORT_PROPERTIES.PARTICIPANT_COMPANY) { fn = sortParticipantsByCompany }
      if (sortProperty === constants.SORT_PROPERTIES.PARTICIPANT_PHONE) { fn = sortParticipantsByPhone }
      if (sortProperty === constants.SORT_PROPERTIES.PARTICIPANT_PERMISSION) { fn = sortParticipantsByPermission }
      if (sortProperty === constants.SORT_PROPERTIES.PARTICIPANT_CAN_APPROVE_REVISIONS) { fn = sortParticipantsByCanApproveRevisions }
      if (sortProperty === constants.SORT_PROPERTIES.PARTICIPANT_UPLOAD_ALERT) { fn = sortParticipantsByUploadAlert }
      if (sortProperty === constants.SORT_PROPERTIES.PARTICIPANT_SELECTED) { fn = sortParticipantsBySelected.bind({ selectedParticipantIds: state.selectedParticipantIds }) }

      if (state.sortSettings.sortOrderReversed) {
        return (a, b) => fn(a, b) * -1
      }

      return fn
    },

    disabledParticipants (state, getters, rootState) {
      return rootState.project.project.participants.filter(p => !p.enabled)
    },

    enabledParticipants (state, getters, rootState) {
      return rootState.project.project.participants.filter(p => p.enabled)
    },

    listFilteredByModus (state, getters, rootState) {
      let participants = getters.enabledParticipants

      if (rootState.participantsListModus.modus === constants.LIST_MODUS.TRASH) {
        participants = getters.disabledParticipants
      }

      return [...participants].sort((a, b) => {
        return getters.groupFunction(a, b) || getters.sortFunction(a, b)
      })
    },

    listFiltered (state, getters, rootState) {
      return getters.listFilteredByModus.filter(participant => {
        return filterFunctions.participants.matchesFilters({
          item: participant,
          searchString: rootState[rootState.participantsListModus.filterStoreName].searchString,
          roles: rootState[rootState.participantsListModus.filterStoreName].roles,
          permissions: rootState[rootState.participantsListModus.filterStoreName].permissions,
        })
      })
    },
  },

  mutations: {
    SET_MARKED_PARTICIPANT (state, participant) {
      state.markedParticipant = participant
    },

    RESET_SORTING (state) {
      state.sortSettings.sortProperty = constants.SORT_PROPERTIES.PARTICIPANT_ROLE
      state.sortSettings.sortOrderReversed = false
    },

    SET_SORT_PROPERTY (state, sortProperty) {
      state.sortSettings.sortProperty = sortProperty
    },

    SET_SORT_ORDER_REVERSED (state, sortOrderReversed) {
      state.sortSettings.sortOrderReversed = sortOrderReversed
    },

    ADD_SELECTED_PARTICIPANT_ID (state, participantId) {
      if (!state.selectedParticipantIds.includes(participantId)) {
        state.selectedParticipantIds.push(participantId)
      }
    },

    REMOVE_SELECTED_PARTICIPANT_ID (state, participantId) {
      state.selectedParticipantIds = state.selectedParticipantIds.filter(pid => pid !== participantId)
    },

    RESET_SELECTED_PARTICIPANT_IDS (state) {
      state.selectedParticipantIds = []
    },

    SET_SELECTED_MULTI_ACTION_PROPERTY (state, multiActionProperty) {
      state.selectedMultiActionProperty = multiActionProperty
    },

    SET_GROUP_BY (state, groupBy) {
      const validModi = [
        constants.PARTICIPANT_PRINT_SETTINGS.GROUP_BY_PERMISSIONS,
        constants.PARTICIPANT_PRINT_SETTINGS.GROUP_BY_ROLES,
        constants.PARTICIPANT_PRINT_SETTINGS.GROUP_BY_NONE,
      ]

      if (!validModi.includes(groupBy)) {
        console.error(`${groupBy} is not a valid grouping mode for participants list`)
        return
      }
      state.printSettings.groupBy = groupBy
    },
  },
}
