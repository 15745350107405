import { postActivitiesQuery } from '@client-shared/api/activities.api.js'

export default {
  namespaced: true,

  state () {
    return {
      list: [],
    }
  },

  mutations: {
    SET_ACTIVITIES (state, activities) {
      state.list = activities
    },

    FULL_RESET (state) {
      state.list = []
    },
  },

  actions: {
    async queryActivities ({ commit, rootState, rootGetters }, { projectId }) {
      const activities = await postActivitiesQuery({
        axios: this.$axios,
        projectId,
        query: {
          ...rootState.activitiesFilter,
          startDate: rootGetters['activitiesFilter/getDateRange'].start,
          endDate: rootGetters['activitiesFilter/getDateRange'].end,
        },
      })

      commit('SET_ACTIVITIES', activities)
    },
  },
}
