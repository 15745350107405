import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M9.85526 9.90233C9.32614 10.4432 9 11.1835 9 12C9 13.6569 10.3431 15 12 15C12.8308 15 13.5826 14.6624 14.1259 14.1168",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M19.6551 15.3964C20.3198 14.6745 20.9385 13.8441 21.4974 12.9053C21.8293 12.3477 21.8293 11.6521 21.4974 11.0946C18.4948 6.05059 13.7651 4.13748 9.45238 5.35522M2.73923 2.75793L6.63558 6.6484M21.2599 21.2506L17.3587 17.3553M17.3587 17.3553C12.4402 20.5624 6.17773 19.0792 2.50257 12.9054C2.17069 12.3479 2.17069 11.6523 2.50257 11.0947C3.66341 9.14465 5.08237 7.66255 6.63558 6.6484M17.3587 17.3553L6.63558 6.6484",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }