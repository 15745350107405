import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M11.25 3.41203H5.75C4.64543 3.41203 3.75 4.30746 3.75 5.41203V17.912C3.75 19.0166 4.64543 19.912 5.75 19.912H7.25647M7.25647 19.912C7.38647 17.4049 9.46051 15.412 12 15.412C14.5395 15.412 16.6135 17.4049 16.7435 19.912M7.25647 19.912H16.7435M20.25 12.412V17.912C20.25 19.0166 19.3546 19.912 18.25 19.912H16.7435M19 1.41203L20.0833 3.5787L22.25 4.66203L20.0833 5.74536L19 7.91203L17.9167 5.74536L15.75 4.66203L17.9167 3.5787L19 1.41203ZM14.75 10.162C14.75 11.6808 13.5188 12.912 12 12.912C10.4812 12.912 9.25 11.6808 9.25 10.162C9.25 8.64325 10.4812 7.41203 12 7.41203C13.5188 7.41203 14.75 8.64325 14.75 10.162Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }