<template>
  <div
    class="debug-bar-wrapper"
  >
    <div
      v-if="!isFullscreen"
      class="debug-bar__header"
      @click="onClickOpenFullscreen"
    >
      DEMO EDIT

      <div
        v-if="currentProjectDurationInDays"
      >
        - Aktuelle Projektdauer:
        {{ currentProjectDurationInDays }} Tage
      </div>

      <PfIcon
        class="debug-bar__header__arrow-icon"
        name="chevronUp"
      />
    </div>

    <div
      v-if="isFullscreen"
      class="debug-bar__fullscreen"
    >
      <div
        class="debug-bar__content"
      >
        <label
          for="apikey"
          style="display:block; padding-bottom: 0.5rem !important;"
        >
          Bitte API Key eingeben:
        </label>

        <MessageBoxInfo
          style="display:block; margin-bottom: 0.5rem !important;"
        >
          Den API Key findest du in 1Password unter "edit.demo.planfred.com API KEY for Devtools"
        </MessageBoxInfo>

        <input
          id="apikey"
          v-model="apiKey"
          style="display:block; margin-bottom: 1rem !important;"
          type="password"
          placeholder="API Key"
        >

        <div style="margin-top: 0.5rem; margin-bottom: 0.5rem;">
          <label
            v-for="option in actionOptions"
            :key="option.key"
            style="display: block; cursor: pointer;  margin-bottom: 0.5rem;"
          >
            <input
              v-model="selectedAction"
              type="radio"
              :value="option.key"
            >

            {{ option.label }}
          </label>
        </div>

        <PfButtonBarButton
          style="font-size: 20px; margin-top: 15px;"
          :is-loading="state === validStates.PROCESSING_PENDING"
          :is-enabled="Boolean(apiKey)"
          @click="onClickProcess"
        >
          Aktion ausführen
        </PfButtonBarButton>

        <div
          v-if="state === validStates.PROCESSING_ERROR"
          style="margin-top: 10px;"
        >
          <MessageBoxError>
            Request fehlgeschlagen. Bitte API-Key überprüfen.
          </MessageBoxError>
        </div>

        <div
          v-if="state === validStates.PROCESSING_SUCCESS"
          style="margin-top: 10px;"
        >
          <MessageBoxSuccess>
            Request erfolgreich
          </MessageBoxSuccess>
        </div>
      </div>

      <ButtonClose
        v-if="isEditEnabled"
        data-testid="modal-close-button"
        class="com_modal__x-close-button"
        size="xl"
        @click="onClickClose"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import ButtonClose from '@/components/ButtonClose.vue'
import MessageBoxError from '@/components/MessageBoxError.vue'
import MessageBoxInfo from '@/components/MessageBoxInfo.vue'
import MessageBoxSuccess from '@/components/MessageBoxSuccess.vue'
import PfButtonBarButton from '@/components/PfButtonBarButton.vue'

const axiosRaw = axios.create()

export default {
  name: 'DemoserverEditDebugBar',

  components: {
    PfButtonBarButton,
    ButtonClose,
    MessageBoxError,
    MessageBoxInfo,
    MessageBoxSuccess,
  },

  data () {
    const validStates = Object.freeze({
      INITIAL: 1,
      PROCESSING_PENDING: 2,
      PROCESSING_ERROR: 3,
      PROCESSING_SUCCESS: 4,
    })

    const validActions = Object.freeze({
      PAUSE: 'pause',
      RESUME: 'resume',
      JUMP_DAY_BACK: 'jumpDayBack',
      SYNC_TO_DEMOSERVER: 'syncToDemoserver',
    })

    return {
      state: validStates.INITIAL,
      validStates,
      validActions,

      isFullscreen: false,
      isEditEnabled: false,

      apiKey: undefined,
      selectedAction: undefined,
    }
  },

  computed: {
    actionOptions () {
      return [
        this.isEditEnabled
          ? {
              key: this.validActions.PAUSE,
              label: 'Aktuellen Stand einfrieren',
            }
          : {
              key: this.validActions.RESUME,
              label: 'Mit editieren fortfahren',
            },
        {
          key: this.validActions.JUMP_DAY_BACK,
          label: '1 Tag vergehen lassen',
        },
        {
          key: this.validActions.SYNC_TO_DEMOSERVER,
          label: 'Aktuellen Stand auf Demoserver übertragen',
        },
      ]
    },

    currentProject () {
      return this.$store.state.project.project
    },

    isInProjectRoute () {
      if (!this.$route || !this.$route.name) {
        return false
      }

      return this.$route.name.startsWith('projects-projectId')
    },

    currentProjectDurationInDays () {
      if (!this.currentProject || !this.isInProjectRoute) {
        return
      }

      return this.$dayjs().diff(this.currentProject.created, 'days')
    },
  },

  created () {
    this.loadStatus()
  },

  methods: {
    cleanApiUrl (url) {
      // Remove double slashes without affecting protocol (source: https://polymermallard.medium.com/regex-fix-duplicate-slashes-without-affecting-protocol-daa1ac34a469)
      return url.replaceAll(/(?<!:)\/+/gm, '/')
    },

    onClickOpenFullscreen () {
      this.isFullscreen = true
    },

    async loadStatus () {
      const { data } = await axiosRaw.get(this.cleanApiUrl(`${this.$config.api.host}/demoserver-edit-environment/status`))
      this.isEditEnabled = data.isEditEnabled

      if (this.isEditEnabled) {
        this.selectedAction = this.validActions.PAUSE
      } else {
        this.selectedAction = this.validActions.RESUME
      }

      if (!this.isEditEnabled) {
        this.isFullscreen = true
      }
    },

    async onClickProcess () {
      try {
        if (!this.apiKey) {
          return
        }

        if (this.state === this.validStates.PROCESSING_PENDING) {
          return
        }

        this.state = this.validStates.PROCESSING_PENDING

        if (this.selectedAction === this.validActions.PAUSE) {
          await axiosRaw.post(this.cleanApiUrl(`${this.$config.api.host}demoserver-edit-environment/pause`), undefined, {
            headers: {
              Authorization: this.apiKey,
            },
          })

          window.location.reload(true)
          this.isFullscreen = false
        }
        if (this.selectedAction === this.validActions.RESUME) {
          await axiosRaw.post(this.cleanApiUrl(`${this.$config.api.host}demoserver-edit-environment/resume`), undefined, {
            headers: {
              Authorization: this.apiKey,
            },
          })

          window.location.reload(true)
          this.isFullscreen = false
        }
        if (this.selectedAction === this.validActions.JUMP_DAY_BACK) {
          await axiosRaw.post(this.cleanApiUrl(`${this.$config.api.host}demoserver-edit-environment/jump-day-back`), undefined, {
            headers: {
              Authorization: this.apiKey,
            },
          })

          window.location.reload(true)
          this.isFullscreen = false
        }
        if (this.selectedAction === this.validActions.SYNC_TO_DEMOSERVER) {
          await axiosRaw.post(this.cleanApiUrl(`${this.$config.api.host}demoserver-edit-environment/sync-to-demoserver`), undefined, {
            headers: {
              Authorization: this.apiKey,
            },
          })
        }

        this.state = this.validStates.PROCESSING_SUCCESS
      } catch (err) {
        console.error(err)

        this.state = this.validStates.PROCESSING_ERROR
      }
    },

    onClickClose () {
      if (this.isEditEnabled) {
        this.isFullscreen = false
      }
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>
.debug-bar-wrapper {
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 10000;
  display: flex;
  justify-content: left;
}

.debug-bar__header {
  background-color: red;
  color: white;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-left: 1px solid #777;
  border-top: 1px solid #777;
  border-right: 1px solid #777;
  pointer-events: auto;

  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  padding: 0.25rem 0.5rem;

  &__arrow-icon {
    margin-left: 5px;
  }

  &__logo {
    width: 20px;
    margin-right: 5px;
  }
}

.debug-bar__fullscreen {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.7);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.debug-bar__content {
}
</style>
