import constants from '../config/constants'

// Example usage
// let input =  '"jkl mno" pqr "stu vwx" yz';
// let output = getSplittedSearchString(input);
// console.log(output); // Output: [ 'jkl mno', 'pqr', 'stu vwx', 'yz' ]
const getSplittedSearchString = input => {
  const parts = input
    .split('+')
    .map(part => part.trim())
    .filter(Boolean)

  // Initialize an array to hold the final results
  const result = []

  // Process each part
  for (const part of parts) {
    // Match groups of non-space characters, or quoted strings with spaces
    const matches = part.match(/(?:[^\s"]+|"[^"]*")/g)

    if (matches) {
      for (const match of matches) {
        const cleanedMatch = match
          .replaceAll('"', '')
          .toLowerCase()
          .trim()

        result.push(cleanedMatch)
      }
    }
  }

  return result
    .filter(Boolean)
}

const getParticipantNameSearchSegments = (participant, options = { includeEmail: true }) => {
  if (!participant) {
    return []
  }

  const segments = []

  if (participant.user && options.includeEmail) {
    segments.push(participant.user.email)
  }

  if (participant.firstname && participant.lastname) {
    segments.push(`${participant.firstname} ${participant.lastname}`)
    segments.push(`${participant.lastname} ${participant.firstname}`)
    segments.push(`${participant.firstname[0]}. ${participant.lastname}`)
    segments.push(`${participant.lastname} ${participant.firstname[0]}.`)
    segments.push(`${participant.lastname[0]}. ${participant.firstname}`)
    segments.push(`${participant.firstname} ${participant.lastname[0]}.`)
  } else if (participant.firstname) {
    segments.push(participant.firstname)
  } else if (participant.lastname) {
    segments.push(participant.lastname)
  }

  return segments
    .filter(Boolean)
    .map(segment => segment.toLowerCase())
}

const containsAny = (text, searchStrings) => {
  for (const searchString of searchStrings) {
    const textSplitted = text
      .toLowerCase()
      .split(constants.SEARCH_INDEX_SEPARATOR)

    const hasMatch = textSplitted.some(textSegment => textSegment.includes(searchString))

    if (hasMatch) {
      return true
    }
  }

  return false
}

const performSearch = ({ text, searchString = '' }) => {
  let searchHit = true

  // First we split by +. For every part there must be a match!
  const searchStringParts = searchString
    .split('+')
    .map(part => part.trim())
    .filter(Boolean)

  for (const searchStringPart of searchStringParts) {
    const searchStrings = getSplittedSearchString(searchStringPart)
    const hasMatch = containsAny(text, searchStrings)

    // if no token in a + concatenated segment is found, then the search is a miss
    if (!hasMatch) {
      searchHit = false
    }
  }

  return searchHit
}

export {
  getSplittedSearchString,
  getParticipantNameSearchSegments,
  performSearch,
}
