import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M5.37036 9.80627L3 9M5.37036 13.75H2.75M5.37036 17.4437L3 18.25M18.63 9.80627L21 9M18.63 13.75H21.2504M18.63 17.4437L21 18.25M12 13.75V20.75M7.75 7.5V7C7.75 4.65279 9.65279 2.75 12 2.75C14.3472 2.75 16.25 4.65279 16.25 7V7.5M5.75 15V9.75C5.75 8.64543 6.64543 7.75 7.75 7.75H16.25C17.3546 7.75 18.25 8.64543 18.25 9.75V15C18.25 18.4518 15.4518 21.25 12 21.25C8.54822 21.25 5.75 18.4518 5.75 15Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }