<template>
  <transition
    name="accordion"
    @enter="onAnimationEnter"
    @after-enter="onAnimationAfterEnter"
    @leave="onAnimationLeave"
    @after-leave="onAnimationAfterLeave"
  >
    <div
      v-if="isOpen"
    >
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AnimateExpand',

  emits: [
    'after:animation',
  ],

  data () {
    return {
      isOpen: false,
    }
  },

  mounted () {
    this.isOpen = true // We set it to open in mounted, so the open animation is shown
  },

  methods: {
    // Animation taken (in reduced from) from here: https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/
    onAnimationEnter (element) {
      const width = getComputedStyle(element).width

      element.style.width = width
      element.style.position = 'absolute'
      element.style.visibility = 'hidden'
      element.style.height = 'auto'

      const height = getComputedStyle(element).height

      element.style.width = null
      element.style.position = null
      element.style.visibility = null
      element.style.height = 0

      // Force repaint to make sure the
      // animation is triggered correctly.
      // getComputedStyle(element).height

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        element.style.height = height
      })
    },

    onAnimationAfterEnter (element) {
      element.style.height = 'auto'
    },

    onAnimationLeave (element) {
      const height = getComputedStyle(element).height

      element.style.height = height

      requestAnimationFrame(() => {
        element.style.height = 0
      })
    },

    onAnimationAfterLeave () {
      this.$emit('after:animation')
    },
  },
}
</script>

<style scoped>
.accordion-enter-active,
.accordion-leave-active {
  transition: height 300ms ease-in-out;
  overflow: hidden;
}

.accordion-enter-from,
.accordion-leave-to {
  height: 0;
}
</style>
