import ClipboardFile from '@client-shared/models/clipboard-file.model.js'
import { Uppy } from '@uppy/core'

export default {
  install: (app) => {
    const clipboardUppy = new Uppy({
      autoProceed: true,
      restrictions: {},
    })

    clipboardUppy
      .on('upload-error', (file, error) => {
        const {
          $auth, $store,
        } = app.config.globalProperties

        const uppyFile = clipboardUppy.store.state.files[file.id]
        const responseStatusCode = error && error.source && error.source.status ? error.source.status : 0

        if (responseStatusCode === 401 && $auth.loggedIn) {
          $store.commit('clipboard/REMOVE_FILE', file)

          return $auth.logout()
        }

        $store.commit('clipboard/REPLACE_FILE', ClipboardFile.createFromUppyFile(uppyFile))
      })
      .on('upload-success', (file) => {
        const uppyFile = clipboardUppy.store.state.files[file.id]
        const $store = app.config.globalProperties.$store

        if (!uppyFile) { return }

        uppyFile.error = null

        $store.commit('clipboard/REPLACE_FILE', ClipboardFile.createFromUppyFile(uppyFile))
        $store.commit('clipboard/SET_FILTER', '')
        clipboardUppy.removeFile(uppyFile.id)
      })
      .on('upload-progress', file => {
        const $store = app.config.globalProperties.$store
        const storeFile = $store.state.clipboard.files.find(f => f.id === file.id)

        if (!storeFile) {
        // File can be empty, for example when file is uploaded in one tab, and removed in another tab (during upload)
          return
        }

        const storeFileProgress = storeFile.uploadPercentageDone
        const uppyFile = clipboardUppy.store.state.files[file.id]
        const uppyFileProgress = uppyFile.progress.percentage

        if (storeFileProgress !== uppyFileProgress) {
          $store.commit('clipboard/REPLACE_FILE', ClipboardFile.createFromUppyFile(uppyFile))
        }
      })
      .on('file-removed', (file) => {
        const $store = app.config.globalProperties.$store
        // will be called on uppy destroy, so we remove all uncompleted upload files from vuex store
        if (!file.progress.uploadComplete) {
          $store.commit('clipboard/REMOVE_FILE', ClipboardFile.createFromUppyFile(file))
        }
      })

    app.config.globalProperties.$clipboardUppy = clipboardUppy
  },
}
