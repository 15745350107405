import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M15.448 9.75L13.198 12M13.198 12L10.948 14.25M13.198 12L10.948 9.75M13.198 12L15.448 14.25M7.56062 4.75H19.698C20.8026 4.75 21.698 5.64543 21.698 6.75V17.25C21.698 18.3546 20.8026 19.25 19.698 19.25H7.56062C6.86883 19.25 6.22616 18.8925 5.86134 18.3047L2.60272 13.0547C2.20176 12.4087 2.20176 11.5913 2.60272 10.9453L5.86134 5.69527C6.22616 5.1075 6.86883 4.75 7.56062 4.75Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }