import dayjs from 'dayjs'
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timeZonePlugin from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import utcPlugin from 'dayjs/plugin/utc'

import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/en-gb'

dayjs.extend(localizedFormatPlugin)
dayjs.extend(utcPlugin)
dayjs.extend(timeZonePlugin)
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

dayjs.tz.setDefault('Europe/Vienna')

dayjs.updateLocale('en', {
  weekStart: 1,
})

export default {
  install: (app) => {
    app.config.globalProperties.$dayjs = dayjs
  },
}
