import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M21 17.9999C21 17.9999 19.666 19.5443 18.166 19.5443C16.666 19.5443 15.4594 18.115 13.9866 18.115C12.5138 18.115 11.6598 18.786 10.75 19.7499M18.4142 4.16415L18.8358 4.58573C19.6168 5.36678 19.6168 6.63311 18.8358 7.41416L6.58579 19.6642C6.21071 20.0392 5.70201 20.2499 5.17157 20.2499H2.75V17.8284C2.75 17.2979 2.96071 16.7892 3.33579 16.4142L15.5858 4.16416C16.3668 3.38311 17.6332 3.38311 18.4142 4.16415Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }