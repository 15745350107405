import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M21.099 10.75V8.75C21.099 7.64543 20.2036 6.75 19.099 6.75H13.3842C13.0498 6.75 12.7376 6.5829 12.5521 6.3047L11.4427 4.6406C11.0718 4.0842 10.4473 3.75 9.77863 3.75H5.599C4.49443 3.75 3.599 4.64543 3.599 5.75V17.677C3.599 18.5457 4.30326 19.25 5.17201 19.25M21.099 10.75H10.3455C9.45826 10.75 8.67709 11.3345 8.42674 12.1857L6.68111 18.1208C6.48421 18.7903 5.86981 19.25 5.17201 19.25M21.099 10.75H21.5125C22.1801 10.75 22.6603 11.3917 22.4719 12.0322L20.7713 17.8143C20.5209 18.6655 19.7397 19.25 18.8525 19.25H5.17201",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }