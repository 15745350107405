import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M1.75 11.75H4.50146C5.38966 11.75 6.17144 11.1643 6.42094 10.3118L8.51434 3.15936C8.65513 2.67835 9.33729 2.68054 9.47498 3.16244L14.5136 20.7975C14.6522 21.2826 15.3403 21.2805 15.4759 20.7946L17.5919 13.2124C17.8331 12.3479 18.6208 11.75 19.5183 11.75H22.25",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }