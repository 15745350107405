import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M2.901 5.75V17.25C2.901 18.3546 3.79643 19.25 4.901 19.25H19.401C20.5056 19.25 21.401 18.3546 21.401 17.25V8.75C21.401 7.64543 20.5056 6.75 19.401 6.75H13.2214C12.5527 6.75 11.9282 6.4158 11.5573 5.8594L10.7447 4.6406C10.3738 4.0842 9.74934 3.75 9.08063 3.75H4.901C3.79643 3.75 2.901 4.64543 2.901 5.75Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }