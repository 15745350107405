import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M8.75 4.99998V17.5M15.25 6.49998V19M2.75 17.4188V7.58112C2.75 6.93547 3.16315 6.36226 3.77566 6.15809L8.29397 4.65199C8.59056 4.55312 8.91063 4.5494 9.20944 4.64134L14.7906 6.35861C15.0894 6.45055 15.4094 6.44683 15.706 6.34797L19.2757 5.15809C20.247 4.83433 21.25 5.55728 21.25 6.58112V16.4188C21.25 17.0645 20.8369 17.6377 20.2243 17.8419L15.706 19.348C15.4094 19.4468 15.0894 19.4506 14.7906 19.3586L9.20944 17.6413C8.91063 17.5494 8.59056 17.5531 8.29397 17.652L4.72434 18.8419C3.75305 19.1656 2.75 18.4427 2.75 17.4188Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "square",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }