import Activity from '../models/activity.model.js'

export const postActivitiesQuery = async ({ axios, projectId, query }) => {
  const { data } = await axios.post('activities', {
    projectId,
    ...query,
  })

  return data.map(a => new Activity(a))
}

export const getActivity = async ({ axios, projectId, activityId }) => {
  const { data } = await axios.get(`activities/${activityId}`, {
    params: {
      projectid: projectId,
    },
  })

  return new Activity(data)
}
