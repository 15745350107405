import { nextTick } from 'vue'

export default {
  install: (app) => {
    const $router = app.config.globalProperties.$router
    const $store = app.config.globalProperties.$store

    $router.beforeEach((to, from, next) => {
      const isSamePage = from.name === to.name && JSON.stringify(from.params) === JSON.stringify(to.params)
      const routesToLoginPage = to.name === 'login'

      if (isSamePage || routesToLoginPage) {
        return next()
      }

      if ($store.state.unsavedChanges.unsavedChanges.length) {
        $store.commit('unsavedChanges/SET_SHOW_OVERLAY', true)

        nextTick(() => {
          const scrollToElement = document.querySelector(`.${$store.state.unsavedChanges.cssClass}`)
          if (scrollToElement) {
            scrollToElement.scrollIntoView({
              block: 'center',
              behavior: 'smooth',
            })
          }
        })

        return next(false)
      }

      next()
    })
  },
}
