import {
  sortTasksByAssigneeAsc,
  sortTasksByAssigneeDesc,
  sortTasksByConsecutiveNumberAsc,
  sortTasksByConsecutiveNumberDesc,
  sortTasksByCreatorAsc,
  sortTasksByCreatorDesc,
  sortTasksByDueDateAsc,
  sortTasksByDueDateDesc,
  sortTasksByFilesAsc,
  sortTasksByFilesDesc,
  sortTasksByLocationAsc,
  sortTasksByLocationDesc,
  sortTasksByCreatedAsc,
  sortTasksByCreatedDesc,
  sortTasksByModifiedAsc,
  sortTasksByModifiedDesc,
  sortTasksByStateAsc,
  sortTasksByStateDesc,
  sortTasksByTitleAsc,
  sortTasksByTitleDesc,
  sortTasksByTypeAsc,
  sortTasksByTypeDesc,
  sortTasksByPublishedAsc,
  sortTasksByPublishedDesc,
  sortTasksBySelected,
} from './sort-functions.js'
import constants from '../config/constants.js'

export default ({ sortProperty, sortOrderReversed, project, selectedTaskIds = [] }) => {
  if (sortProperty === constants.SORT_PROPERTIES.TASK_TITLE && !sortOrderReversed) { return sortTasksByTitleAsc }
  if (sortProperty === constants.SORT_PROPERTIES.TASK_TITLE && sortOrderReversed) { return sortTasksByTitleDesc }

  if (sortProperty === constants.SORT_PROPERTIES.TASK_FILES && !sortOrderReversed) { return sortTasksByFilesAsc }
  if (sortProperty === constants.SORT_PROPERTIES.TASK_FILES && sortOrderReversed) { return sortTasksByFilesDesc }

  if (sortProperty === constants.SORT_PROPERTIES.TASK_CONSECUTIVE_NUMBER && !sortOrderReversed) { return sortTasksByConsecutiveNumberDesc }
  if (sortProperty === constants.SORT_PROPERTIES.TASK_CONSECUTIVE_NUMBER && sortOrderReversed) { return sortTasksByConsecutiveNumberAsc }

  if (sortProperty === constants.SORT_PROPERTIES.TASK_TYPE && !sortOrderReversed) { return sortTasksByTypeAsc }
  if (sortProperty === constants.SORT_PROPERTIES.TASK_TYPE && sortOrderReversed) { return sortTasksByTypeDesc }

  if (sortProperty === constants.SORT_PROPERTIES.TASK_STATE && !sortOrderReversed) { return sortTasksByStateAsc }
  if (sortProperty === constants.SORT_PROPERTIES.TASK_STATE && sortOrderReversed) { return sortTasksByStateDesc }

  if (sortProperty === constants.SORT_PROPERTIES.TASK_ASSIGNEE && !sortOrderReversed) { return sortTasksByAssigneeAsc.bind({ project }) }
  if (sortProperty === constants.SORT_PROPERTIES.TASK_ASSIGNEE && sortOrderReversed) { return sortTasksByAssigneeDesc.bind({ project }) }

  if (sortProperty === constants.SORT_PROPERTIES.TASK_CREATOR && !sortOrderReversed) { return sortTasksByCreatorAsc.bind({ project }) }
  if (sortProperty === constants.SORT_PROPERTIES.TASK_CREATOR && sortOrderReversed) { return sortTasksByCreatorDesc.bind({ project }) }

  if (sortProperty === constants.SORT_PROPERTIES.TASK_DUE_DATE && !sortOrderReversed) { return sortTasksByDueDateAsc }
  if (sortProperty === constants.SORT_PROPERTIES.TASK_DUE_DATE && sortOrderReversed) { return sortTasksByDueDateDesc }

  if (sortProperty === constants.SORT_PROPERTIES.TASK_LOCATION && !sortOrderReversed) { return sortTasksByLocationAsc.bind({ project }) }
  if (sortProperty === constants.SORT_PROPERTIES.TASK_LOCATION && sortOrderReversed) { return sortTasksByLocationDesc.bind({ project }) }

  if (sortProperty === constants.SORT_PROPERTIES.TASK_CREATED && !sortOrderReversed) { return sortTasksByCreatedDesc }
  if (sortProperty === constants.SORT_PROPERTIES.TASK_CREATED && sortOrderReversed) { return sortTasksByCreatedAsc }

  if (sortProperty === constants.SORT_PROPERTIES.TASK_MODIFIED && !sortOrderReversed) { return sortTasksByModifiedDesc }
  if (sortProperty === constants.SORT_PROPERTIES.TASK_MODIFIED && sortOrderReversed) { return sortTasksByModifiedAsc }

  if (sortProperty === constants.SORT_PROPERTIES.TASK_NEWEST && !sortOrderReversed) { return sortTasksByPublishedDesc }
  if (sortProperty === constants.SORT_PROPERTIES.TASK_NEWEST && sortOrderReversed) { return sortTasksByPublishedAsc }

  if (sortProperty === constants.SORT_PROPERTIES.TASK_SELECTED && !sortOrderReversed) { return sortTasksBySelected.bind({ selectedTaskIds }) }
  if (sortProperty === constants.SORT_PROPERTIES.TASK_SELECTED && sortOrderReversed) { return (a, b) => sortTasksBySelected.bind({ selectedTaskIds })(a, b) * -1 }

  return sortTasksByTitleAsc
}
