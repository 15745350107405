export default {
  namespaced: true,

  state: () => {
    return {
      downloadFormat: 'originalfilename',
    }
  },

  mutations: {
    SET_DOWNLOAD_FORMAT (state, option) {
      state.downloadFormat = option
    },
  },
}
