export default (query = {}) => {
  let queryString = '?'

  for (const key of Object.keys(query)) {
    if (query[key]) {
      if (queryString !== '?') {
        queryString = queryString + '&'
      }
      queryString = queryString + key
      queryString = queryString + '=' + query[key]
    }
  }
  return queryString
}
