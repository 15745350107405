import isOnLocalhost from '@client-shared/utils/is-on-localhost'

export default ({ error, tags, customData }) => {
  if (isOnLocalhost) {
    console.log('sendRaygun', {
      error,
      tags,
      customData,
    })
  }

  if (window.rg4js) {
    window.rg4js('send', {
      error,
      tags,
      customData,
    })
  }
}
