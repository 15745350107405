<template>
  <div class="decorative-wave">
    <div class="decorative-wave__trapezoid decorative-wave__trapezoid--one" />

    <div class="decorative-wave__trapezoid decorative-wave__trapezoid--two" />
  </div>
</template>

<script>
export default {
  name: 'DecorativeWaves',
}
</script>

<style lang="scss" scoped>
@keyframes swim {
  0% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 62%, 0 40%);
    clip-path: polygon(0 100%, 100% 100%, 100% 72%, 0 50%);
  }
  100% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 15%, 0 60%);
    clip-path: polygon(0 100%, 100% 100%, 100% 25%, 0 70%);
  }
}
@keyframes swim-reverse {
  0% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 27%, 0 67%);
    clip-path: polygon(0 100%, 100% 100%, 100% 27%, 0 67%);
  }
  100% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 50%, 0 18%);
    clip-path: polygon(0 100%, 100% 100%, 100% 50%, 0 18%);
  }
}
.decorative-wave {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 0.5rem solid #FF6400;
}
.decorative-wave__trapezoid {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(#009eb7, 0.1);
}
.decorative-wave__trapezoid--one {
  -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 62%, 0 40%);
  clip-path: polygon(0 100%, 100% 100%, 100% 62%, 0 40%);
  animation: swim 20s ease-in-out 0s infinite alternate;
}
.decorative-wave__trapezoid--two {
  -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 27%, 0 67%);
  clip-path: polygon(0 100%, 100% 100%, 100% 27%, 0 67%);
  animation: swim-reverse 20s ease-in-out 0s infinite alternate;
}
</style>
