import { BroadcastChannel } from 'broadcast-channel'

// The broadcastChannel is used to send messages across tabs
// https://github.com/pubkey/broadcast-channel

export default {
  install: (app) => {
    const broadcastChannel = new BroadcastChannel('broadcast-channel')

    broadcastChannel.addEventListener('message', message => {
      const { $config, $clipboardUppy, $auth } = app.config.globalProperties

      if (message.toString() === $config.constants.BROADCAST_MESSAGES.LOGOUT) {
        return $auth.logout({ shouldBroadcast: false })
      } else if (message.startsWith($config.constants.BROADCAST_MESSAGES.REMOVE_CLIPBOARD_FILE)) { // if other tab initiates clipboard file removal, message looks like this: remove-clipboard-file-{{clipboardFileId}}
        const clipboardFileId = message.replace($config.constants.BROADCAST_MESSAGES.REMOVE_CLIPBOARD_FILE, '')

        $clipboardUppy.removeFile(clipboardFileId)
      }
    })

    app.config.globalProperties.$broadcastChannel = broadcastChannel
  },
}
