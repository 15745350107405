import plansFilter from '@/store/plansFilter'

export default {
  namespaced: true,

  state: () => {
    return {
      ...plansFilter.state(),
    }
  },

  getters: {
    ...plansFilter.getters,
  },

  mutations: {
    ...plansFilter.mutations,
  },
}
