export default {
  namespaced: true,

  state: () => {
    return {
      isOpen: null,
    }
  },

  mutations: {
    SET_IS_OPEN (state, isOpen) {
      state.isOpen = isOpen
    },
  },
}
