<template>
  <div
    class="approval-request__selection    com_selection-box com_selection-box--radio"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'RadioSelection',
}
</script>
