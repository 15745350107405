import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M3.75 10.412V5.91203C3.75 4.80746 4.64543 3.91203 5.75 3.91203H19.25C20.3546 3.91203 21.25 4.80746 21.25 5.91203V13.412C21.25 14.5166 20.3546 15.412 19.25 15.412H14.75M17.25 7.91203V10.912M17.25 7.91203H14.25M17.25 7.91203L14 11.162M9.25 21.412H4.75C3.64543 21.412 2.75 20.5166 2.75 19.412V15.912C2.75 14.8074 3.64543 13.912 4.75 13.912H9.25C10.3546 13.912 11.25 14.8074 11.25 15.912V19.412C11.25 20.5166 10.3546 21.412 9.25 21.412Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }