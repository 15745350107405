import i18nInstance from '@/plugins/i18n'
import setLangCookieDomain from '@/utils/set-lang-cookie-domain'

const $i18n = i18nInstance.global

export default ({ $axios, app }) => {
  $axios.interceptors.response.use(response => {
    const $config = app.config.globalProperties.$config
    const $dayjs = app.config.globalProperties.$dayjs

    // on demo.planfred.com do not use users language settings
    // every visitor should get its own language based on browser or local setting, but not on global user setting from DB
    if ($config.isDemoserver) {
      return Promise.resolve(response)
    }

    if (
      response &&
      response.config &&
      response.config.url === 'current-user'
    ) {
      const currentUser = response.data.user
      if (currentUser?.languageSelected) {
        if (currentUser.languageSelected !== $i18n.locale) {
          $i18n.locale = currentUser.languageSelected
        }
        if (currentUser.languageSelected !== $dayjs.locale()) {
          $dayjs.locale(currentUser.languageSelected.split('-')[0])
        }
      } else if (currentUser?.languageBrowser) {
        if (currentUser.languageBrowser !== $i18n.locale) {
          $i18n.locale = currentUser.languageBrowser
        }
        if (currentUser.languageSelected !== $dayjs.locale()) {
          $dayjs.locale(currentUser.languageBrowser.split('-')[0])
        }
      }

      setLangCookieDomain($i18n.locale)
    }
    return Promise.resolve(response)
  })
}
