import sharedConstants from '@client-shared/config/constants'

export default {
  ...sharedConstants,
  PARTICIPANT_PRINT_SETTINGS: {
    GROUP_BY_PERMISSIONS: 'permissions',
    GROUP_BY_ROLES: 'roles',
    GROUP_BY_NONE: 'none',
  },
  ITEM_PRINT_SETTINGS: {
    GROUP_BY_TOP_CATEGORIES: 'top_categories',
    GROUP_BY_ROLES: 'roles',
    GROUP_BY_PHASETAGS: 'phasetags',
    GROUP_BY_NONE: 'none',
  },
  TASKS_LIST_GROUPING: {
    GROUP_BY_LOCATION_LAYER: 'location_layer',
    GROUP_BY_TYPE: 'type',
    GROUP_BY_NONE: 'none',
  },
  LIST_MODUS: {
    LIST: 'list',
    PRINT: 'print',
    UPDATE: 'update',
    TRASH: 'trash',
    APPROVAL: 'approval',
    MULTI_ACTION: 'multiaction',
    MULTI_ACTION_EDIT: 'multiaction-edit',
  },
  LIST_HEADER_MODUS: {
    AUTO: 'auto', // Show the header based on the viewport width. If it's too narrow, how a selectbox for sorting
    ALWAYS_SHOW_LIST_HEADER: 'alwaysShowListHeader',
    ALWAYS_SHOW_SORT_SELECT: 'alwaysShowSortSelect',
  },
  POPUP_TYPES: {
    OVERLAY: 'overlay',
    MODAL: 'modal',
  },
  MULTI_ACTION: {
    TOP_CATEGORY: 'top_category',
    PHASETAG: 'phasetag',
    AUTHOR: 'author',
    TAGS: 'tags',
    APPROVAL: 'approval',
    TRASHED: 'trashed',
    ROLE: 'role',
    PERMISSION: 'permission',
    TASK_TYPE: 'taskType',
    TASK_ASSIGNEE: 'taskAssignee',
    TASK_PUBLISH: 'taskPublish',
    TASK_DISCARD_DRAFTS: 'taskDiscardDrafts',
  },
  PLAN_DOC_GROUP_IMPORT_OPTIONS: {
    IMPORT_AS_NEW: 'NEW',
    IMPORT_AS_REVISION: 'REVISION',
  },
  FILTER_STORE_NAMES: {
    ACTIVITIES_FILTER: 'activitiesFilter',
    PLANS_FILTER: 'plansFilter',
    PLANS_FILTER_MODUS_APPROVAL: 'plansFilterModusApproval',
    PLANS_FILTER_MODUS_TRASH: 'plansFilterModusTrash',
    DOCUMENTS_FILTER: 'documentsFilter',
    DOCUMENTS_FILTER_MODUS_APPROVAL: 'documentsFilterModusApproval',
    DOCUMENTS_FILTER_MODUS_TRASH: 'documentsFilterModusTrash',
    TASKS_FILTER_DRAFTS: 'tasksFilterDrafts',
    TASKS_FILTER_MAIN: 'tasksFilterMain',
    TASKS_FILTER_DONE: 'tasksFilterDone',
    TASKS_FILTER_DRAFTS_MAP_VIEW: 'tasksFilterDraftsMapView',
    TASKS_FILTER_MAIN_MAP_VIEW: 'tasksFilterMainMapView',
    TASKS_FILTER_DONE_MAP_VIEW: 'tasksFilterDoneMapView',
    TASKS_FILTER_TRASH: 'tasksFilterTrash',
    PARTICIPANTS_FILTER: 'participantsFilter',
    PARTICIPANTS_FILTER_MODUS_TRASH: 'participantsFilterModusTrash',
  },
  BILLING_PLANS: {
    NONE: 'none',
    V2_SMALL_19: 'v2_small_19',
    V2_MEDIUM_59: 'v2_medium_59',
    V1_LARGE_199: 'v1_large_199',
    V1_PRIME_359: 'v1_prime_359',
    V1_SMALL_19: 'v1_small_19',
    V1_MEDIUM_59: 'v1_medium_59',
  },
  BILLING_INTERVALS: {
    MONTHLY: 'monthly',
    ANNUALLY: 'annually',
  },

  CRM_FEATURE_FLAGS: {
    HIDE_SETTINGS_LINK_POPUP: 'hideSettingsLinkPopup',
    HIDE_PARALLEL_UPLOAD_INFO_POPUP: 'hideParallelUploadInfoPopup',
    HIDE_MULTI_SELECTION_SHIFT_HINT: 'hideMultiSelectionShiftHint',
    HIDE_INLINE_EDIT_PROMOTION_ELEMENT: 'hideInlineEditPromotionElement',
    HIDE_APPLY_PLAN_SETTINGS_PROMOTION_ELEMENT: 'hideApplyPlanSettingsPromotionElement',
    HIDE_ENHANCED_MANUALS_PROMOTION_ELEMENT: 'hideEnhancedManualsPromotionElement',
    HIDE_TOP_CATEGORIES_PROMOTION_ELEMENT: 'hideTopCategoriesPromotionElement',
    HIDE_RECIPIENT_GROUPS_INFO_POPUP: 'hideRecipientGroupsInfoPopup',
    HIDE_FEATURES_2021_PROMOTION_ELEMENT: 'hideFeatures2021PromotionElement',
    HIDE_TASKS_IS_HERE_PROMOTION_ELEMENT: 'hideTaskIsHerePromotionElement',
    HIDE_TASK_LOCALIZATION_ELEMENT: 'hideTaskLocalizationElement',
  },

  LOCAL_STORAGE_DEMOSERVER_MODAL: 'crm-demo-project-modal-already-shown',
  LOCAL_STORAGE_PROJECT_CANCELLATIONS_PENDING: 'project-cancellations-pending',
  LOCAL_STORAGE_ACCESS_TOKEN_KEY: 'auth._token.local',
  LOCAL_STORAGE_REFRESH_TOKEN_KEY: 'auth._refresh_token.local',

  BROADCAST_MESSAGES: {
    LOGOUT: 'logout',
    REMOVE_CLIPBOARD_FILE: 'remove-clipboard-file-',
    SAVE_AND_CLOSE_IN_TAB: 'save-and-close-in-tab-',
  },

  TASK_LIST_SUB_TABS: {
    DRAFTS: 'drafts',
    MAIN: 'main',
    DONE: 'done',
    TRASH: 'trash',
  },

  HTML_TAG_CLASS_EDIT_IN_TAB: 'edit-in-tab',
  HTML_TAG_CLASS_NEW_IN_TAB: 'new-in-tab',
  HTML_TAG_CLASS_FADE_OUT_AND_CLOSE: 'tab-detail-only__exit-animation',
  PROJECT_SEARCH_THRESHOLD: 4,
  SIGNATURE_IMAGE_FILE_SIZE_LIMIT: 1024 * 100, // 100kb,
  SIGNATURE_IMAGE_ALLOWED_MIME_TYPES: ['image/png', 'image/jpeg', 'image/jpg'],

  FILE_ASSIGNMENT_CONFIDENCES: {
    PLAN_NAME_MATCH: 'planNameMatch',
    BEST_GUESS: 'bestGuess',
    NO_MATCH: 'noMatch',
  },

  TILE_PROCESSING_STATUS: {
    NOT_STARTED: 'notStarted',
    PROCESSING: 'processing',
    UPLOADING: 'uploading',
    ERROR: 'error',
    DONE: 'done',
  },

  LANG_COOKIE_NAME: 'langkey',

}
