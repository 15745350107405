import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M17.7499 18.912H20.3459C21.5088 18.912 22.4522 17.9109 22.1336 16.7925C21.4006 14.219 19.6688 12.412 16.9999 12.412C16.5538 12.412 16.1339 12.4625 15.7405 12.5586M11.2498 6.66203C11.2498 8.45696 9.79472 9.91203 7.9998 9.91203C6.20487 9.91203 4.7498 8.45696 4.7498 6.66203C4.7498 4.8671 6.20487 3.41203 7.9998 3.41203C9.79472 3.41203 11.2498 4.8671 11.2498 6.66203ZM19.7498 7.16203C19.7498 8.68081 18.5186 9.91203 16.9998 9.91203C15.481 9.91203 14.2498 8.68081 14.2498 7.16203C14.2498 5.64325 15.481 4.41203 16.9998 4.41203C18.5186 4.41203 19.7498 5.64325 19.7498 7.16203ZM2.08106 17.788C2.85968 14.6487 4.85865 12.412 7.9998 12.412C11.1409 12.412 13.1399 14.6487 13.9185 17.788C14.1983 18.9159 13.2602 19.912 12.0982 19.912H3.90137C2.73936 19.912 1.80134 18.9159 2.08106 17.788Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }