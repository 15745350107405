export default {
  namespaced: true,

  state: () => {
    return {
      list: [],
      importedIds: [],
    }
  },

  getters: {
    getById: (state, getters, rootState) => {
      return participantId => { return state.list.find(participant => participant._id === participantId) }
    },

    getByName: (state, getters, rootState) => {
      return participantName => { return state.list.filter(participant => participant.name === participantName) }
    },
  },

  mutations: {
    SET_PARTICIPANTS (state, participants) {
      state.list = participants.filter(participant => !participant.trashed)
    },

    REPLACE_PARTICIPANT (state, participant) {
      const participantIndex = state.list.findIndex(p => p._id === participant._id)

      state.list[participantIndex] = participant
    },

    SET_IMPORTED_IDS (state, importedIds) {
      state.importedIds = [...importedIds]
    },

    RESET_IMPORTED_IDS (state) {
      state.importedIds = []
    },

    RESET_LIST (state) {
      state.list = []
    },
  },
}
