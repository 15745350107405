import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 25",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M13.5269 10.25L14.7393 7.75M14.7393 7.75L16.6792 3.75L18.6125 7.75M14.7393 7.75H18.6125M18.6125 7.75L19.8208 10.25M13.9292 13.75H19.4292L13.9292 20.25H19.4292M6.1792 3.75V20.25M6.1792 20.25L3.1792 17.25M6.1792 20.25L9.1792 17.25",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }