import { postLogs } from '@client-shared/api/logs.api'
import ObjectID from 'bson-objectid'

const ENABLE_SERVER_PUSH = true
const PUSH_LOGS_TO_SERVER_INTERVAL = 30000 // 30 seconds
const LOG_LIST_LIMIT = 1000
let logEntriesQueue = []
let isCommitRunning = false
let isPushToServerRunning = false
let pushToServerInterval

export default {
  install: (app) => {
    const $store = app.config.globalProperties.$store
    const $auth = app.config.globalProperties.$auth
    const $axios = app.config.globalProperties.$axios

    if (!pushToServerInterval) {
      pushToServerInterval = setInterval(pushLogsToServer, PUSH_LOGS_TO_SERVER_INTERVAL)
    }

    async function pushLogsToServer () {
      if (!ENABLE_SERVER_PUSH) {
        return
      }

      try {
        if (isPushToServerRunning) {
          return
        }

        isPushToServerRunning = true

        // If commit is currently running we wait for it's completion and proceed with the server push afterwards
        if (isCommitRunning) {
          let interval

          await new Promise(resolve => {
            interval = setInterval(() => {
              if (!isCommitRunning) {
                resolve()
              }
            }, 500)
          })

          if (interval) {
            clearInterval(interval)
          }
        }

        const logs = await getLogs()

        if (logs.length === 0) {
          return
        }

        await postLogs({
          axios: $axios,
          logs,
        })

        localStorage.setItem('logs', JSON.stringify([]))
      } catch (err) {
        console.error(err)
      } finally {
        isPushToServerRunning = false
      }
    }

    async function commitLogEntriesQueue () {
      try {
        if (logEntriesQueue.length === 0 || isCommitRunning || isPushToServerRunning) {
          return
        }

        isCommitRunning = true

        // As this function is async there are cases where log entries are pushed to the queue while the commit is running. To avoid losing logs we need to check if there are new entries in the queue and commit them as well
        while (logEntriesQueue.length > 0) {
          const newEntries = [...logEntriesQueue]
          logEntriesQueue = []

          let logs = await getLogs()
          logs = logs.concat(newEntries)

          if (logs.length > LOG_LIST_LIMIT) {
            logs = logs.slice(-LOG_LIST_LIMIT)
          }

          localStorage.setItem('logs', JSON.stringify(logs))
        }
      } catch (err) {
        console.error(err)
      } finally {
        isCommitRunning = false
      }
    }

    function log ({ tag, message, context }) {
      try {
        const $route = app.config.globalProperties.$route

        const logEntry = {
          _id: new ObjectID().toString(),
          tag,
          timestamp: new Date().toISOString(),
          userId: $auth.user ? $auth.user._id : undefined,
          projectId: $store.state.project.project ? $store.state.project.project._id : undefined,
          route: $route.name,
          message,
          context,
        }

        if (!logEntry.timestamp) {
          throw new Error('timestamp is required')
        }
        if (!logEntry.tag) {
          throw new Error('tag is required')
        }

        // First push log entries to a queue. Because we need to access the device storage and this operation is async, the queue ensures that no logs are lost
        logEntriesQueue.push(logEntry)

        commitLogEntriesQueue()
      } catch (err) {
        console.error('Error while logging', err)
      }
    }

    async function getLogs () {
      try {
        const logs = JSON.parse(localStorage.getItem('logs') || '[]')

        return logs
      } catch (err) {
        console.error('Error while getting logs', err)
      }
    }

    const logger = {
      log,
      getLogs,
      pushLogsToServer,
    }

    app.config.globalProperties.$logger = logger
  },
}
