import filterFunctions from '@client-shared/utils/filter-functions'

const getPropertyValue = ({ currentValue, hasResult }) => {
  return {
    count: currentValue ? currentValue.count + 1 : 1,
    hasResult,
  }
}

const setHasResult = (object, value) => {
  for (const key of Object.keys(object)) {
    object[key].hasResult = value
  }
}

const handleRolesCount = ({ result, participant }) => {
  result.roles[participant.roles[0]] = getPropertyValue({
    currentValue: result.roles[participant.roles[0]],
    hasResult: false,
  })
}

const handlePermissionsCount = ({ result, participant }) => {
  result.permissions[participant.permission] = getPropertyValue({
    currentValue: result.permissions[participant.permission],
    hasResult: false,
  })
}

const handleRolesHasResults = ({ result, project, filterStore, participantsAll, isFilterActive }) => {
  if (isFilterActive) {
    const participantsFilteredExcludingRoles = participantsAll.filter(item => { return filterFunctions.participants.matchesFilters({ item, project, ...filterStore, roles: [] }) })

    for (const participant of participantsFilteredExcludingRoles) {
      result.roles[participant.roles[0]].hasResult = true
    }
  } else {
    setHasResult(result.roles, true)
  }
}

const handlePermissionsHasResults = ({ result, project, filterStore, participantsAll, isFilterActive }) => {
  if (isFilterActive) {
    const participantsFilteredExcludingPermissions = participantsAll.filter(item => { return filterFunctions.participants.matchesFilters({ item, project, ...filterStore, permissions: [] }) })

    for (const participant of participantsFilteredExcludingPermissions) {
      result.permissions[participant.permission].hasResult = true
    }
  } else {
    setHasResult(result.permissions, true)
  }
}

export const getResult = ({ participantsAll, project, participantsFiltered, filterStore }) => {
  const result = {
    roles: {},
    permissions: {},
  }

  const isFilterActive = participantsAll.length !== participantsFiltered.length

  for (const participant of participantsAll) {
    handleRolesCount({ result, participant })
    handlePermissionsCount({ result, participant })
  }

  handleRolesHasResults({ result, project, isFilterActive, participantsAll, filterStore })
  handlePermissionsHasResults({ result, project, isFilterActive, participantsAll, filterStore })

  return result
}
