export default class Activity {
  constructor ({ _id, user, date, projectid, action, doc, log }) {
    this._id = _id
    this.user = user
    this.date = date
    this.projectid = projectid
    this.action = action
    this.doc = doc
    this.log = log
  }
}
