import Activity from '../models/activity.model.js'
import Document from '../models/document.model.js'

export const getDocuments = async ({ axios, projectId }) => {
  const { data } = await axios.get('documents', {
    params: {
      projectid: projectId,
    },
  })

  // Freeze Objects to prevent data binding on documents which causes slow performance
  const documents = data.map(p => Object.freeze(new Document(p)))

  return documents
}

export const getDocument = async ({ axios, documentId }) => {
  const { data } = await axios.get(`documents/${documentId}`)

  return new Document(data)
}

export const getLatestCreatedDocument = async ({ axios, projectId }) => {
  const { data } = await axios.get('documents', {
    params: {
      projectid: projectId,
      filter: 'latest-by-user',
    },
  })

  if (data.length === 0) {
    return null
  }

  return new Document(data[0])
}

export const postDocument = async ({ axios, document }) => {
  // remove computed prop
  const { computed, ...postDocument } = document

  const { data } = await axios.post('documents', postDocument)

  return new Document(data)
}

export const importDocuments = async ({ axios, sourceProject, targetProject, settings, imports }) => {
  const postBody = {
    sourceProjectId: sourceProject._id,
    targetProjectId: targetProject._id,
    settings,
    imports,
  }

  let { data } = await axios.post('documents/import', postBody)

  data = data.map(dataEntry => {
    if (dataEntry.targetDocument) {
      return {
        ...dataEntry,
        targetDocument: new Document(dataEntry.targetDocument),
      }
    }

    return dataEntry
  })

  return data
}

export const updateName = async ({ axios, document, newName, newContent }) => {
  const patchObject = {
    op: 'update',
    path: 'name',
    value: {
      old: {
        name: document.name,
        content: document.content,
      },
      new: {
        name: newName,
        content: newContent,
      },
    },
  }

  const { data } = await axios.patch(`documents/${document._id}`, patchObject)

  return new Document(data)
}

export const updatePermission = async ({ axios, document, newRestricted, newUsers }) => {
  const oldRestricted = document.permission.restricted
  const oldUsers = document.permission.users

  const patchObject = {
    op: 'update',
    path: 'permission',
    value: {
      old: {
        restricted: oldRestricted,
        users: oldUsers,
      },
      new: {
        restricted: newRestricted,
        users: newUsers,
      },
    },
  }

  const { data } = await axios.patch(`documents/${document._id}`, patchObject)

  return new Document(data)
}

export const updatePermissionDownloadLinks = async ({ axios, document, permissionDownloadLinks }) => {
  const patchObject = {
    op: 'update',
    path: 'permissionDownloadLinks',
    value: {
      old: {
        permissionDownloadLinks: document.permissionDownloadLinks,
      },
      new: {
        permissionDownloadLinks,
      },
    },
  }

  const { data } = await axios.patch(`documents/${document._id}`, patchObject)

  return new Document(data)
}

export const updateAuthor = async ({ axios, document, newUserId, newRole }) => {
  let oldAuthor = {}
  let newAuthor = {}

  if (document.author.userId) {
    oldAuthor = {
      userId: document.author.userId,
    }
  } else if (document.author.role) {
    oldAuthor = {
      role: document.author.role,
    }
  }
  if (newUserId) {
    newAuthor = {
      userId: newUserId,
    }
  } else if (newRole) {
    newAuthor = {
      role: newRole,
    }
  }

  const patchObject = {
    op: 'update',
    path: 'author',
    value: {
      old: oldAuthor,
      new: newAuthor,
    },
  }

  const { data } = await axios.patch(`documents/${document._id}`, patchObject)

  return new Document(data)
}

export const updatePhasetag = async ({ axios, document, newPhasetag }) => {
  const patchObject = {
    op: 'update',
    path: 'phasetag',
    value: {
      old: document.phasetag,
      new: newPhasetag,
    },
  }

  const { data } = await axios.patch(`documents/${document._id}`, patchObject)

  return new Document(data)
}

export const updateTopCategory = async ({ axios, document, newTopCategory }) => {
  if (document.topCategory === newTopCategory) {
    return new Document(document)
  }

  const patchObject = {
    op: 'update',
    path: 'topCategory',
    value: {
      old: document.topCategory,
      new: newTopCategory,
    },
  }

  const { data } = await axios.patch(`documents/${document._id}`, patchObject)

  return new Document(data)
}

export const updateTags = async ({ axios, document, newValue }) => {
  const patchObject = {
    op: 'update',
    path: 'tags',
    value: {
      old: document.tags,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`documents/${document._id}`, patchObject)

  return new Document(data)
}

export const updateTrashed = async ({ axios, document, newTrashedValue }) => {
  const patchObject = {
    op: 'update',
    path: 'trashed',
    value: {
      old: document.trashed,
      new: newTrashedValue,
    },
  }

  const { data } = await axios.patch(`documents/${document._id}`, patchObject)

  return new Document(data)
}

export const updateRevisionApproval = async ({ axios, document, revision, newParticipantIds, newApprovalMode, newApprovalRequestMessage, newApprovalDate }) => {
  const oldApprovalMode = revision.approval.mode.approvalmode
  const oldApprovalRequestMessage = revision.approval.mode.approvalrequestmessage
  const oldApprovalDate = revision.approval.approvalDate

  const oldParticipantIds = revision.approval.by
    .filter(by => by.approved !== 'cancelled')
    .map(approver => approver.participantid)

  const patchObject = {
    op: 'update',
    path: 'approval',
    value: {
      old: {
        byParticipantIds: oldParticipantIds,
        approvalmode: oldApprovalMode,
        approvalrequestmessage: oldApprovalRequestMessage,
        approvalDate: oldApprovalDate,
      },
      new: {
        byParticipantIds: newParticipantIds,
        approvalmode: newApprovalMode,
        approvalrequestmessage: newApprovalRequestMessage,
        approvalDate: newApprovalDate,
      },
    },
  }

  const { data } = await axios.patch(`documents/${document._id}/revisions/${revision._id}`, patchObject)

  return new Document(data)
}

export const approveRevisionByMyself = async ({ axios, document, revision, comment }) => {
  const patchObject = {
    op: 'execute',
    path: 'approveByMyself',
    value: {
      comment,
    },
  }

  const { data } = await axios.patch(`documents/${document._id}/revisions/${revision._id}`, patchObject)

  return new Document(data)
}

export const rejectRevisionByMyself = async ({ axios, document, revision, comment }) => {
  const patchObject = {
    op: 'execute',
    path: 'rejectByMyself',
    value: {
      comment,
    },
  }

  const { data } = await axios.patch(`documents/${document._id}/revisions/${revision._id}`, patchObject)

  return new Document(data)
}

export const updateRevisionApprovalStatus = async ({ axios, document, approver, approvalStatus, message = '', attachments = [] }) => {
  const putObject = {
    approved: approvalStatus,
    comment: {
      message,
      attachments,
    },
  }

  const { data } = await axios.put(`documents/${document._id}/approvals/${approver._id}`, putObject)

  return new Document(data)
}

export const addRevisionApprovalComment = async ({ axios, document, approver, message = '', attachments = [] }) => {
  const postObject = {
    message,
    attachments,
  }

  const { data } = await axios.post(`documents/${document._id}/approvals/${approver._id}/comments`, postObject)

  return new Document(data)
}

export const getDownloadRevisionUrl = async ({ axios, documentId, revisionId, fileKey, contentDisposition = 'attachment' }) => {
  if (!axios || !documentId || !revisionId || !fileKey) {
    console.error('Arguments for function call "DocumentsApi.getRevisionDownloadUrl" missing')
  }

  if (!['attachment', 'inline'].includes(contentDisposition)) {
    throw new Error('Invalid contentDisposition')
  }

  const { data } = await axios.get(`documents/${documentId}/revisions/${revisionId}?contentDisposition=${contentDisposition}`, {
    params: {
      filekey: fileKey,
    },
  })

  return data
}

export const logRevisionDownload = async ({ axios, documentId, revisionId }) => {
  const { data } = await axios.post(`documents/${documentId}/revisions/${revisionId}/log-download`)

  return data
}

export const getAttachmentDownloadUrl = async ({ axios, documentId, attachmentId, contentDisposition = 'attachment' }) => {
  if (!['attachment', 'inline'].includes(contentDisposition)) {
    throw new Error('Invalid contentDisposition')
  }

  const { data } = await axios.get(`documents/${documentId}/attachments/${attachmentId}?contentDisposition=${contentDisposition}`)

  return data
}

export const logAttachmentDownload = async ({ axios, documentId, attachmentId }) => {
  const { data } = await axios.post(`documents/${documentId}/attachments/${attachmentId}/log-download`)

  return data
}

export const triggerRevisionSend = async ({
  axios,
  revisionIdsToSend,
  receiverEmails,
  addedParticipants,
  mailData,
  subject,
  isPdfOnlyActive,
  isShowRecipientsListActive,
}) => {
  const postObject = {
    revisionidlist: revisionIdsToSend,
    recipients: receiverEmails,
    addedParticipants,
    mailData,
    subject,
    showRecipientsList: isShowRecipientsListActive,
    pdfOnly: isPdfOnlyActive,
  }

  const { data } = await axios.post('documents/mail', postObject)

  return new Activity(data)
}

export const isDocumentNameAvailable = async ({ axios, projectId, documentId, newDocumentName }) => {
  const { data } = await axios.get('documents/isnameavailable', {
    params: {
      newdocumentname: newDocumentName,
      projectid: projectId,
      documentid: documentId,
    },
  })

  return data
}

export const addRevision = async ({ axios, document, revision }) => {
  const postObject = revision

  const { data } = await axios.post(`documents/${document._id}/revisions`, postObject)

  return new Document(data)
}

export const importRevisions = async ({ axios, sourceProject, targetProject, settings, imports }) => {
  const postBody = {
    sourceProjectId: sourceProject._id,
    targetProjectId: targetProject._id,
    settings,
    imports,
  }

  let { data } = await axios.post('documents/revisions/import', postBody)

  data = data.map(dataEntry => {
    if (dataEntry.targetDocument) {
      return {
        ...dataEntry,
        targetDocument: new Document(dataEntry.targetDocument),
      }
    }

    return dataEntry
  })

  return data
}

export const getDocumentActivities = async ({ axios, documentId }) => {
  const { data } = await axios.get(`documents/${documentId}/activities`)

  return data.map(activity => new Activity(activity))
}
