import * as TextblocksApi from '@client-shared/api/textblocks.api.js'

export default {
  namespaced: true,

  state: () => {
    return {
      allTextBlocks: {},
    }
  },

  mutations: {
    GET_BLOCKS (state, { category, blocksList }) {
      state.allTextBlocks = {
        ...state.allTextBlocks,
        [category]: blocksList,
      }
    },
    PATCH_BLOCK (state, updatedBlock) {
      const { category, _id } = updatedBlock
      state.allTextBlocks = {
        ...state.allTextBlocks,
        [category]: (state.allTextBlocks?.[category] || []).map(block => {
          if (_id !== block._id) { return block }
          return updatedBlock
        }),
      }
    },
    POST_BLOCK (state, newBlock) {
      const { category } = newBlock
      state.allTextBlocks = {
        [category]: [newBlock, ...(state.allTextBlocks?.[category] || [])],
      }
    },
    DELETE_BLOCK (state, { category, blockId }) {
      state.allTextBlocks = {
        ...state.allTextBlocks,
        [category]: state.allTextBlocks?.[category].filter(
          block => block._id !== blockId,
        ),
      }
    },
  },

  actions: {
    async getAllBlocks ({ commit }, { projectId, category }) {
      const blocks = await TextblocksApi.getTextblocks({
        axios: this.$axios,
        projectId,
        category,
      })
      commit('GET_BLOCKS', {
        category,
        blocksList: blocks,
      })
    },
    async patchBlock ({ commit }, { blockId, textblock, newValue }) {
      const updatedBlock = await TextblocksApi.patchBlock({
        axios: this.$axios, blockId, textblock, newValue,
      })
      commit('PATCH_BLOCK', updatedBlock)
    },
    async postBlock ({ commit }, newBlock) {
      const { projectId, title, text, category } = newBlock
      const result = await TextblocksApi.postNewBlock({
        axios: this.$axios,
        projectId,
        title,
        text,
        category,
      })

      commit('POST_BLOCK', result)
    },
    async deleteBlock ({ commit }, { blockId, category }) {
      const response = await TextblocksApi.deleteBlock({
        axios: this.$axios,
        blockId,
      })
      if (response.deletion === 'ok') {
        commit('DELETE_BLOCK', { category, blockId })
      }
    },
  },
}
