import sendRaygun from '@/utils/send-raygun.js'

export default ({ $axios, app }) => {
  $axios.interceptors.response.use(undefined, async error => {
    const router = app.config.globalProperties.$router
    const route = app.config.globalProperties.$route
    const $auth = app.config.globalProperties.$auth

    if (!error.response) {
      await sendRaygun({
        error,
        tags: ['axios-no-error-response'],
      })

      return Promise.reject(error)
    }

    if (error.response.status === 403 &&
    route.name !== 'projects-projectId-plans' &&
    route.name !== 'projects-projectId-plans-planId' &&
    route.name !== 'projects-projectId-documents' &&
    route.name !== 'projects-projectId-documents-documentId' &&
    route.name !== 'projects-projectId-tasks-taskId' &&
    route.name !== 'download-downloadId') {
      await router.push({
        name: 'projects',
      })

      return Promise.reject(error)
    }

    if (error.response.status === 401 && $auth.loggedIn && route.name !== 'download-downloadId') {
      await $auth.logout()

      return Promise.reject(error)
    }

    // log 500er separately
    if (error.response.status === 500) {
      await sendRaygun({
        error,
        tags: ['axios-on-error-500'],
      })

      return Promise.reject(error)
    }

    if (error.response.status === 429) {
      await sendRaygun({
        error,
        tags: ['axios-on-error-rate-limit-reached'],
      })

      return Promise.reject(error)
    }

    // do not log 401 auth or 403 forbidden´
    if (error.response.status !== 401 && error.response.status !== 403) {
      await sendRaygun({
        error,
        tags: ['axios-on-error'],
      })

      return Promise.reject(error)
    }

    return Promise.reject(error)
  })
}
