import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M11.8151 2.17969C12.0904 1.60677 12.9096 1.60677 13.1849 2.17969L15.5534 7.10966C15.6643 7.34032 15.8847 7.49956 16.1393 7.5329L21.5889 8.24637C22.2226 8.32933 22.476 9.1071 22.0119 9.54462L18.0278 13.3009C17.8409 13.4772 17.7564 13.7357 17.8034 13.9877L18.8039 19.3544C18.9204 19.9793 18.2574 20.4596 17.6961 20.157L12.8614 17.5509C12.6359 17.4294 12.3641 17.4294 12.1386 17.5509L7.30393 20.157C6.74257 20.4596 6.07955 19.9793 6.19605 19.3544L7.19661 13.9877C7.2436 13.7357 7.15911 13.4772 6.97218 13.3009L2.98806 9.54462C2.524 9.1071 2.77743 8.32933 3.41107 8.24637L8.86069 7.5329C9.11531 7.49956 9.33574 7.34032 9.44656 7.10966L11.8151 2.17969Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }