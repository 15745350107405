import { getFilters } from '@client-shared/api/filters.api'
import * as ParticipantsApi from '@client-shared/api/participants.api.js'
import * as ProjectsApi from '@client-shared/api/projects.api.js'

import constants from '@/config/constants.js'

export default {
  namespaced: true,

  state: () => {
    return {
      project: null,
      isLoading: false,
    }
  },

  getters: {
    locationPropertiesSorted (state) {
      if (!state.project) {
        return []
      }

      return [
        'location1',
        'location2',
        'location3',
        'location4',
      ]
        .sort((a, b) => state.project.locations[a].order > state.project.locations[b].order ? 1 : -1)
    },

    locationPropertiesSortedEnabled (state, getters) {
      return [...getters.locationPropertiesSorted]
        .filter(locationProperty => {
          return state.project.locations[locationProperty].isEnabled
        })
    },

    isProjectUsingLocationFeature (state, getters, rootState) {
      return state.project.computed.isLocationPropertiesEnabled || rootState.locationLayers.list.length > 0
    },

    isTaskUsingLocationTags (state, getters, rootState) {
      return task => {
        return getters.locationPropertiesSortedEnabled.find(locationProperty => task[locationProperty])
      }
    },

    isTaskUsingLocationFeature (state, getters, rootState) {
      return task => {
        const hasLocationTag = getters.isTaskUsingLocationTags(task)
        const hasLocationLayer = task.locationLayerId

        return hasLocationTag || hasLocationLayer
      }
    },
  },

  mutations: {
    SET_PROJECT (state, project) {
      state.project = project
    },

    SET_IS_LOADING (state, isLoading) {
      state.isLoading = isLoading
    },

    UPDATE_PARTICIPANT (state, newParticipant) {
      state.project.participants = state.project.participants.map(p => {
        if (p._id === newParticipant._id) {
          newParticipant.user = p.user
          return newParticipant
        }

        return p
      })
    },

    ADD_PARTICIPANT (state, participantToAdd) {
      const existingParticipantIndex = state.project.participants.findIndex(participant => participant._id === participantToAdd._id)

      if (existingParticipantIndex !== -1) {
        state.project.participants[existingParticipantIndex] = participantToAdd
      } else {
        state.project.participants.push(participantToAdd)
      }
    },

    RESET_PROJECT (state) {
      state.project = null
    },

    FULL_RESET (state) {
      state.project = null
    },
  },

  actions: {
    async fetch ({ commit, state, dispatch }, projectId) {
      if (state.isLoading) {
        return
      }

      commit('SET_IS_LOADING', true)

      try {
        const [project] = await Promise.all([
          ProjectsApi.getProject({
            axios: this.$axios,
            projectId,
          }),
          dispatch('fetchFilters', { projectId }),
        ])

        commit('SET_IS_LOADING', false)
        commit('SET_PROJECT', project)
      } catch (e) {
        commit('SET_IS_LOADING', false)
        throw e
      }
    },

    async fetchFilters ({ dispatch }, { projectId }) {
      const filters = await getFilters({
        axios: this.$axios,
        projectId,
        filterKeys: [
          constants.FILTER_KEYS.PLANS_LIST_WEB_MAIN,
          constants.FILTER_KEYS.DOCUMENTS_LIST_WEB_MAIN,
          constants.FILTER_KEYS.TASKS_LIST_WEB_DRAFTS,
          constants.FILTER_KEYS.TASKS_LIST_WEB_MAIN,
          constants.FILTER_KEYS.TASKS_LIST_WEB_DONE,
          constants.FILTER_KEYS.PARTICIPANTS_LIST_WEB_MAIN,
          constants.FILTER_KEYS.ACTIVITIES_LIST_WEB,
        ],
      })

      dispatch('commitFilters', filters)
    },

    commitFilters ({ commit }, filters) {
      const plansFilter = filters.find(filter => filter.filterKey === constants.FILTER_KEYS.PLANS_LIST_WEB_MAIN)
      const documentsFilter = filters.find(filter => filter.filterKey === constants.FILTER_KEYS.DOCUMENTS_LIST_WEB_MAIN)
      const taskFilterDrafts = filters.find(filter => filter.filterKey === constants.FILTER_KEYS.TASKS_LIST_WEB_DRAFTS)
      const taskFilterMain = filters.find(filter => filter.filterKey === constants.FILTER_KEYS.TASKS_LIST_WEB_MAIN)
      const taskFilterDone = filters.find(filter => filter.filterKey === constants.FILTER_KEYS.TASKS_LIST_WEB_DONE)

      const activitiesFilter = filters.find(filter => filter.filterKey === constants.FILTER_KEYS.ACTIVITIES_LIST_WEB)

      const participantsFilter = filters.find(filter => filter.filterKey === constants.FILTER_KEYS.PARTICIPANTS_LIST_WEB_MAIN)

      if (taskFilterDrafts) {
        commit('tasksFilterDrafts/SET_FILTERS', {
          filters: taskFilterDrafts.body,
          lastServerUpdate: taskFilterDrafts.modified,
        }, { root: true })
      }

      if (taskFilterMain) {
        commit('tasksFilterMain/SET_FILTERS', {
          filters: taskFilterMain.body,
          lastServerUpdate: taskFilterMain.modified,
        }, { root: true })
      }

      if (taskFilterDone) {
        commit('tasksFilterDone/SET_FILTERS', {
          filters: taskFilterDone.body,
          lastServerUpdate: taskFilterDone.modified,
        }, { root: true })
      }

      if (activitiesFilter) {
        commit('activitiesFilter/SET_FILTERS', {
          filters: activitiesFilter.body,
          lastServerUpdate: activitiesFilter.modified,
        }, { root: true })
      }
      if (plansFilter) {
        commit('plansFilter/SET_FILTERS', {
          filters: plansFilter.body,
          modified: plansFilter.modified,
        }, { root: true })
      }
      if (documentsFilter) {
        commit('documentsFilter/SET_FILTERS', {
          filters: documentsFilter.body,
          modified: documentsFilter.modified,
        }, { root: true })
      }
      if (participantsFilter) {
        commit('participantsFilter/SET_FILTERS', {
          filters: participantsFilter.body,
          modified: participantsFilter.modified,
        }, { root: true })
      }
    },

    async deleteProject ({
      commit,
      state,
    }) {
      await ProjectsApi.deleteProject({
        axios: this.$axios,
        project: state.project,
      })

      commit('RESET_PROJECT')
    },

    async transferProject ({
      commit,
      state,
    }, { newValue }) {
      const updatedProject = await ProjectsApi.transferProject({
        axios: this.$axios,
        project: state.project,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async acceptProjectOwnershipTransfer ({
      commit,
      state,
    }, { project }) {
      const updatedProject = await ProjectsApi.acceptProjectOwnershipTransfer({
        axios: this.$axios,
        project,
      })

      // We need this check, because accept and cancel can also be triggered on the projects index page
      if (state.project && state.project._id === updatedProject._id) {
        commit('SET_PROJECT', updatedProject)
      }
    },

    async cancelProjectOwnershipTransfer ({
      commit,
      state,
    }, { project }) {
      const updatedProject = await ProjectsApi.cancelProjectOwnershipTransfer({
        axios: this.$axios,
        project,
      })

      if (state.project && state.project._id === updatedProject._id) {
        commit('SET_PROJECT', updatedProject)
      }
    },

    async updateProjectTitle ({
      commit,
      state,
    }, {
      newValue,
      oldValue,
    }) {
      const updatedProject = await ProjectsApi.updateProjectTitle({
        axios: this.$axios,
        project: state.project,
        newValue,
        oldValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async updateProjectLanguage ({
      commit,
      state,
    }, { newValue }) {
      const updatedProject = await ProjectsApi.updateProjectLanguage({
        axios: this.$axios,
        project: state.project,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async updateProjectTimezone ({
      commit,
      state,
    }, { newValue }) {
      const updatedProject = await ProjectsApi.updateProjectTimezone({
        axios: this.$axios,
        project: state.project,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async deleteParticipantSelection ({
      commit,
      state,
    }, {
      participant,
      selectionId,
    }) {
      const updatedParticipant = await ParticipantsApi.deleteGroupSelection({
        axios: this.$axios,
        project: state.project,
        participant,
        selectionId,
      })

      commit('UPDATE_PARTICIPANT', updatedParticipant)
    },

    async updateParticipantSelection ({
      commit,
      state,
    }, {
      participant,
      selectionGroup,
      category,
    }) {
      const updatedParticipant = await ParticipantsApi.updateGroupSelection({
        axios: this.$axios,
        project: state.project,
        category,
        participant,
        selectionGroup,
      })

      commit('UPDATE_PARTICIPANT', updatedParticipant)
    },

    async addParticipantSelection ({
      commit,
      state,
    }, {
      participant,
      selectionLabel,
      selectionEmails,
      category,
    }) {
      const updatedParticipant = await ParticipantsApi.addGroupSelection({
        axios: this.$axios,
        project: state.project,
        category,
        participant,
        selectionLabel,
        selectionEmails,
      })

      commit('UPDATE_PARTICIPANT', updatedParticipant)
    },

    async addParticipant ({
      dispatch,
      commit,
      state,
    }, {
      newParticipant,
      inviteMessage,
    }) {
      const project = state.project

      await ParticipantsApi.addParticipant({
        axios: this.$axios,
        project,
        newParticipant,
        inviteMessage,
      })

      await dispatch('fetch', project._id)
    },

    async updateParticipantUploadAlertStatus ({
      commit,
      state,
    }, {
      participant,
      newValue,
    }) {
      const updatedParticipant = await ParticipantsApi.updateAlertStatus({
        axios: this.$axios,
        project: state.project,
        participant,
        newValue,
      })

      commit('UPDATE_PARTICIPANT', updatedParticipant)
    },

    async updateParticipantPlanDocReport ({
      commit,
      state,
    }, {
      participant,
      newValue,
      authTokenRestricted,
    }) {
      const updatedParticipant = await ParticipantsApi.updatePlanDocReport({
        axios: this.$axios,
        authTokenRestricted,
        project: state.project,
        participant,
        isEnabled: newValue,
      })

      commit('UPDATE_PARTICIPANT', updatedParticipant)
    },

    async updateParticipantTaskReport ({
      commit,
      state,
    }, {
      participant,
      newValue,
      authTokenRestricted,
    }) {
      const updatedParticipant = await ParticipantsApi.updateTaskReport({
        axios: this.$axios,
        authTokenRestricted,
        project: state.project,
        participant,
        isEnabled: newValue,
      })

      commit('UPDATE_PARTICIPANT', updatedParticipant)
    },

    async updateParticipantDueDateReport ({
      commit,
      state,
    }, {
      participant,
      newValue,
      authTokenRestricted,
    }) {
      const updatedParticipant = await ParticipantsApi.updateDueDateReport({
        axios: this.$axios,
        authTokenRestricted,
        project: state.project,
        participant,
        isEnabled: newValue,
      })

      commit('UPDATE_PARTICIPANT', updatedParticipant)
    },

    async updateParticipantWeekStartReport ({
      commit,
      state,
    }, {
      participant,
      containsTasks,
      containsPlans,
      containsDocuments,
    }) {
      const updatedParticipant = await ParticipantsApi.updateWeekStartReport({
        axios: this.$axios,
        project: state.project,
        participant,
        containsTasks,
        containsPlans,
        containsDocuments,
      })

      commit('UPDATE_PARTICIPANT', updatedParticipant)
    },

    async updateParticipantCanapproverevisions ({
      commit,
      state,
    }, {
      participant,
      newValue,
    }) {
      const updatedParticipant = await ParticipantsApi.updateCanapproverevisions({
        axios: this.$axios,
        project: state.project,
        participant,
        newValue,
      })

      commit('UPDATE_PARTICIPANT', updatedParticipant)
    },

    async updateParticipantSendCopyToMyself ({
      commit,
      state,
    }, {
      participant,
      newValue,
    }) {
      const updatedParticipant = await ParticipantsApi.updateSendCopyToMyself({
        axios: this.$axios,
        project: state.project,
        participant,
        newValue,
      })

      commit('UPDATE_PARTICIPANT', updatedParticipant)
    },

    async updateParticipantShowActivitiesInTaskDetail ({
      commit,
      state,
    }, {
      participant,
      newValue,
    }) {
      const updatedParticipant = await ParticipantsApi.updateShowActivitiesInTaskDetail({
        axios: this.$axios,
        project: state.project,
        participant,
        newValue,
      })

      commit('UPDATE_PARTICIPANT', updatedParticipant)
    },

    async updateParticipantPermission ({
      commit,
      state,
    }, {
      participant,
      newValue,
      message,
    }) {
      const updatedParticipant = await ParticipantsApi.updatePermission({
        axios: this.$axios,
        project: state.project,
        participant,
        newValue,
        message,
      })

      commit('UPDATE_PARTICIPANT', updatedParticipant)
    },

    async updateParticipantEnabled ({
      commit,
      state,
    }, {
      participant,
      newValue,
    }) {
      const updatedParticipant = await ParticipantsApi.updateEnabled({
        axios: this.$axios,
        project: state.project,
        participant,
        newValue,
      })

      commit('UPDATE_PARTICIPANT', updatedParticipant)
    },

    async updateParticipantName ({
      commit,
      state,
    }, {
      participant,
      newFirstName,
      newLastName,
    }) {
      const updatedParticipant = await ParticipantsApi.updateName({
        axios: this.$axios,
        project: state.project,
        participant,
        newFirstName,
        newLastName,
      })

      commit('UPDATE_PARTICIPANT', updatedParticipant)
    },

    async updateParticipantPhone ({
      commit,
      state,
    }, {
      participant,
      newValue,
    }) {
      const updatedParticipant = await ParticipantsApi.updatePhone({
        axios: this.$axios,
        project: state.project,
        participant,
        newValue,
      })

      commit('UPDATE_PARTICIPANT', updatedParticipant)
    },

    async updateParticipantAddress ({
      commit,
      state,
    }, {
      participant,
      newValue,
    }) {
      const updatedParticipant = await ParticipantsApi.updateAddress({
        axios: this.$axios,
        project: state.project,
        participant,
        newValue,
      })

      commit('UPDATE_PARTICIPANT', updatedParticipant)
    },

    async updateParticipantNote ({
      commit,
      state,
    }, {
      participant,
      newValue,
    }) {
      const updatedParticipant = await ParticipantsApi.updateNote({
        axios: this.$axios,
        project: state.project,
        participant,
        newValue,
      })

      commit('UPDATE_PARTICIPANT', updatedParticipant)
    },

    async updateParticipantCompany ({
      commit,
      state,
    }, {
      participant,
      newValue,
    }) {
      const updatedParticipant = await ParticipantsApi.updateCompany({
        axios: this.$axios,
        project: state.project,
        participant,
        newValue,
      })

      commit('UPDATE_PARTICIPANT', updatedParticipant)
    },

    async updateParticipantRoles ({
      commit,
      state,
      dispatch,
    }, {
      participant,
      newValue,
    }) {
      await ParticipantsApi.updateRoles({
        axios: this.$axios,
        project: state.project,
        participant,
        newValue,
      })

      await dispatch('fetch', state.project._id)
    },

    async addPhasetagPlans ({
      commit,
      state,
    }, { newValue }) {
      const updatedProject = await ProjectsApi.addPhasetagPlans({
        axios: this.$axios,
        project: state.project,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async reorderPhasetagsPlans ({
      commit,
      state,
    }, { newValue }) {
      const updatedProject = await ProjectsApi.reorderPhasetagsPlans({
        axios: this.$axios,
        project: state.project,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async addPhasetagDocuments ({
      commit,
      state,
    }, { newValue }) {
      const updatedProject = await ProjectsApi.addPhasetagDocuments({
        axios: this.$axios,
        project: state.project,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async reorderPhasetagsDocuments ({
      commit,
      state,
    }, { newValue }) {
      const updatedProject = await ProjectsApi.reorderPhasetagsDocuments({
        axios: this.$axios,
        project: state.project,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async reorderLocationProperties ({
      commit,
      state,
    }, {
      oldValue,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.reorderLocationProperties({
        axios: this.$axios,
        project: state.project,
        oldValue,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async updatePlansTaggingRestrictedForUsers ({
      commit,
      state,
    }, {
      oldValue,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.updatePlansTaggingRestrictedForUsers({
        axios: this.$axios,
        project: state.project,
        oldValue,
        newValue,
      })
      commit('SET_PROJECT', updatedProject)
    },

    async updateDocumentsTaggingRestrictedForUsers ({
      commit,
      state,
    }, {
      oldValue,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.updateDocumentsTaggingRestrictedForUsers({
        axios: this.$axios,
        project: state.project,
        oldValue,
        newValue,
      })
      commit('SET_PROJECT', updatedProject)
    },

    async updateTopCategoriesPlansEnabled ({
      commit,
      state,
    }, {
      oldValue,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.updateTopCategoriesPlansEnabled({
        axios: this.$axios,
        project: state.project,
        oldValue,
        newValue,
      })
      commit('SET_PROJECT', updatedProject)
    },

    async addTopCategoriesPlans ({
      commit,
      state,
    }, { newValue }) {
      const updatedProject = await ProjectsApi.addTopCategoriesPlans({
        axios: this.$axios,
        project: state.project,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async reorderTopCategoriesPlans ({
      commit,
      state,
    }, { newValue }) {
      const updatedProject = await ProjectsApi.reorderTopCategoriesPlans({
        axios: this.$axios,
        project: state.project,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async updateTopCategoriesPlans ({
      commit,
      state,
      dispatch,
    }, {
      oldValue,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.updateTopCategoriesPlans({
        axios: this.$axios,
        project: state.project,
        oldValue,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
      dispatch('plans/fetch', state.project._id, { root: true })
    },

    async deleteTopCategoryPlans ({
      commit,
      state,
    }, { value }) {
      const updatedProject = await ProjectsApi.deleteTopCategoryPlans({
        axios: this.$axios,
        project: state.project,
        value,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async enableTopCategoriesDocuments ({
      commit,
      state,
    }, {
      oldValue,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.enableTopCategoriesDocuments({
        axios: this.$axios,
        project: state.project,
        oldValue,
        newValue,
      })
      commit('SET_PROJECT', updatedProject)
    },

    async addTopCategoriesDocuments ({
      commit,
      state,
    }, { newValue }) {
      const updatedProject = await ProjectsApi.addTopCategoriesDocuments({
        axios: this.$axios,
        project: state.project,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async reorderTopCategoriesDocuments ({
      commit,
      state,
    }, { newValue }) {
      const updatedProject = await ProjectsApi.reorderTopCategoriesDocuments({
        axios: this.$axios,
        project: state.project,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async updateTopCategoriesDocuments ({
      commit,
      state,
      dispatch,
    }, {
      oldValue,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.updateTopCategoriesDocuments({
        axios: this.$axios,
        project: state.project,
        oldValue,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
      dispatch('documents/fetch', state.project._id, { root: true })
    },

    async deleteTopCategoryDocuments ({
      commit,
      state,
    }, { value }) {
      const updatedProject = await ProjectsApi.deleteTopCategoryDocuments({
        axios: this.$axios,
        project: state.project,
        value,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async addRole ({
      commit,
      state,
    }, { newValue }) {
      const updatedProject = await ProjectsApi.addRole({
        axios: this.$axios,
        project: state.project,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async addTagPlans ({
      commit,
      state,
    }, { newValue }) {
      const updatedProject = await ProjectsApi.addTagPlans({
        axios: this.$axios,
        project: state.project,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async addTagTasks ({
      commit,
      state,
    }, { newValue }) {
      const updatedProject = await ProjectsApi.addTagTasks({
        axios: this.$axios,
        project: state.project,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async addTagDocuments ({
      commit,
      state,
    }, { newValue }) {
      const updatedProject = await ProjectsApi.addTagDocuments({
        axios: this.$axios,
        project: state.project,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async updatePhasetagPlans ({
      commit,
      state,
      dispatch,
    }, {
      oldValue,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.updatePhasetagPlans({
        axios: this.$axios,
        project: state.project,
        oldValue,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
      dispatch('plans/fetch', state.project._id, { root: true })
    },

    async updatePhasetagDocuments ({
      commit,
      state,
      dispatch,
    }, {
      oldValue,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.updatePhasetagDocuments({
        axios: this.$axios,
        project: state.project,
        oldValue,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
      dispatch('documents/fetch', state.project._id, { root: true })
    },

    async updateTagPlans ({
      commit,
      state,
      dispatch,
    }, {
      oldValue,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.updateTagPlans({
        axios: this.$axios,
        project: state.project,
        oldValue,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
      dispatch('plans/fetch', state.project._id, { root: true })
    },

    async updateTagTasks ({
      commit,
      state,
      dispatch,
    }, {
      oldValue,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.updateTagTasks({
        axios: this.$axios,
        project: state.project,
        oldValue,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
      dispatch('tasks/fetch', { projectId: state.project._id }, { root: true })
    },

    async updateTagDocuments ({
      commit,
      state,
      dispatch,
    }, {
      oldValue,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.updateTagDocuments({
        axios: this.$axios,
        project: state.project,
        oldValue,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
      dispatch('documents/fetch', state.project._id, { root: true })
    },

    async updateRole ({
      commit,
      state,
    }, {
      oldValue,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.updateRole({
        axios: this.$axios,
        project: state.project,
        oldValue,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async deletePhasetagPlans ({
      commit,
      state,
    }, { value }) {
      const updatedProject = await ProjectsApi.deletePhasetagPlans({
        axios: this.$axios,
        project: state.project,
        value,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async deletePhasetagDocuments ({
      commit,
      state,
    }, { value }) {
      const updatedProject = await ProjectsApi.deletePhasetagDocuments({
        axios: this.$axios,
        project: state.project,
        value,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async deleteRole ({
      commit,
      state,
    }, { value }) {
      const updatedProject = await ProjectsApi.deleteRole({
        axios: this.$axios,
        project: state.project,
        value,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async deleteTagPlans ({
      commit,
      state,
    }, { value }) {
      const updatedProject = await ProjectsApi.deleteTagPlans({
        axios: this.$axios,
        project: state.project,
        value,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async deleteTagTasks ({
      commit,
      state,
    }, { value }) {
      const updatedProject = await ProjectsApi.deleteTagTasks({
        axios: this.$axios,
        project: state.project,
        value,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async deleteTagDocuments ({
      commit,
      state,
    }, { value }) {
      const updatedProject = await ProjectsApi.deleteTagDocuments({
        axios: this.$axios,
        project: state.project,
        value,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async resendParticipantInvitation ({
      commit,
      state,
    }, {
      participant,
      message,
    }) {
      await ParticipantsApi.resendInvitation({
        axios: this.$axios,
        project: state.project,
        participant,
        message,
      })
    },

    async updateLocationPropertyIsEnabled ({
      commit,
      state,
    }, {
      locationProperty,
      oldValue,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.updateLocationPropertyIsEnabled({
        axios: this.$axios,
        project: state.project,
        locationProperty,
        oldValue,
        newValue,
      })
      commit('SET_PROJECT', updatedProject)
    },

    async addLocationValue ({
      commit,
      state,
    }, {
      locationProperty,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.addLocationValue({
        axios: this.$axios,
        project: state.project,
        locationProperty,
        newValue,
      })
      commit('SET_PROJECT', updatedProject)
    },

    async deleteLocationValue ({ commit, state, dispatch }, { locationProperty, value }) {
      const updatedProject = await ProjectsApi.deleteLocationValue({
        axios: this.$axios,
        project: state.project,
        locationProperty,
        value,
      })
      commit('SET_PROJECT', updatedProject)

      dispatch('locationLayers/fetch', { projectId: state.project._id }, { root: true })
    },

    async updateLocationValue ({
      commit,
      state,
      dispatch,
    }, {
      locationProperty,
      oldValue,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.updateLocationValue({
        axios: this.$axios,
        project: state.project,
        locationProperty,
        oldValue,
        newValue,
      })
      commit('SET_PROJECT', updatedProject)

      dispatch('tasks/fetch', { projectId: state.project._id }, { root: true })
      dispatch('locationLayers/fetch', { projectId: state.project._id }, { root: true })
    },

    async reorderLocationValues ({
      commit,
      state,
    }, {
      locationProperty,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.reorderLocationValues({
        axios: this.$axios,
        project: state.project,
        locationProperty,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async updateLocationLabel ({
      commit,
      state,
    }, {
      locationProperty,
      oldValue,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.updateLocationLabel({
        axios: this.$axios,
        project: state.project,
        locationProperty,
        oldValue,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async addTaskType ({
      commit,
      state,
    }, { newValue }) {
      const updatedProject = await ProjectsApi.addTaskType({
        axios: this.$axios,
        project: state.project,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async updateTaskType ({
      commit,
      state,
      dispatch,
    }, {
      oldValue,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.updateTaskType({
        axios: this.$axios,
        project: state.project,
        oldValue,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
      dispatch('tasks/fetch', { projectId: state.project._id }, { root: true })
    },

    async deleteTaskType ({
      commit,
      state,
    }, { value }) {
      const updatedProject = await ProjectsApi.deleteTaskType({
        axios: this.$axios,
        project: state.project,
        value,
      })

      commit('SET_PROJECT', updatedProject)
    },

    async reorderTaskTypes ({
      commit,
      state,
    }, {
      oldValue,
      newValue,
    }) {
      const updatedProject = await ProjectsApi.reorderTaskTypes({
        axios: this.$axios,
        project: state.project,
        oldValue,
        newValue,
      })

      commit('SET_PROJECT', updatedProject)
    }
    ,
  }
  ,
}
