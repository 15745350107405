export default {
  namespaced: true,

  state: () => {
    return {
      unsavedChanges: [],
      showOverlay: false,
      cssClass: 'highlight-element',
    }
  },

  mutations: {
    ADD_UNSAVED_CHANGES (state, uid) {
      if (state.unsavedChanges.includes(uid)) {
        return
      }
      state.unsavedChanges.push(uid)
    },

    REMOVE_UNSAVED_CHANGES (state, uid) {
      state.unsavedChanges = state.unsavedChanges.filter(listUid => uid !== listUid)

      if (state.unsavedChanges.length === 0) {
        state.showOverlay = false
      }
    },

    RESET_STATE (state) {
      state.unsavedChanges = []
      state.showOverlay = false
    },

    SET_SHOW_OVERLAY (state, showOverlay) {
      state.showOverlay = showOverlay
    },
  },
}
