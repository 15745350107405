import * as PlansApi from '@client-shared/api/plans.api.js'

export default {
  namespaced: true,

  state: () => {
    return {
      list: [],
      importedIds: [],
    }
  },

  getters: {
    getById: (state, getters, rootState) => {
      return planId => { return state.list.find(plan => plan._id === planId) }
    },

    getByName: (state, getters, rootState) => {
      return planName => { return state.list.filter(plan => plan.name === planName) }
    },
  },

  mutations: {
    SET_PLANS (state, plans) {
      state.list = plans.filter(plan => !plan.trashed)
    },

    REPLACE_PLAN (state, plan) {
      const planIndex = state.list.findIndex(p => p._id === plan._id)

      state.list[planIndex] = plan
    },

    SET_IMPORTED_IDS (state, importedIds) {
      state.importedIds = [...importedIds]
    },

    RESET_IMPORTED_IDS (state) {
      state.importedIds = []
    },

    RESET_LIST (state) {
      state.list = []
    },
  },

  actions: {
    async fetch ({ commit }, project) {
      const plans = await PlansApi.getPlans({
        axios: this.$axios,
        projectId: project._id,
      })

      commit('SET_PLANS', plans)
    },
  },
}
