export default {
  namespaced: true,

  state: () => {
    return {
      searchString: '',
      approvals: [],
      tags: [],
      phasetags: [],
      authorRoles: [],
      authorUserIds: [],
      approverRoles: [],
      approverUserIds: [],
      topCategories: [],
      tagsAndSwitchActive: true,
      approvalDateFrom: undefined,
      approvalDateTo: undefined,
      approvalDatePreset: undefined,

      lastLocalUpdate: null,
      lastServerUpdate: null,
    }
  },

  getters: {
    isFilterActive: (state, getters, rootState) => {
      return state.searchString ||
        state.approvals.length > 0 ||
        state.tags.length > 0 ||
        state.phasetags.length > 0 ||
        state.authorRoles.length > 0 ||
        state.authorUserIds.length > 0 ||
        state.approverRoles.length > 0 ||
        state.approverUserIds.length > 0 ||
        state.topCategories.length > 0 ||
        state.approvalDateFrom ||
        state.approvalDateTo ||
        state.approvalDatePreset
    },
  },

  mutations: {
    SET_FILTERS (state, { filters = {}, modified }) {
      state.searchString = filters.searchString || ''
      state.approvals = filters.approvals || []
      state.tags = filters.tags || []
      state.phasetags = filters.phasetags || []
      state.authorRoles = filters.authorRoles || []
      state.authorUserIds = filters.authorUserIds || []
      state.approverRoles = filters.approverRoles || []
      state.approverUserIds = filters.approverUserIds || []
      state.topCategories = filters.topCategories || []
      state.tagsAndSwitchActive = filters.tagsAndSwitchActive === undefined ? true : filters.tagsAndSwitchActive
      state.approvalDateFrom = filters.approvalDateFrom || undefined
      state.approvalDateTo = filters.approvalDateTo || undefined
      state.approvalDatePreset = filters.approvalDatePreset || undefined

      state.lastLocalUpdate = null
      state.lastServerUpdate = new Date(modified)
    },

    RESET_FILTERS (state) {
      state.searchString = ''
      state.approvals = []
      state.approverUserIds = []
      state.approverRoles = []
      state.tags = []
      state.phasetags = []
      state.authorUserIds = []
      state.authorRoles = []
      state.topCategories = []
      state.tagsAndSwitchActive = true
      state.approvalDateFrom = undefined
      state.approvalDateTo = undefined
      state.approvalDatePreset = undefined

      state.lastLocalUpdate = new Date()
      state.lastServerUpdate = null
    },

    FULL_RESET (state) {
      state.searchString = ''
      state.approvals = []
      state.tags = []
      state.phasetags = []
      state.authorRoles = []
      state.authorUserIds = []
      state.approverRoles = []
      state.approverUserIds = []
      state.topCategories = []
      state.tagsAndSwitchActive = true
      state.approvalDateFrom = undefined
      state.approvalDateTo = undefined
      state.approvalDatePreset = undefined

      state.lastLocalUpdate = null
      state.lastServerUpdate = null
    },
  },
}
