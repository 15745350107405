export default {
  install: (app) => {
    const scrollToTop = ({ smooth = true } = {}) => {
      const overlayElement = document.querySelector('#overlay #overlay-content')
      const behavior = smooth ? 'smooth' : 'instant'

      if (overlayElement) {
        overlayElement.scrollIntoView({
          block: 'start',
          behavior,
        })
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior,
        })
      }
    }

    app.config.globalProperties.$scrollToTop = scrollToTop
  },
}
