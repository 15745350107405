import UnifiedFile from './unified-file.model'

export default class LocationLayerFile {
  constructor ({ _id, s3Key, name, size, mimeType }) {
    this._id = _id
    this.s3Key = s3Key
    this.name = name
    this.size = size
    this.mimeType = mimeType
  }

  convertToUnifiedFile () {
    return new UnifiedFile({
      _id: this._id,
      fileName: this.name,
      s3Key: this.s3Key,
      size: this.size,
      mimeType: this.mimeType,
    })
  }
}
